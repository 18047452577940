// Account
export const SET_USER = "SET_USER";
export const SET_USER_PROFILE = "SET_USER_PROFILE";
export const EDIT_ENSO_USER = "EDIT_ENSO_USER";
export const SET_START_KEYS = "SET_START_KEYS";
export const SET_KEY = "SET_KEY";
export const SET_BKP_URL = "SET_BKP_URL";
export const FORCE_BILLING = "FORCE_BILLING";
export const SET_SERVICE_ACCOUNTS = "SET_SERVICE_ACCOUNTS";

// Houses
export const SET_HOUSES = "SET_HOUSES";
export const ADD_HOUSE = "ADD_HOUSE";
export const EDIT_HOUSE = "EDIT_HOUSE";
export const CHANGE_HOUSE = "CHANGE_HOUSE";
export const DELETE_HOUSE = "DELETE_HOUSE";
export const ADD_LISTING_GROUP = "ADD_LISTING_GROUP";
export const EDIT_LISTING_GROUP = "EDIT_LISTING_GROUP";
export const DELETE_LISTING_GROUP = "DELETE_LISTING_GROUP";
export const SET_SELECTED_LISTING_GROUPS = "SET_SELECTED_LISTING_GROUPS";

// Guests
export const SET_GUESTS = "SET_GUESTS";
export const ADD_GUESTS = "ADD_GUESTS";
export const EDIT_GUEST = "EDIT_GUEST";

// Bookings
export const SET_BOOKINGS = "SET_BOOKINGS";
export const ADD_BOOKING = "ADD_BOOKING";
export const EDIT_BOOKING = "EDIT_BOOKING";
export const SET_SELECTED_BOOKING = "SET_SELECTED_BOOKING";
export const SET_SELECTED_GUEST_BOOKINGS = "SET_SELECTED_GUEST_BOOKINGS";

// Messages
export const ADD_MESSAGES = "ADD_MESSAGES";
export const APPEND_MESSAGE = "APPEND_MESSAGE";
export const ADD_HTML_CONTENT = "ADD_HTML_CONTENT";
export const EDIT_MESSAGE = "EDIT_MESSAGE";
export const SET_INBOX_DATA = "SET_INBOX_DATA";
export const SET_CHAT_OPTIONS = "SET_CHAT_OPTIONS";

// Automessages
export const ADD_AUTOMESSAGES = "ADD_AUTOMESSAGES";
export const EDIT_AUTOMESSAGE = "EDIT_AUTOMESSAGE";

// Chatbot
export const ADD_CHATBOT_RESPONSES = "ADD_CHATBOT_RESPONSES";
export const EDIT_CHATBOT = "EDIT_CHATBOT";

// Notifications
export const SET_NOTIFICATIONS = "SET_NOTIFICATIONS";
export const ADD_NOTIFICATION = "ADD_NOTIFICATION";
export const DELETE_NOTIFICATION = "DELETE_NOTIFICATION";
export const ADD_ATTACHED_OBJECT = "ADD_ATTACHED_OBJECT";
export const UPDATE_ATTACHED_OBJECT = "UPDATE_ATTACHED_OBJECT";

// Reports
export const SET_REPORTS_LOADING_HITS = "SET_REPORTS_LOADING_HITS";
export const SET_REPORT_LIST = "SET_REPORT_LIST";
export const SET_DASHBOARD_REPORT = "SET_DASHBOARD_REPORT";
export const ADD_REPORT = "ADD_REPORT";
export const ADD_REPORT_HITS = "ADD_REPORT_HITS";
export const ADD_EXPORT_REPORT = "ADD_EXPORT_REPORT";
export const DELETE_REPORT = "DELETE_REPORT";
export const SET_SELECTED_REPORT = "SET_SELECTED_REPORT";

// Experiences
export const SET_AVAILABLE_EXPERIENCES = "SET_AVAILABLE_EXPERIENCES";
export const SET_CONFIGURED_EXPERIENCES = "SET_CONFIGURED_EXPERIENCES";
export const SET_INSTANT_EXPERIENCES = "SET_INSTANT_EXPERIENCES";
export const ADD_EXPERIENCE = "ADD_EXPERIENCE";
export const ADD_LISTING_EXPERIENCES = "ADD_LISTING_EXPERIENCES";
export const ADD_PRESET_EXPERIENCE = "ADD_PRESET_EXPERIENCE";
export const REMOVE_EXPERIENCE = "REMOVE_EXPERIENCE";
export const LOADING_X_VERIFICATION_SETTINGS =
  "LOADING_X_VERIFICATION_SETTINGS";

// Devices
export const SET_DEVICES = "SET_DEVICES";
export const EDIT_DEVICE = "EDIT_DEVICE";

// Dashboard
export const SET_DASHBOARD_DATA = "SET_DASHBOARD_DATA";
export const TRIGGER_DASHBOARD_REFRESH = "TRIGGER_DASHBOARD_REFRESH";

// App
export const SET_LOADED = "SET_LOADED";
export const ADD_LOADED_EVENTS = "ADD_LOADED_EVENTS";
export const SET_API_START = "SET_API_START";
export const SET_API_SUCCESS = "SET_API_SUCCESS";
export const SET_API_ERROR = "SET_API_ERROR";
export const OPEN_ALERT = "OPEN_ALERT";
export const CLOSE_ALERT = "CLOSE_ALERT";
export const RESET_STORE = "RESET_STORE";
export const SET_LANGUAGE = "SET_LANGUAGE";
export const OPEN_GENERAL_ERROR = "OPEN_GENERAL_ERROR";
export const CLOSE_GENERAL_ERROR = "CLOSE_GENERAL_ERROR";
export const OPEN_GENERAL_ALERT_SUCCESS = "OPEN_GENERAL_ALERT_SUCCESS";
export const CLOSE_GENERAL_ALERT_SUCCESS = "CLOSE_GENERAL_ALERT_SUCCESS";
export const SET_DEVICE_TYPE = "SET_DEVICE_TYPE";
export const SET_TOTALS = "SET_TOTALS";
export const SET_NAVBAR_VISIBILITY = "SET_NAVBAR_VISIBILITY";
export const SET_ERROR_PATH_BLOCKED = "SET_ERROR_PATH_BLOCKED";
export const SET_SELECTED_VIEW_ITEM = "SET_SELECTED_VIEW_ITEM";
