import {makeStyles} from "@material-ui/core";
import {THEME} from "configuration/settings.js";

const useOldDashboardStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    padding: theme.spacing(0, 5),
    [theme.breakpoints.down("sm")]: {padding: 0},
  },
  container: {
    flexGrow: 1,
    display: "flex",
    overflow: "hidden",
    flexDirection: "row",
    alignItems: "flex-start",
    [theme.breakpoints.down("sm")]: {
      "&::-webkit-scrollbar": {width: "0!important"},
    },
  },
  header: {
    padding: theme.spacing(4, 0),
    display: "flex",
    paddingLeft: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "center",
      padding: theme.spacing(4, 4, 2),
      gap: theme.spacing(4),
    },
  },
  greeting: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
  },
  subheader: {
    fontWeight: 400,
    fontSize: 18,
    lineHeight: "21px",
    color: "#707070",
    marginTop: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {fontSize: 16},
  },
  content: {
    flexGrow: 1,
    overflow: "auto",
    [theme.breakpoints.down("sm")]: {
      "&::-webkit-scrollbar": {width: "0!important"},
    },
  },
  center: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  btnRangeRoot: {minWidth: 140},
  btnTransparent: {
    backgroundColor: "rgba(191, 191, 191, 0.2)",
    borderRadius: 10,
    "&:hover": {backgroundColor: "rgba(191, 191, 191, 0.4)"},
  },
  btnLabel: {
    fontWeight: 400,
    color: "#818183",
  },
  arrowBtn: {
    minWidth: 40,
    width: 40,
    borderRadius: 10,
    marginRight: theme.spacing(1),
  },
  arrowBtnDisabled: {
    backgroundColor: "rgba(191, 191, 191, 0.2)!important",
    opacity: 0.5,
  },
  btnsContainer: {
    display: "flex",
    alignItems: "flex-end",
    [theme.breakpoints.down("sm")]: {width: "100%"},
  },
  controlsRow: {
    marginLeft: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      paddingTop: theme.spacing(2),
      marginLeft: 0,
    },
  },
  greyButton: {
    textTransform: "none",
    textDecoration: "underline",
    padding: 0,
    fontWeight: "inherit",
    fontSize: "inherit",
    lineHeight: "inherit",
    color: "inherit",
    backgroundColor: "transparent",
    margin: theme.spacing(0, 2),
    "&:hover": {
      backgroundColor: "transparent",
      textDecoration: "underline",
    },
  },
  card: {
    width: 330,
    height: 200,
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(4, 3),
    margin: theme.spacing(2),
    border: "none",
    boxShadow: "0px 2px 4px rgba(28,41,90,0.04)",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      width: "calc(100% - 40px)",
      margin: theme.spacing(4),
      padding: theme.spacing(4, 3),
    },
  },
  cardTitle: {
    ...THEME.customTypography.title1,
    fontSize: 18,
    color: theme.palette.primary.main,
  },
  cardHeader: {
    alignItems: "flex-start",
    paddingBottom: 0,
    paddingTop: 0,
    paddingLeft: 10,
  },
  cardAction: {
    display: "flex",
    marginTop: -theme.spacing(1),
  },
  cardActionTotals: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    justifyContent: "flex-start",
  },
  cardContent: {
    flexGrow: 1,
    overflow: "hidden",
  },
  number: {
    fontSize: 22,
    lineHeight: "28px",
    color: theme.palette.primary.main,
    fontWeight: 500,
    fontFamily: "Roboto",
  },
  percentage: {
    fontSize: 16,
    lineHeight: "20px",
    fontWeight: 500,
    color: THEME.mutedGreen,
    marginLeft: theme.spacing(2),
  },
  negativePercentage: {color: "#B7A9B4"},
  movementsRow: {display: "inline-block"},
  mobileReportsContainer: {
    height: "100%",
    overflowY: "auto",
    overflowX: "hidden",
    [theme.breakpoints.down("sm")]: {
      "&::-webkit-scrollbar": {width: "0!important"},
    },
  },
  mobileMainContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    overflowX: "hidden",
    overflowY: "auto",
    flexDirection: "column",
    backgroundColor: "rgba(250,250,250,0.85)",
  },
}));

export default useOldDashboardStyles;
