import React from "react";
import {useDispatch} from "react-redux";
import {makeStyles} from "@material-ui/core/styles";
import CustomDialog from "core/dialogs/CustomDialog";
import ConfirmDialog from "components/Dialogs/ConfirmDialog";
import UpdateSession from "components/Dialogs/UpdateSession";
import ListingGroupSettings from "components/Panels/GJE/ListingGroupSettings";
// Actions
import {saveListingGroup} from "redux/actions/listingsActions";

const useStyles = makeStyles((theme) => ({
  container: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
  },
  dialogContent: {display: "flex"},
}));

export default function ListingGroupModal({
  open,
  loading,
  setLoading,
  onClose,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [updateSession, setUpdateSession] = React.useState(null);
  const [data, setData] = React.useState({
    name: "",
    listings: [],
    groupId: null,
    connection_type: "ids",
  });
  const saveDisabled = !data.name?.trim() || !data.listings.length;

  React.useEffect(() => {
    if (!!open) {
      resetData();
    }
  }, [open]);

  const handleCancelUpdates = () => setUpdateSession((prev) => null);

  const resetData = () => {
    setData((prev) => ({
      name: "",
      listings: [],
      groupId: null,
      connection_type: "ids",
    }));
  };

  const saveCallback = () => {
    setLoading(false);
    setUpdateSession((prev) => null);
  };

  const handleSave = (saveUpdate) => {
    setLoading("Saving group...");
    let newData = {
      name: data.name,
      connected_to: {
        object: "listing",
        ids: data.listings,
      },
    };

    if (!!saveUpdate && !!updateSession) {
      newData = {update_id: updateSession.update_session.update_id};
    }

    dispatch(
      saveListingGroup({
        body: newData,
        onSuccess: (response) => {
          saveCallback();
          onClose(!!saveUpdate);
        },
        onUpdate: !saveUpdate
          ? (update) => {
              setUpdateSession((prev) => update);
              setLoading(false);
            }
          : null,
        onError: saveCallback,
      }),
    );
  };

  function getDialogContent() {
    return (
      <div id="create-group-modal" className={classes.container}>
        <ListingGroupSettings
          edit
          data={data}
          hideActions
          isInsideModal
          loading={loading}
          setData={setData}
          resetData={resetData}
          setLoading={setLoading}
          selectedGroup="create"
        />
      </div>
    );
  }

  const confirmModal = !!updateSession && (
    <ConfirmDialog
      disableDismiss
      open={!loading}
      dialo
      onClose={handleCancelUpdates}
      title="Confirm updates?"
      message={
        <>
          {"Updating this group will affect the following:"}
          <UpdateSession updates={updateSession.update_session.payload} />
        </>
      }
      confirmLabel="Confirm"
      confirmAction={() => handleSave(true)}
      cancelLabel="Cancel"
      cancelAction={handleCancelUpdates}
    />
  );

  return (
    <>
      {confirmModal}
      <CustomDialog
        open={open}
        fullWidth
        maxWidth="sm"
        maxHeight="calc(100% - 70px)"
        customHeight="calc(100% - 70px)"
        dialogContentExtraClass={classes.dialogContent}
        title="New listing group"
        content={getDialogContent()}
        onClose={() => onClose()}
        labelConfirm="Save"
        confirmBtnID="save-listing-group-btn"
        disableConfirm={saveDisabled}
        actionConfirm={() => handleSave()}
        labelCancel="Cancel"
        actionCancel={() => onClose()}
      />
    </>
  );
}
