import {
  SET_REPORTS_LOADING_HITS,
  SET_REPORT_LIST,
  SET_DASHBOARD_REPORT,
  ADD_REPORT,
  ADD_REPORT_HITS,
  ADD_EXPORT_REPORT,
  DELETE_REPORT,
  SET_SELECTED_REPORT,
} from "../actionTypes";

const initial_state = {
  report_list: [],
  reports: {},
  selected_report_id: null,
  dashboard_report: {},
  export_reports: {},
  loadingHits: false,
};

export default function reportsReducer(state = initial_state, action) {
  switch (action.type) {
    case SET_REPORTS_LOADING_HITS:
      return {...state, loadingHits: action.loading};
    case SET_SELECTED_REPORT:
      return {...state, selected_report_id: action.reportId};
    case SET_REPORT_LIST:
      const newReportObj = {};
      for (let index = 0; index < action.reportList.length; index++) {
        const report = action.reportList[index];
        newReportObj[report.report_id] = {...report, minimalData: true};
      }
      return {
        ...state,
        report_list: action.reportList,
        reports: newReportObj,
      };
    case DELETE_REPORT:
      return {
        ...state,
        report_list: state.report_list.filter(
          (report) => report.report_id !== action.reportId,
        ),
      };
    case SET_DASHBOARD_REPORT:
      return {
        ...state,
        dashboard_report: action.report,
      };
    case ADD_REPORT:
      const newReportList = [...state.report_list];
      if (action.isNew) {
        newReportList.push(action.report);
      } else {
        const reportIndex = state.report_list.findIndex(
          (r) => r.report_id === action.reportId,
        );
        if (reportIndex > -1) {
          newReportList[reportIndex] = action.report;
        }
      }
      return {
        ...state,
        reports: {
          ...state.reports,
          [action.reportId]: action.report,
        },
        report_list: newReportList,
      };
    case ADD_REPORT_HITS:
      return {
        ...state,
        reports: {
          ...state.reports,
          [action.reportId]: {
            ...state.reports[action.reportId],
            hits: [...state.reports[action.reportId].hits, ...action.hits],
            start_key: action.startKey,
          },
        },
      };
    case ADD_EXPORT_REPORT:
      const reportData = state.reports[action.reportId];
      return {
        ...state,
        reports: {
          ...state.reports,
          [action.reportId]: {
            ...reportData,
            [action.contentType]: action.data,
          },
        },
      };

    default:
      return state;
  }
}
