import React from "react";
// UI
import {makeStyles} from "@material-ui/core/styles";
import {List, ListItem, ListItemText, Typography} from "@material-ui/core";
// Utilities
import {capitalize} from "utilities/helperFunctions";
import {format, isSameMonth} from "date-fns";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
    maxHeight: "95vh",
  },
  itemPrimaryText: {
    fontWeight: 600,
    fontSize: 16,
    lineHeight: "22px",
    color: "#000",
    marginBottom: 2,
  },
  itemSecondaryText: {
    fontWeight: 400,
    fontSize: 14,
    lineHeight: "16px",
    color: "#707070",
  },
  status: {
    fontWeight: 400,
    fontSize: 14,
    lineHeight: "18px",
    textAlign: "right",
    color: "rgba(112,112,112,0.5)",
    alignSelf: "flex-end",
    marginLeft: theme.spacing(4),
  },
  wide: {minWidth: 300},
  listItemText: {margin: 0},
}));

export default function BookingList({
  bookings,
  wide,
  listItemClass,
  onBookingSelect,
}) {
  const classes = useStyles();
  const [bkgSelected, setBkgSelected] = React.useState(null);

  const handleBookingSelect = (bkg) => () => {
    if (!!onBookingSelect) {
      onBookingSelect(bkg);
      setBkgSelected((prev) => bkg.booking_id);
    }
  };

  function getDates(start, end) {
    const sameMonth = isSameMonth(start, end);
    if (!end) {
      return format(start, "MMM d");
    }
    if (sameMonth) {
      return `${format(start, "MMM d")} - ${format(end, "d, y")}`;
    } else {
      return `${format(start, "MMM d")} - ${format(end, "MMM d, y")}`;
    }
  }

  return (
    <div className={classes.root}>
      <List>
        {bookings.map((b) => (
          <ListItem
            button
            divider
            disableRipple
            key={b.booking_id}
            className={clsx({[classes.wide]: !!wide}, listItemClass)}
            selected={b.booking_id === bkgSelected}
            onClick={handleBookingSelect(b)}
          >
            <ListItemText
              primary={b.name}
              primaryTypographyProps={{className: classes.itemPrimaryText}}
              secondary={getDates(b.checkin_raw, b.checkout_raw)}
              secondaryTypographyProps={{className: classes.itemSecondaryText}}
              className={classes.listItemText}
            />
            <Typography className={classes.status}>
              {capitalize(b.status, "_")}
            </Typography>
          </ListItem>
        ))}
      </List>
    </div>
  );
}
