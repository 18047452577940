import React from "react";
import {useSelector} from "react-redux";
import clsx from "clsx";
// UI
import {makeStyles} from "@material-ui/core/styles";
import {CircularProgress, Typography} from "@material-ui/core";
// Custom
import BarChart from "components/Charts/BarChart";
import PieChart from "components/Charts/PieChart";
import LineChart from "components/Charts/LineChart";
// Utilities
import {getLabelsFromDateRange, setStartOfDay} from "utilities/helperFunctions";
import {metricLabels} from "configuration/constants";
import {THEME} from "configuration/settings";
import {differenceInHours} from "date-fns";

const useStyles = makeStyles((theme) => ({
  chartContainer: {
    flexGrow: 1,
    maxHeight: "42%",
    minHeight: 150,
    padding: 20,
    paddingTop: 0,
  },
  center: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export default function ReportChart({
  create,
  metric,
  view,
  interval,
  selectedReport,
  previewData = [],
  isPreview,
  start,
}) {
  const classes = useStyles();
  const reports = useSelector((state) => state.reportsReducer.reports);
  const loading = useSelector((state) => state.defaultReducer.loading).reports;
  const loadingHits = useSelector((state) => state.reportsReducer.loadingHits);
  const error = useSelector((state) => state.defaultReducer.errors).reports;
  const [reportData, setReportData] = React.useState([]);
  const [report, setReport] = React.useState(null);
  // General

  React.useEffect(() => {
    const isNew = selectedReport === "new";
    setReport((prev) => (isNew ? null : reports[selectedReport.report_id]));
  }, [reports, selectedReport]);

  React.useEffect(() => {
    setReportData((prev) =>
      isPreview ? previewData : report?.metrics[0]?.buckets || [],
    );
  }, [isPreview, previewData, report]);

  function getTimeCount() {
    let count = 0;
    const now = new Date();
    switch (interval) {
      case "day":
        count = differenceInHours(now, setStartOfDay(now), {
          roundingMethod: "ceil",
        });
        break;
      case "week":
        count = 168;
        break;
      case "month":
        count = reportData[0].data.length;
        break;
      case "year":
        count = 52;
        break;
    }
    return count;
  }

  function getChartData(isLineChart) {
    const timeCount = getTimeCount();
    const labels = [];
    const data = [];
    const colors = [];
    const lineChartLabels = getLabelsFromDateRange(
      timeCount,
      interval,
      new Date(),
      isLineChart,
      start,
    );
    for (let index = 0; index < reportData.length; index++) {
      const bucket = reportData[index];
      labels.push(bucket.label);
      data.push(isLineChart ? {bucket, values: bucket.data} : bucket.total);
      colors.push(bucket.color || THEME.secondary);
    }
    return {
      labels: isLineChart ? lineChartLabels : labels,
      data: data,
      title: metricLabels[data.metric],
      colors: colors,
    };
  }

  const chart = React.useMemo(() => {
    if (!metric || !view || !reportData.length) {
      return null;
    }
    switch (view) {
      case "histogram":
        return <BarChart data={getChartData()} />;
      case "pie_chart":
        return <PieChart data={getChartData()} fullSize />;
      case "line_chart":
        return <LineChart data={getChartData(true)} smallGradient />;
      default:
        return null;
    }
  }, [metric, view, interval, reportData]);

  const circularProgress = <CircularProgress color="secondary" />;
  const noDataAvailable = (
    <Typography variant="h2" color="textSecondary">
      {!error.hasError || !error.message
        ? "No report data available"
        : error.message}
    </Typography>
  );

  return React.useMemo(() => {
    return (
      <div
        className={clsx(classes.chartContainer, {
          [classes.center]:
            !view ||
            (loading && !loadingHits) ||
            !report ||
            !reportData ||
            !!error.hasError,
        })}
      >
        {!metric ? (
          !report && !create ? (
            circularProgress
          ) : !!report && loading ? (
            circularProgress
          ) : (
            <Typography variant="h2" color="textSecondary">
              {"Select a metric to get started"}
            </Typography>
          )
        ) : loading ? (
          !loadingHits ? (
            circularProgress
          ) : (
            chart
          )
        ) : (!!report || !!create) && !error.hasError ? (
          chart
        ) : (
          noDataAvailable
        )}
      </div>
    );
  }, [metric, report, selectedReport, loading, reportData]);
}
