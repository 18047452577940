import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {Waypoint} from "react-waypoint";
// UI
import NumberFormat from "react-number-format";
import {
  Avatar,
  Checkbox,
  ListItem,
  ListItemIcon,
  ListItemText,
  Chip,
  Tooltip,
  Typography,
  makeStyles,
  withStyles,
} from "@material-ui/core";
import RightArrowIcon from "@material-ui/icons/ArrowForwardIos";
import InfoIcon from "@material-ui/icons/Info";
import useHouseListStyles from "styles/useHouseListStyles";
//Custom
import {EmptyListPanel} from "components/Helpers/EmptyPanels";
import VirtualizedList from "components/Lists/VirtualizedList";
import CustomCardHeader from "core/cards/CustomCardHeader";
import PrimaryButton from "core/buttons/PrimaryButton";
import SearchBar from "core/bars/SearchBar";
// Actions
import {getHouseContent, getHouses} from "redux/actions/listingsActions";
import {setStartKeys} from "redux/actions/settingsActions";
// Utilities
import {getEncodedFilters} from "utilities/helperFunctions.js";
import {FIXED_SIZES, THEME} from "configuration/settings.js";
import clsx from "clsx";
import qs from "qs";
import ButtonLabelLoader from "core/loaders/ButtonLabelLoader";

const filterKeys = ["country", "ungrouped"];
const sortKeys = ["alphabetical"];

const useStyles = makeStyles({
  unpairedColor: {
    color: THEME.white,
    backgroundColor: THEME.error,
  },
  unavailableColor: {
    color: THEME.text,
    backgroundColor: THEME.warning,
  },
});

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    color: theme.palette.text,
    backgroundColor: theme.palette.common.white,
  },
}))(Tooltip);

export default function ListingList({
  selectHouse,
  selected,
  multiselect,
  customTitle,
  disableGutters,
  disableSelect,
  listingKey = "listing_id",
  customActions,
  customItem,
  hideTitle,
  disableSearch,
  hideScrollbar = true,
  itemSize = null,
  label = "Listings",
  createGroup,
  onlyHouses,
  singleselect,
  listingMap,
  account,
  loadedPairedMap,
  getDisabled = null,
  isInsideModal,
}) {
  const styleProps = {disableGutters, disableSelect};
  const classes = useHouseListStyles(styleProps);
  const chipClasses = useStyles();
  const dispatch = useDispatch();
  const isLoadingMore = React.useRef(false);
  const listRef = React.useRef(null);
  // Selectors
  const totals = useSelector((state) => state.defaultReducer.totals);
  const houseArray = useSelector((state) => state.defaultReducer.house_data);
  const startKeys = useSelector((state) => state.defaultReducer.start_keys);
  const current_user = useSelector(
    (state) => state.defaultReducer.current_user,
  );
  const houses_dict = useSelector(
    (state) => state.defaultReducer.house_data_dict,
  );
  const loadingHouses = useSelector(
    (state) => state.defaultReducer.loading,
  ).listings;
  const hasError = useSelector((state) => state.defaultReducer.errors).listings
    .hasError;
  // State
  const [data, setData] = React.useState([]);
  const [filters, setFilters] = React.useState([]);
  const [searchText, setSearchText] = React.useState("");
  const [totalData, setTotalData] = React.useState(totals.listings);
  const [hasNextPage, setHasNextPage] = React.useState(
    !!onlyHouses
      ? false
      : startKeys.listings === null
        ? true
        : startKeys.listings,
  );
  // General
  const sort = sortKeys[0];
  const houses = React.useMemo(() => {
    return !!onlyHouses
      ? onlyHouses.map((h) => houses_dict[h] ?? {})
      : houseArray;
  }, [houses_dict, onlyHouses]);
  const listingItemHeight =
    itemSize ||
    (multiselect ? FIXED_SIZES.listings_dense : FIXED_SIZES.listings);

  React.useEffect(() => {
    if (!searchText && !filters.length) {
      setHouses(houses);
      setTotalData((prev) => totals.listings);
    } else {
      setHouses(data.map((h) => houses_dict[h[listingKey]]));
    }
  }, [houses, totals]);

  React.useEffect(() => {
    if (!searchText && !filters.length) {
      setTotalData((prev) => totals.listings);
      setHouses(houses);
      setHasNextPage((prev) =>
        !!onlyHouses
          ? false
          : startKeys.listings === null
            ? true
            : startKeys.listings,
      );
      scrollToTop();
    } else {
      loadData();
    }
  }, [searchText, filters]);

  const compareHouses = (a, b) => {
    const isASelected = selected.includes(a[listingKey]);
    const isBSelected = selected.includes(b[listingKey]);
    if (isASelected && isBSelected) {
      return 0;
    } else if (isASelected && !isBSelected) {
      return -1;
    } else if (!isASelected && isBSelected) {
      return 1;
    } else return 0;
  };

  function setHouses(newHouses) {
    if (!!multiselect) {
      setData((prev) => newHouses.sort(compareHouses));
      if (!!listRef.current) {
        listRef.current.forceUpdateGrid();
      }
    } else {
      setData((prev) => newHouses);
    }
  }

  function scrollToTop() {
    if (!!listRef.current) {
      listRef.current.scrollToRow(0);
    }
  }

  const loadData = () => {
    let params = `enso_key=${current_user}&start=0&sort=${sort}`;
    if (!!searchText) {
      params += `&${qs.stringify({query: searchText})}`;
    }
    if (!!filters.length) {
      params += `&filters=${getEncodedFilters(filters)}`;
    }

    dispatch(
      getHouses(params, true, (response) => {
        setTotalData((prev) => response.count);
        setHasNextPage((prev) => response.start_key);
        setHouses(response.hits);
      }),
    );
  };

  const loadNextPage = () => {
    isLoadingMore.current = true;
    let params = `enso_key=${current_user}&start=${data.length}&sort=${sort}`;
    if (!!searchText) {
      params += `&${qs.stringify({query: searchText})}`;
    }
    if (!!filters.length) {
      params += `&filters=${getEncodedFilters(filters)}`;
    }

    dispatch(
      getHouses(
        params,
        true,
        (response) => {
          setHasNextPage((prev) => response.start_key);
          if (!searchText && !filters.length) {
            dispatch(setStartKeys("listings", response.start_key));
          } else {
            setHouses(data.concat(response.hits));
          }
          isLoadingMore.current = false;
        },
        () => {
          isLoadingMore.current = false;
        },
      ),
    );
  };

  const handleSelectListing = (newHouse) => {
    if (disableSelect) {
      return;
    }
    if (!multiselect && !singleselect) {
      dispatch(getHouseContent(newHouse[listingKey]));
    }
    selectHouse(newHouse);
  };

  const handleDeleteFilter = (filter) => {
    setFilters((prevFilters) => {
      const newFilters = [...prevFilters];
      const filterInd = prevFilters.findIndex(
        (f) => f.path === filter.path && f.value === filter.value,
      );
      if (filterInd !== -1) {
        newFilters.splice(filterInd, 1);
      }
      return newFilters;
    });
  };

  function getCustomItem({index}) {
    const house = data[index];
    const waypoint =
      hasNextPage && index === data.length - 10 ? (
        <Waypoint onEnter={() => loadNextPage()} />
      ) : null;

    return customItem(house, waypoint);
  }

  function getPairedStateChip(house) {
    const inMap = !!listingMap?.[house[listingKey]];
    const unmappedListings = account?.unmapped_listings;
    const cfs = house?.listing_content?.custom;
    const ab_host_id = cfs?.find(
      (cf) => cf?.properties?.name == "Airbnb integration host id",
    )?.properties?.value;
    if (!loadedPairedMap?.[house[listingKey]]) {
      return (
        <Chip
          size="small"
          color="primary"
          icon={<ButtonLabelLoader variant="white" size={15} />}
          label="Loading..."
          className={classes.chip}
        />
      );
    } else if (
      inMap &&
      ab_host_id &&
      ab_host_id != account?.integration_host_id
    ) {
      return (
        <LightTooltip
          title={
            <>
              <Typography color="textPrimary">
                Connected to different AirBnb account (ID: {ab_host_id})
              </Typography>
            </>
          }
        >
          <Chip
            size="small"
            color="primary"
            label="Paired"
            className={chipClasses.unavailableColor}
            icon={<InfoIcon />}
          />
        </LightTooltip>
      );
    } else if (inMap) {
      const ab_name =
        cfs?.find((cf) => cf?.properties?.name == "Airbnb listing name")
          ?.properties?.value ||
        unmappedListings?.find(
          (ul) => ul.id == listingMap?.[house?.[listingKey]],
        )?.name;
      const ab_addr =
        cfs?.find((cf) => cf?.properties?.name == "Airbnb listing address")
          ?.properties?.value ||
        unmappedListings?.find(
          (ul) => ul.id == listingMap?.[house?.[listingKey]],
        )?.address;
      return (
        <LightTooltip
          title={
            <>
              <Typography color="textPrimary">
                Connected to {ab_name} {ab_addr}
              </Typography>
            </>
          }
        >
          <Chip
            size="small"
            color="primary"
            label="Paired"
            className={classes.chip}
            icon={<InfoIcon />}
          />
        </LightTooltip>
      );
    } else {
      return (
        <Chip
          size="small"
          label="Unpaired"
          color="primary"
          classes={{
            colorPrimary: chipClasses.unpairedColor,
          }}
        />
      );
    }
  }

  function getHouseListItem({index}) {
    const house = data[index];
    const isSelected = multiselect
      ? selected.includes(house[listingKey])
      : selected === house[listingKey];

    return (
      <>
        {hasNextPage && index === data.length - 10 && (
          <Waypoint onEnter={() => loadNextPage()} />
        )}
        <ListItem
          button
          disableRipple
          dense={!!multiselect}
          divider
          disabled={!!getDisabled ? getDisabled(house) : false}
          className={classes.multiListItem}
          selected={isSelected}
          onClick={() => handleSelectListing(house)}
          classes={{selected: multiselect && classes.multiselected}}
          id={`listing-item-${index}`}
        >
          <ListItemIcon className={classes.itemIcon}>
            {multiselect && (
              <Checkbox
                edge="start"
                size="small"
                checked={isSelected}
                disableRipple
                className={classes.checkbox}
              />
            )}
            <Avatar className={classes.img} src={house.picture}>
              {""}
            </Avatar>
          </ListItemIcon>
          <ListItemText
            primary={house.nickname || house.name}
            primaryTypographyProps={{
              variant: "h1",
              className: clsx(classes.ellipsis, classes.title),
            }}
            secondary={house.address?.formatted}
            secondaryTypographyProps={{
              className: clsx(classes.ellipsis, classes.subtitle),
            }}
          />
          {!!listingMap && getPairedStateChip(house)}

          {!multiselect && !disableSelect && (
            <RightArrowIcon className={classes.icon} />
          )}
        </ListItem>
      </>
    );
  }

  const actions = customActions ?? (
    <>
      <PrimaryButton
        size="small"
        label="Create listing group"
        style={{maxWidth: 106}}
        onClick={createGroup}
      />
    </>
  );

  const searchbar = React.useMemo(() => {
    return (
      <div
        className={clsx(classes.searchContainer, {"-no-title": !!hideTitle})}
      >
        <SearchBar
          enableFilters
          disableGutters
          filters={filters}
          short={multiselect}
          filterKeys={filterKeys}
          searchInput={searchText}
          isInsideModal={isInsideModal}
          handleDeleteFilter={handleDeleteFilter}
          handleSearchInput={(val) => setSearchText((prev) => val)}
          setFilters={(newFilters) => setFilters((prev) => newFilters)}
        />
      </div>
    );
  }, [searchText, filters]);

  const getHouseList = () => {
    if (startKeys.listings === null) {
      return null;
    }
    return loadingHouses &&
      !isLoadingMore.current &&
      (!!searchText || !!filters.length) ? (
      <EmptyListPanel
        loading
        list="listings"
        noTitle
        noSearchbar
        itemSize={itemSize}
        multiselect={multiselect}
      />
    ) : (
      <>
        <VirtualizedList
          hideScrollbar={hideScrollbar}
          listRef={listRef}
          getRowItem={!!customItem ? getCustomItem : getHouseListItem}
          rowHeight={listingItemHeight}
          totalRows={data.length}
        />
      </>
    );
  };

  return startKeys.listings === null ? (
    <EmptyListPanel
      loading={!hasError}
      list="listings"
      noTitle={!!customTitle || !!multiselect}
      multiselect={multiselect}
    />
  ) : (
    <div className={classes.root}>
      {!hideTitle && (
        <CustomCardHeader
          type={customTitle ? "text" : "header"}
          className={classes.header}
          action={actions}
          title={
            !!customTitle ? (
              customTitle
            ) : !!multiselect ? (
              !disableSearch ? (
                searchbar
              ) : null
            ) : (
              <>
                <NumberFormat
                  value={totalData}
                  thousandSeparator
                  displayType="text"
                />
                {` ${label}`}
              </>
            )
          }
        />
      )}
      {!multiselect && !disableSearch && searchbar}
      <div className={classes.content} id={"listings-list"}>
        {getHouseList()}
      </div>
    </div>
  );
}
