import React from "react";
import {useSelector} from "react-redux";
import {useRouteMatch} from "react-router-dom";
import {
  Box,
  makeStyles,
  MenuItem,
  SvgIcon,
  Typography,
} from "@material-ui/core";
import {ReactComponent as BusinessIcon} from "assets/icons/Icon_Buildings.svg";
import {ReactComponent as ListingIcon} from "assets/icons/Icon_House.svg";
import HouseGroupIcon from "@material-ui/icons/HomeWork";
// Custom
import ListingList from "components/Lists/ListingList";
import FilledSelect from "core/selects/FilledSelect";
import MultiButtonBar from "core/buttons/MultiButtonBar";
import PrimaryButton from "core/buttons/PrimaryButton";
import InfoText from "components/Misc/InfoText";
// Utilities
import {THEME} from "configuration/settings.js";
import clsx from "clsx";
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    gap: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  grow: {
    flexGrow: 1,
    marginBottom: theme.spacing(2),
    overflow: "hidden",
  },
  title: {...THEME.customTypography.title2},
  titlePadding: {
    padding: theme.spacing(3, 4),
    paddingBottom: 0,
  },
  connectHouses: {
    marginTop: theme.spacing(3),
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    border: "1px solid rgba(229, 229, 234, 0.75)",
    borderRadius: 10,
    overflow: "hidden",
  },
  housesList: {
    flexGrow: 1,
    paddingTop: theme.spacing(1),
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
  },
  selected: {fontWeight: 700},
  row: {
    padding: theme.spacing(4),
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    border: "1px solid #F3F5FA",
    borderRadius: 10,
  },
  connectedListings: {
    color: THEME.subdued,
    marginLeft: theme.spacing(2),
  },
}));

export default function ListingConnection({
  data,
  showHouseList,
  setData,
  setShowHouseList = () => null,
  extraListings = null,
  disableConnectionTypes,
  keepOpen,
  disabledButtons,
  disableEdit,
  itemLimit = null,
  onlyHouses = null,
  disableSearch,
  onlyOneSelection,
  type,
  listingKey = "listing_id",
  disableGroupSelector,
  isInsideModal,
}) {
  const classes = useStyles();
  const matchCardSection = useRouteMatch(
    "/admin/listings/groups/:group_id/:step/:card",
  );
  const listingGroups = useSelector(
    (state) => state.defaultReducer.listing_groups_dict,
  );
  // State
  const [connectedHouses, setConnectedHouses] = React.useState(data.listings);
  const [newConnectedListings, setNewConnectedListings] =
    React.useState(connectedHouses);
  const [listingGroup, setListingGroup] = React.useState(data.groupId);
  // General
  const disableListingGroupSelect =
    !!matchCardSection?.isExact || !!disableGroupSelector;
  const listingGroupsKeys = React.useMemo(() => {
    return Object.keys(listingGroups).filter((lg) => lg !== "ALL");
  }, [listingGroups]);
  let allowOnlyListings = !!onlyHouses || !!extraListings;
  const listingsListed = React.useMemo(() => {
    return [...(onlyHouses ?? []), ...(extraListings ?? [])];
  }, [onlyHouses, extraListings]);

  const icons = {
    all: (
      <SvgIcon
        viewBox="0 0 18 16"
        className="-button-icon"
        component={BusinessIcon}
      />
    ),
    listing_group: <HouseGroupIcon />,
    listing: (
      <SvgIcon
        viewBox="0 0 18 16"
        className="-button-icon"
        component={ListingIcon}
      />
    ),
  };

  const multiButtonBarOptions = !!listingGroupsKeys.length
    ? {
        options: ["all", "group", "ids"],
        icons: [icons.all, icons.listing_group, icons.listing],
        labels: [
          "All listings",
          `${disableListingGroupSelect ? "Current l" : "L"}isting group`,
          "Specific listings",
        ],
      }
    : {
        options: ["all", "ids"],
        icons: [icons.all, icons.listing],
        labels: ["All listings", "Specific listings"],
      };

  React.useEffect(() => {
    setListingGroup((prev) => data.groupId);
    setConnectedHouses((prev) => data.listings);
    setNewConnectedListings((prev) => data.listings);
  }, [data]);

  React.useEffect(() => {
    const prevData = {...data};
    const newData = {
      // To change
      listings: connectedHouses,
      groupId: listingGroup,
      connection_type: data.connection_type,
    };
    if (!_.isEqual(prevData, newData)) {
      setData(newData);
    }
    if (["all", "group"].includes(data.connection_type) && showHouseList) {
      setShowHouseList(false);
    }
  }, [connectedHouses, listingGroup]);

  const handleListingGroupChange = (e) => setListingGroup(e.target.value);
  const handleObjectChange = (option) =>
    setData({...data, connection_type: option});

  const handleClose = () => {
    setShowHouseList(false);
  };

  const handleHouseSelect = (house) => {
    if (onlyOneSelection) {
      setNewConnectedListings((prev) => [house[listingKey]]);
      setConnectedHouses([house[listingKey]]);
    } else {
      const newList = newConnectedListings.includes(house[listingKey])
        ? newConnectedListings.filter((s) => s !== house[listingKey])
        : [...newConnectedListings, house[listingKey]];
      setNewConnectedListings((prev) => newList);
      setConnectedHouses(newList);
    }
  };

  const getDisabledListing = (listing) => {
    if (itemLimit === null) {
      return false;
    } else {
      if (
        newConnectedListings.length >= itemLimit &&
        !newConnectedListings.includes(listing.listing_id)
      ) {
        return true;
      } else {
        return false;
      }
    }
  };

  const customActions = (
    <>
      <PrimaryButton
        subdued
        size="small"
        variant="outlined"
        label="Close"
        onClick={handleClose}
      />
    </>
  );

  function getConnectionOptions() {
    switch (data.connection_type) {
      case "ids":
        return (
          <>
            {!showHouseList && (
              <div className={classes.row}>
                <Box
                  flex={1}
                  display="flex"
                  flexDirection="column"
                  style={{gap: THEME.spacing.xs}}
                >
                  <Typography variant="subtitle2">
                    {`${newConnectedListings.length} Listing${newConnectedListings.length !== 1 ? "s" : ""}`}
                  </Typography>
                  {!!type && (
                    <Typography>
                      {`Edit settings below to customize your ${type} for these listings`}
                    </Typography>
                  )}
                </Box>
                <PrimaryButton
                  size="small"
                  disabled={disableEdit}
                  label="Edit connected listings"
                  onClick={() => setShowHouseList(true)}
                />
              </div>
            )}
            {showHouseList && (
              <div className={classes.connectHouses}>
                <Box
                  display="flex"
                  alignItems="flex-end"
                  justifyContent="space-between"
                  paddingRight={4}
                >
                  <Typography
                    component="div"
                    className={clsx(classes.title, classes.titlePadding)}
                  >
                    Select listings
                    <Typography
                      component="span"
                      className={classes.connectedListings}
                    >
                      {itemLimit !== null ? (
                        <>
                          {newConnectedListings.length >= itemLimit ? (
                            <Typography component={"span"} color="error">
                              {newConnectedListings.length}
                            </Typography>
                          ) : (
                            `${newConnectedListings.length}`
                          )}
                          {`/${itemLimit} listings selected`}
                          <InfoText
                            text={`You can only assign a maximum of ${itemLimit} listings per group`}
                          />
                        </>
                      ) : (
                        `${newConnectedListings.length} listing${newConnectedListings.length !== 1 ? "s" : ""} selected`
                      )}
                    </Typography>
                  </Typography>
                  {!!disableSearch && !!onlyHouses && (
                    <Box paddingTop={4}>{customActions}</Box>
                  )}
                </Box>
                <div className={classes.housesList}>
                  <input
                    id="selected-listings-list-values"
                    style={{display: "none"}}
                    readOnly
                    value={newConnectedListings?.toString() ?? ""}
                  />
                  <ListingList
                    multiselect
                    disableGutters
                    itemLimit={itemLimit}
                    listingKey={listingKey}
                    isInsideModal={isInsideModal}
                    getDisabled={getDisabledListing}
                    onlyHouses={allowOnlyListings ? listingsListed : null}
                    disableSearch={disableSearch}
                    selectHouse={handleHouseSelect}
                    selected={newConnectedListings}
                    customActions={
                      keepOpen ? <></> : disableSearch ? <></> : customActions
                    }
                  />
                </div>
              </div>
            )}
          </>
        );

      case "group":
        if (disableListingGroupSelect) {
          return null;
        }
        return (
          <div className="mt-2">
            <FilledSelect
              fullWidth
              label="Listing group"
              value={listingGroup}
              onChange={handleListingGroupChange}
            >
              <MenuItem value="" disabled>
                {"Select a listing group"}
              </MenuItem>
              {listingGroupsKeys.map((lg) => (
                <MenuItem
                  key={lg}
                  value={lg}
                  selected={lg === listingGroup}
                  classes={{selected: classes.selected}}
                >
                  {listingGroups[lg].name}
                </MenuItem>
              ))}
            </FilledSelect>
          </div>
        );

      default:
        return null;
    }
  }

  return (
    <div className={clsx(classes.root, {[classes.grow]: showHouseList})}>
      {!disableConnectionTypes && (
        <Typography className={classes.title}>Applies To</Typography>
      )}
      {!disableConnectionTypes && (
        <MultiButtonBar
          thin
          value={data.connection_type}
          disabled={disabledButtons}
          options={multiButtonBarOptions.options}
          icons={multiButtonBarOptions.icons}
          labels={multiButtonBarOptions.labels}
          onChange={handleObjectChange}
          id={"mbutton-experiences-applies-to"}
        />
      )}
      {getConnectionOptions()}
    </div>
  );
}
