import {makeStyles} from "@material-ui/core/styles";

const usePreviewBPPanelStyles = makeStyles((theme) => ({
  previewContainer: {
    flex: 1,
    padding: theme.spacing(3, 5),
    overflow: "auto",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    position: "relative",
    "&::-webkit-scrollbar": {
      width: "0!important",
      height: "0!important",
    },
  },
  loader: {color: "white"},
  loaderContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
  },
  contentContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    position: "relative",
    overflowY: "auto",
    overflowX: "hidden",
    borderRadius: 50,
    zoom: 0.7,
    "&::-webkit-scrollbar": {width: "0!important"},
  },
  dropShadow: {
    background: "rgba(0, 0, 0, 0.30)",
    boxShadow: "0px 0px 25px 10px rgba(0,0,0,0.3)",
    position: "absolute",
    top: 30,
    left: "50%",
    height: "calc(100% - 60px)!important",
    transform: "translate(-50%, 0)",
  },
  frame: {
    border: "10px solid #000000",
    backgroundColor: "#000000",
    height: "100%",
    borderRadius: 42,
  },
  emptyIDCollectionBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
    position: "relative",
  },
  closeIconEmpty: {
    position: "absolute",
    top: theme.spacing(4),
    right: theme.spacing(4),
  },
  autohostContainer: {
    borderRadius: 50,
    height: "100%",
    width: "100%",
  },
  autohostImg: {
    width: "100%",
    height: "100%",
    objectFit: "contain",
  },
}));

export default usePreviewBPPanelStyles;
