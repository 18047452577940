import React from "react";
// Redux
import {useDispatch, useSelector} from "react-redux";
// UI
import {
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Divider,
  IconButton,
  MenuItem,
  MenuList,
  TextField,
  Typography,
  useTheme,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Close";
import FilterIcon from "@material-ui/icons/FilterList";
import BarChartIcon from "@material-ui/icons/BarChart";
import PieChartIcon from "@material-ui/icons/PieChart";
import LineChartIcon from "@material-ui/icons/Timeline";
import ColumnsIcon from "@material-ui/icons/ViewColumnRounded";
import useReportTableStyles from "styles/useReportTableStyles";
// Custom
import {SelectedFilterList} from "components/Helpers/filterHelpers";
import CustomCellElement from "components/Helpers/CustomCellElement";
import VirtualizedTable from "components/Tables/VirtualizedTable";
import NotificationAlert from "core/alerts/NotificationAlert";
import PrimaryButton from "core/buttons/PrimaryButton";
import ExpandButton from "core/buttons/ExpandButton";
import FilterMenus from "components/Dialogs/FilterMenus";
import CustomMenu from "core/menus/CustomMenu";
import SearchBar from "core/bars/SearchBar";
// Actions
import {updateGuest} from "redux/actions/guestsActions";
// Utilities
import {
  guestFilters,
  guestIntegrationLabels,
  integrations,
  metricLabels,
  reportExportTypes,
} from "configuration/constants.js";
import {
  fieldParams,
  metricsParams,
  reportMetrics,
} from "configuration/specs.js";
import {getNestedObject, getSender} from "utilities/helperFunctions";
import usePrevious from "hooks/usePrevious";
import clsx from "clsx";
import _ from "lodash";

export default function ReportTable({
  create,
  metric,
  filters,
  viewType,
  setMetric,
  setFilters,
  setViewType,
  exportButton,
  selectedReport,
  searchText,
  setSearchText,
  loadNextPage,
  isPreview,
  previewData,
  hideCheckboxColumn,
  crmView,
  setSelectedIds,
  openGuestPanel,
  disableFilters,
  loadingData,
  exportDataHandler = function () {},
}) {
  const classes = useReportTableStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const customFieldsMap = React.useRef({});
  // Selectos
  const reports = useSelector((state) => state.reportsReducer.reports);
  const loading = useSelector((state) => state.defaultReducer.loading).reports;
  const error = useSelector((state) => state.defaultReducer.errors).reports;
  const guests = useSelector((state) => state.defaultReducer.guests_dict);
  const groups = useSelector((state) => state.defaultReducer.listing_groups);
  const totals = useSelector((state) => state.defaultReducer.totals);
  // State
  const [metricsAnchorEl, setMetricsAnchorEl] = React.useState(null);
  const [filtersAnchorEl, setFiltersAnchorEl] = React.useState(null);
  const [selectedGuests, setSelectedGuests] = React.useState([]);
  const [writeFilter, setWriteFilter] = React.useState(null);
  const [openAlert, setOpenAlert] = React.useState(false);
  const [reportHits, setReportHits] = React.useState([]);
  const [sortParams, setSortParams] = React.useState({
    sortBy: "",
    sortDirection: "ASC",
  });
  const [exportMenuAnchorEl, setExportMenuAnchorEl] = React.useState(null);
  const [columnsMenuAnchorEl, setColumnsMenuAnchorEl] = React.useState(null);
  const [allColumns, setAllColumns] = React.useState([]);
  // General
  const prevSelectedReport = usePrevious(selectedReport);
  const report = create ? null : reports[selectedReport.report_id];
  const tableFields = metricsParams[metric]?.table_fields || [];
  const metricObj = !!metric ? metricsParams[metric]?.obj : null;
  const hasFilters = !!metric && !!metricsParams[metric]?.filters.length;
  const hasViewTypes = !!metric && !!metricsParams[metric]?.views.length;
  const hasNextPage = isPreview
    ? previewData[0]?.start_key
    : !!report?.start_key;
  const rowCount = hasNextPage ? reportHits.length + 1 : reportHits.length;
  const loadingTable =
    (isPreview || report?.metrics[0]?.metric !== metric) &&
    (!previewData.length || previewData[0]?.metric !== metric) &&
    !error.hasError;
  const allFilters = React.useMemo(() => {
    let newFilters = [...guestFilters.filterKeys];
    if (groups.length < 2) {
      newFilters = newFilters.filter((f) => f !== "guest_group_id");
    }
    return newFilters;
  }, [groups]);

  const [selectedColumns, setSelectedColumns] = React.useState([
    ...tableFields,
  ]);

  const removeFilter = (selectedFilter) => {
    const newFilters = [...filters];
    const filterIndex = filters.findIndex(
      (f) => f.path === selectedFilter.path && f.value === selectedFilter.value,
    );
    if (filterIndex > -1) {
      newFilters.splice(filterIndex, 1);
      setFilters(newFilters);
    }
  };

  const searchBarProps =
    !!crmView && !disableFilters
      ? {
          enableFilters: true,
          filters: filters,
          disableSelectedFilters: true,
          filterKeys: allFilters,
          handleDeleteFilter: removeFilter,
          setFilters,
        }
      : {};

  const columns = React.useMemo(() => {
    const newColumns = [];

    if (!!crmView) {
      if (!hideCheckboxColumn) {
        newColumns.push({
          width: 1,
          label: (
            <Checkbox
              size="small"
              color="secondary"
              checked={selectedGuests.includes("all")}
              onChange={(e) => toggleSelectAll(e)}
            />
          ),
          field: "checkbox",
          dataKey: "checkbox",
          flexGrow: 0.5,
          customCell: true,
        });
      }
      if (metric === "guests") {
        newColumns.push({
          width: 1,
          label: "dot",
          field: "dot",
          dataKey: "dot",
          flexGrow: 0.2,
          customCell: true,
          hideLabel: true,
        });
      }
    }

    _.each(tableFields, (field) => {
      if (!selectedColumns.includes(field)) {
        return;
      }

      newColumns.push({
        field,
        width: 1,
        label: fieldParams[field].name,
        dataKey: fieldParams[field].path.slice(1).join("."),
        flexGrow: 1,
        customCell: true,
        sort: true,
        numeric: false,
      });
    });

    if (
      !!crmView &&
      previewData?.length &&
      previewData[0]?.hits?.length &&
      previewData[0]?.hits[0]?.custom_fields
    ) {
      Object.keys(customFieldsMap.current).forEach((key, i) => {
        if (!selectedColumns.includes(key)) {
          return;
        }

        newColumns.push({
          field: `custom_field_${i}`,
          width: 1,
          label: fieldParams[key]?.name || key,
          dataKey: customFieldsMap.current[key],
          flexGrow: 1,
          customCell: true,
          sort: true,
          numeric: false,
        });
      });
    }

    if (!!crmView) {
      newColumns.push({
        field: "view_profile",
        width: 1,
        label: "view profile",
        dataKey: "view_profile",
        flexGrow: 0.8,
        customCell: true,
        hideLabel: true,
      });
    }

    return newColumns;
  }, [metric, reportHits, selectedGuests, selectedColumns, hideCheckboxColumn]);

  React.useEffect(() => {
    if (!!crmView) {
      setSelectedIds(selectedGuests.filter((g) => g !== "all"));
    }
  }, [selectedGuests]);

  React.useEffect(() => setSelectedGuests((prev) => []), [metric, filters]);

  React.useEffect(() => {
    let timer = null;
    if (!!crmView && metric === "guests") {
      timer = setTimeout(() =>
        setReportHits((prev) =>
          prev.map((g) => {
            if (!!guests[g.guest_id]) {
              return {...g, ...guests[g.guest_id]};
            } else {
              return g;
            }
          }),
        ),
      );
    }
    return () => clearTimeout(timer);
  }, [guests, metric]);

  React.useEffect(() => {
    const isDifferentSelectedReport =
      prevSelectedReport?.report_id !== selectedReport?.report_id;
    let newHits = isPreview
      ? !!previewData?.length
        ? previewData[0].hits
        : []
      : report?.hits || [];

    if (metricObj === "message") {
      newHits = newHits.map((i) => {
        const isNotification = !!i.notification_type;
        const guestName = guests[i.guest_id]?.name || "Guest";
        const sender = getSender(
          isNotification ? "notification" : i.sender_type,
        );
        return {
          ...i,
          name: guestName,
          channel: i.channel ?? (isNotification ? "notification" : ""),
          sender_type: sender,
        };
      });
    }

    if (metricObj === "booking") {
      newHits = newHits.map((i) => {
        const guest = guests[i.guest_id];
        return {
          ...i,
          integration: guestIntegrationLabels[guest?.integration],
          booking_payment: i.payment,
          payment: !!i.payment ? i.payment.total_guest_paid : null,
        };
      });
    }

    if (!!sortParams.sortBy && !isDifferentSelectedReport) {
      sort(newHits);
    } else {
      isDifferentSelectedReport &&
        setSortParams((prev) => ({sortBy: "", sortDirection: "ASC"}));
      setReportHits((prev) => newHits);
    }

    let newCustomFieldsMap = {};
    if (
      !!crmView &&
      previewData?.length &&
      previewData[0]?.hits?.length &&
      previewData[0]?.hits[0]?.custom_fields
    ) {
      previewData[0].hits.forEach((hit) => {
        if (hit.custom_fields) {
          Object.keys(hit.custom_fields).forEach((key) => {
            newCustomFieldsMap[key] = `custom_fields.${key}`;
          });
        }
      });
    }

    customFieldsMap.current = newCustomFieldsMap;
    setAllColumns((prev) => [
      ...new Set([...tableFields, ...Object.keys(newCustomFieldsMap)]),
    ]);
  }, [isPreview, previewData, create, reports, selectedReport]);

  React.useEffect(() => {
    let timer = null;
    if (!!sortParams.sortBy) {
      timer = setTimeout(() => {
        sort();
      });
    }

    return () => clearTimeout(timer);
  }, [sortParams]);

  React.useEffect(() => {
    setSelectedColumns([...tableFields]);
  }, [metric]);

  React.useEffect(() => {
    if (!!hideCheckboxColumn && !!selectedGuests.length) {
      setSelectedGuests((prev) => []);
    }
  }, [hideCheckboxColumn]);

  const handleSearchInput = (val) => setSearchText(val);
  const closeMetricsMenu = () => setMetricsAnchorEl(null);
  const closeFiltersMenu = () => setFiltersAnchorEl(null);
  const closeWriteFilter = () => setWriteFilter(null);
  const selectViewType = (view) => () => setViewType(view);
  const isRowLoaded = ({index}) => !hasNextPage || index < reportHits.length;
  const loadMoreRows = loading ? () => {} : loadNextPage;

  const clearAll = () => {
    handleSearchInput("");
    setSelectedGuests((prev) => []);
    setFilters([]);
    if (crmView) {
      setMetric("guests");
    }
  };

  const toggleSelectAll = (e) => {
    const val = e.target.checked;
    if (val) {
      let selectedGuestsList = [...reportHits.map((g) => g.guest_id), "all"];
      if (selectedGuestsList.length > 50) {
        setOpenAlert((prev) => true);
        setSelectedGuests((prev) => selectedGuestsList.slice(0, 50));
      } else {
        setSelectedGuests((prev) => selectedGuestsList);
      }
    } else {
      setSelectedGuests((prev) => []);
    }
  };

  const onCheck = (guestId) => {
    if (selectedGuests.includes("all")) {
      setSelectedGuests((prev) =>
        prev.filter((g) => !(g === guestId || g === "all")),
      );
    } else {
      if (selectedGuests.includes(guestId)) {
        setSelectedGuests((prev) => prev.filter((g) => g !== guestId));
      } else {
        if (selectedGuests.length < 50) {
          setSelectedGuests((prev) => [...prev, guestId]);
        } else {
          setOpenAlert((prev) => true);
        }
      }
    }
  };

  const viewProfile = (index) => {
    const selectedGuest = reportHits[index];
    if (!!selectedGuest?.unread_messages) {
      dispatch(
        updateGuest({
          guest_id: selectedGuest.guest_id,
          field: "unread_messages",
          val: false,
          disableAlert: true,
        }),
      );
    }
    openGuestPanel(selectedGuest);
  };

  const sort = (newHits) => {
    let sortKey = [sortParams.sortBy];
    let directions = [sortParams.sortDirection.toLowerCase()];
    const hits = newHits ?? reportHits;

    if (sortParams.sortBy === "total") {
      sortKey = ["amount"];
    }

    const sortedData = _.orderBy(hits, sortKey, directions);
    setReportHits((prev) => sortedData);
  };

  const onMetricSelect = (selectedMetric) => {
    setMetric(selectedMetric);
    closeMetricsMenu();
  };

  const onFilterSelect = (selectedFilter) => {
    const existingFilter = filters.find(
      (f) => f.path === selectedFilter.path && f.value === selectedFilter.value,
    );
    if (!existingFilter) {
      if (!selectedFilter.value) {
        setWriteFilter((prev) => ({...selectedFilter, value: ""}));
      } else {
        setFilters([...filters, selectedFilter]);
      }
    }
    closeFiltersMenu();
  };

  const saveWriteFilter = () => {
    setFilters([...filters, writeFilter]);
    setWriteFilter((prev) => null);
  };

  const onWriteFilterKeyDown = (event) => {
    if (event.key === "Escape") {
      closeWriteFilter();
      return false;
    } else if (!["Enter", "Tab"].includes(event.key)) {
      return false;
    }
    saveWriteFilter();
  };

  const onWriteFilterChange = (e) => {
    const val = e.target.value;
    setWriteFilter((prev) => ({...prev, value: val}));
  };

  const onRowClick = ({index}) => {
    console.log("Row selected: ", reportHits[index]);
  };

  const getCustomCell = (columnConfig, cellData, rowIndex) => {
    const columnDataKey = columnConfig.dataKey;
    const cellValue = columnDataKey.includes(".")
      ? getNestedObject(reportHits[rowIndex], columnDataKey.split("."))
      : cellData;
    const field = columnDataKey.includes(".")
      ? columnDataKey.split(".").pop()
      : columnConfig.field;

    const obj = columnDataKey.includes(".")
      ? {...reportHits[rowIndex], [field]: cellValue}
      : reportHits[rowIndex];

    return (
      <div className={classes.column}>
        <CustomCellElement
          data={cellValue}
          customClass={
            columnConfig.field === "checkbox"
              ? `guest-checkbox-${rowIndex}`
              : null
          }
          rowIndex={rowIndex}
          obj={obj}
          field={field}
          simpleBkgDate={columnDataKey.includes(".")}
          selectedValues={selectedGuests}
          viewProfile={viewProfile}
          onCheck={columnConfig.dataKey === "checkbox" ? onCheck : null}
        />
      </div>
    );
  };

  function getMetricsMenuContent() {
    return (
      <MenuList id="metrics-menu">
        {reportMetrics.map((m) => (
          <MenuItem
            key={m.name}
            value={m.metric}
            selected={m.metric === metric}
            classes={{selected: classes.selected}}
            onClick={() => onMetricSelect(m.metric)}
          >
            {m.name}
          </MenuItem>
        ))}
      </MenuList>
    );
  }

  function getChartIcon(view) {
    switch (view) {
      case "histogram":
        return (
          <BarChartIcon
            className="chart-icon"
            color={viewType === view ? "secondary" : "disabled"}
          />
        );
      case "pie_chart":
        return (
          <PieChartIcon
            className="chart-icon"
            color={viewType === view ? "secondary" : "disabled"}
          />
        );
      case "line_chart":
        return (
          <LineChartIcon
            className="chart-icon"
            color={viewType === view ? "secondary" : "disabled"}
          />
        );
      default:
        return null;
    }
  }

  const handleSort = ({sortBy, sortDirection}) => {
    setSortParams((prev) => ({sortBy, sortDirection}));
  };

  const rowGetter = ({index}) => {
    const data = reportHits[index];
    let row = {};
    switch (metricObj) {
      case "notification":
      case "listing":
      case "booking":
      case "message":
        row = {...data, checkbox: data.guest_id};
        break;
      case "guest":
        row = {
          ...data,
          integration: integrations[data.integration],
          name: !!data.name ? data.name : "Unknown",
          email: data.email,
          checkbox: data.guest_id,
        };
        break;
    }
    return row;
  };

  function getFilterTags() {
    if (disableFilters) {
      return null;
    }
    return (
      <SelectedFilterList outlined filters={filters} onDelete={removeFilter} />
    );
  }

  function getColumnsMenuContent() {
    return (
      <div id="columns-menu" className={classes.columnsMenu}>
        {allColumns.map((field) => (
          <MenuItem
            key={field}
            className={classes.columnsMenuItem}
            onClick={() => {
              if (selectedColumns.includes(field)) {
                setSelectedColumns((prev) => prev.filter((f) => f !== field));
              } else {
                setSelectedColumns((prev) => [...new Set([...prev, field])]);
              }
            }}
          >
            <Checkbox checked={selectedColumns.includes(field)} />
            <Typography>{fieldParams[field]?.name || field}</Typography>
          </MenuItem>
        ))}
      </div>
    );
  }

  function getExportMenuContent() {
    return (
      <MenuList id="export-options-menu">
        {Object.keys(reportExportTypes).map((exportType) => (
          <MenuItem
            key={exportType}
            onClick={() => exportDataHandler(exportType)}
          >
            {`Export as .${exportType}`}
          </MenuItem>
        ))}
      </MenuList>
    );
  }

  const closeExportMenu = () => setExportMenuAnchorEl((prev) => null);

  const closeColumnsMenu = () => setColumnsMenuAnchorEl((prev) => null);

  const crmButtonsRow = (
    <Box className={classes.crmButtonsContainer}>
      <Box className={classes.row} alignItems="center">
        {!!filters.length && getFilterTags()}
        {!!filters.length && (
          <Button
            className={classes.clearAllBtn}
            variant="text"
            color="secondary"
            onClick={clearAll}
          >
            {"Clear all"}
          </Button>
        )}
      </Box>
      <Box className={clsx(classes.row, classes.gap8)} alignItems="center">
        <ExpandButton
          size="small"
          label="Columns"
          color="secondary"
          startIcon={<ColumnsIcon />}
          style={{height: "fit-content", padding: theme.spacing(1, 3)}}
          onClick={(e) => setColumnsMenuAnchorEl(e.currentTarget)}
        />
        {!!exportButton && exportButton}
      </Box>
    </Box>
  );

  return (
    <div className={classes.root}>
      <NotificationAlert
        open={openAlert}
        vertical="bottom"
        horizontal="center"
        onClose={() => setOpenAlert((prev) => false)}
        message={"No more than 50 guests can be selected at a time"}
      />
      <CustomMenu
        open={Boolean(columnsMenuAnchorEl)}
        anchorEl={columnsMenuAnchorEl}
        placement="bottom"
        overflowAuto
        hideScrollbar
        onClose={closeColumnsMenu}
        content={getColumnsMenuContent()}
      />
      <CustomMenu
        open={Boolean(exportMenuAnchorEl)}
        anchorEl={exportMenuAnchorEl}
        placement="right-start"
        onClose={closeExportMenu}
        content={getExportMenuContent()}
      />
      <CustomMenu
        open={Boolean(metricsAnchorEl)}
        anchorEl={metricsAnchorEl}
        onClose={closeMetricsMenu}
        content={getMetricsMenuContent()}
      />
      <FilterMenus
        filterList={hasFilters ? metricsParams[metric].filters : []}
        anchorEl={filtersAnchorEl}
        onClose={closeFiltersMenu}
        onFilterSelect={onFilterSelect}
      />
      <div className={clsx(classes.row, classes.options, {crm: !!crmView})}>
        <div className={clsx(classes.filters, {crm: !!crmView})}>
          {!crmView && (
            <ExpandButton
              label={!!metric ? metricLabels[metric] : "Select a Metric"}
              onClick={(e) => setMetricsAnchorEl(e.currentTarget)}
              hasPopup
            />
          )}
          {hasFilters && !crmView && (
            <PrimaryButton
              rounded
              label="Filters"
              size="small"
              variant="outlined"
              icon={<FilterIcon />}
              iconPlacement="end"
              onClick={(e) => setFiltersAnchorEl(e.currentTarget)}
            />
          )}
          {!crmView && getFilterTags()}
          {!!writeFilter && (
            <Chip
              label={
                <>
                  {fieldParams[writeFilter.id].name}:
                  <TextField
                    autoFocus
                    placeholder="Type here"
                    className={classes.chipInput}
                    value={writeFilter.value}
                    onChange={onWriteFilterChange}
                    onKeyDown={onWriteFilterKeyDown}
                  />
                </>
              }
              onDelete={closeWriteFilter}
              deleteIcon={<DeleteIcon />}
              color="primary"
              variant="outlined"
              size="small"
              className={clsx(classes.chip, {crm: !!crmView})}
              classes={{
                deleteIcon: classes.chipDeleteIcon,
                label: classes.chipLabel,
              }}
            />
          )}
          <SearchBar
            rounded
            disableGutters
            noChipMargin={!!crmView}
            searchInput={searchText}
            handleSearchInput={handleSearchInput}
            preferredFilters={guestFilters.preferredFilters}
            placeholder={`Search ${totals.guests || ""} guests`}
            {...searchBarProps}
          />
          {!!crmView && crmButtonsRow}
        </div>
        {hasViewTypes && !crmView && (
          <div className={classes.graphics}>
            {metricsParams[metric].views.map((v) => (
              <IconButton
                key={v}
                size="small"
                className="icon-button"
                onClick={selectViewType(v)}
              >
                {getChartIcon(v)}
              </IconButton>
            ))}
          </div>
        )}
      </div>
      <div className={classes.tableSection}>
        {!metric ? (
          <Divider className={classes.divider} />
        ) : loadingData || loadingTable ? (
          <>
            <Divider className={classes.divider} />
            <div className={classes.center}>
              <CircularProgress color="secondary" />
            </div>
          </>
        ) : !!reportHits.length && !!selectedColumns.length ? (
          <Box height={"100%"} minWidth={columns.length * 150}>
            <VirtualizedTable
              rowCount={reportHits.length}
              loaderRowCount={rowCount}
              rowGetter={rowGetter}
              columns={columns}
              onRowClick={onRowClick}
              rowHeight={56}
              getCustomCell={getCustomCell}
              useInfiniteLoader
              isRowLoaded={isRowLoaded}
              loadMoreRows={loadMoreRows}
              sort={handleSort}
              sortBy={sortParams.sortBy}
              sortDirection={sortParams.sortDirection}
              disableAutoHeaderHeight={!!crmView}
            />
          </Box>
        ) : (
          <>
            <Divider className={classes.divider} />
            <Typography
              variant="h2"
              align="center"
              className="mt-3"
              color="textSecondary"
            >
              {"No data found"}
            </Typography>
          </>
        )}
      </div>
    </div>
  );
}
