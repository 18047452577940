import React from "react";
import {useDispatch, useSelector} from "react-redux";
// UI
import {makeStyles} from "@material-ui/core/styles";
import {
  TextField,
  Button,
  MenuList,
  MenuItem,
  CircularProgress,
  IconButton,
  SvgIcon,
  Typography,
  Box,
} from "@material-ui/core";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import {ReactComponent as ExportIcon} from "assets/icons/Icon_Export_Report.svg";
// Custom
import CustomCardHeader from "core/cards/CustomCardHeader";
import ReportChart from "components/Charts/ReportChart";
import ReportTable from "components/Tables/ReportTable";
import PrimaryButton from "core/buttons/PrimaryButton";
import ExpandButton from "core/buttons/ExpandButton";
import CustomMenu from "core/menus/CustomMenu";
import CloseIconButton from "core/buttons/CloseIconButton";
// Actions
import {
  getReportPreview,
  getReports,
  postReport,
  deleteReport,
  selectReport,
  getExportReport,
} from "redux/actions/reportsActions";
import {
  closeGeneralError,
  openGeneralError,
} from "redux/actions/settingsActions";
// Utilities
import {
  intervals,
  maxAllowedStartValues,
  metricLabels,
  reportExportTypes,
} from "configuration/constants.js";
import {metricsParams} from "configuration/specs.js";
import {saveAsFile} from "utilities/fileUtilities";
import usePrevious from "hooks/usePrevious";
import _ from "lodash";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    overflow: "hidden",
  },
  headerRow: {
    display: "flex",
    alignItems: "flex-end",
    marginBottom: theme.spacing(2),
  },
  center: {
    alignItems: "center",
    justifyContent: "center",
  },
  content: {
    flexGrow: 1,
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
  },
  header: {paddingTop: theme.spacing(4)},
  actions: {
    height: "100%",
    display: "flex",
    alignSelf: "flex-end",
    alignItems: "flex-start",
    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  inputTitle: {
    fontSize: theme.typography.h4.fontSize,
    lineHeight: theme.typography.h4.lineHeight,
    fontWeight: theme.typography.h4.fontWeight,
    color: theme.palette.primary.main,
    padding: 0,
    height: "100%",
  },
  editIcon: {
    fontSize: 17,
    color: "#A8A8A8",
  },
  icon: {
    transition: "0.15s",
    color: "#A6A6A6",
    fontSize: 22,
    "&.-small": {fontSize: 16},
    "&:hover": {color: theme.palette.secondary.main},
  },
  iconBtn: {
    width: 28,
    height: 28,
    padding: 2,
  },
  cancelBtn: {
    color: "#A8A8A8",
    fontSize: 14,
  },
}));

export default function ReportDetailsPanel({
  selectedReport,
  onSave,
  onClose,
  disableEdit,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const reports = useSelector((state) => state.reportsReducer.reports);
  const reportList = useSelector((state) => state.reportsReducer.report_list);
  const error = useSelector((state) => state.defaultReducer.errors).reports;
  const selectedReportId = useSelector(
    (state) => state.reportsReducer.selected_report_id,
  );
  // State
  const [report, setReport] = React.useState(null);
  const [reportName, setReportName] = React.useState("");
  const [start, setStart] = React.useState(0);
  const [filters, setFilters] = React.useState([]);
  const [metric, setMetric] = React.useState(null);
  const [viewType, setViewType] = React.useState(null);
  const [interval, setInterval] = React.useState("month");
  const [intervalMenuAnchorEl, setIntervalMenuAnchorEl] = React.useState(null);
  const [previewBuckets, setPreviewBuckets] = React.useState([]);
  const [previewData, setPreviewData] = React.useState([]);
  const [searchText, setSearchText] = React.useState("");
  const [isPreview, setIsPreview] = React.useState(false);
  const [editName, setEditName] = React.useState(false);
  const [reportHasChanges, setReportHasChanges] = React.useState(false);
  const [hasMetricChanges, setHasMetricChanges] = React.useState(false);
  const [exportMenuAnchorEl, setExportMenuAnchorEl] = React.useState(null);
  // General
  const prevMetric = usePrevious(metric);
  const prevInterval = usePrevious(interval);
  const create = selectedReport === "new";
  const reportMetrics = report?.metrics || [];
  const reportHits = report?.hits || [];
  const saveDisabled = !metric || !viewType || !reportName.trim();

  React.useEffect(() => {
    const isNew = selectedReport === "new";
    setReport((prev) => (isNew ? null : reports[selectedReport.report_id]));
  }, [reports, selectedReport]);

  React.useEffect(() => {
    setHasMetricChanges((prev) => {
      return (
        (!!metric && metric !== reportMetrics[0]?.metric) ||
        (!!viewType && viewType !== reportMetrics[0]?.view) ||
        interval !== reportMetrics[0]?.interval
      );
    });
  }, [metric, viewType, interval]);

  React.useEffect(() => {
    if (
      !create &&
      !!selectedReport &&
      (!report || report.minimalData || !!error.hasError)
    ) {
      dispatch(getReports(selectedReport.report_id));
    }
    resetParams();
    if (!!report && !!selectedReportId) {
      dispatch(selectReport(null));
    }
  }, [report]);

  React.useEffect(() => {
    let timer = null;
    let view = viewType;
    let willChangeState = false;
    const currFilters =
      reportMetrics[0]?.filters?.map((f) => ({path: f.path, value: f.value})) ||
      [];
    const searchFilters = filters.map((f) => ({path: f.path, value: f.value}));
    const allowSearch = !!searchText.trim();
    const newStart =
      prevInterval !== interval || prevMetric !== metric ? 0 : start;

    if (!!metric && metric !== prevMetric) {
      if (metric !== reportMetrics[0]?.metric) {
        view = metricsParams[metric].views[0];
      } else if (metric === reportMetrics[0]?.metric) {
        view = reportMetrics[0].view || null;
      }
    }

    if (view !== viewType) {
      setViewType((prev) => view);
      willChangeState = true;
    }

    if (newStart !== start) {
      setStart((prev) => newStart);
      willChangeState = true;
    }

    if (willChangeState) {
      return;
    }

    if (
      !!metric &&
      !!view &&
      (hasMetricChanges ||
        start > 0 ||
        allowSearch ||
        !_.isEqual(searchFilters, currFilters))
    ) {
      timer = setTimeout(() => {
        setIsPreview((prev) => true);
        dispatch(
          getReportPreview(
            metric,
            newStart,
            view,
            interval,
            searchFilters,
            searchText,
            handleReportPreviewSuccess,
            handleReportPreviewError,
          ),
        );
      });
    } else {
      timer = setTimeout(() => {
        setIsPreview((prev) => false);
        setPreviewData((prev) => []);
        setPreviewBuckets((prev) => []);
      });
    }

    return () => clearTimeout(timer);
  }, [
    hasMetricChanges,
    metric,
    viewType,
    interval,
    start,
    searchText,
    filters,
  ]);

  const closeIntervalMenu = () => setIntervalMenuAnchorEl(null);
  const handleCloseError = () => dispatch(closeGeneralError());
  const handleReportPreviewSuccess = (response) => {
    if (response?.[0]?.start_hits === 0) {
      setPreviewBuckets((prev) => response[0].buckets);
    }
    setPreviewData((prev) => response);
  };

  const handleReportPreviewError = () => {
    setPreviewBuckets((prev) => []);
    setPreviewData((prev) => []);
  };

  const changeStartRange = (type) => () => {
    setStart((prev) => (type === "before" ? ++prev : --prev));
  };

  const handleFiltersChange = (newFilters) => {
    setFilters((prev) => newFilters);
    setReportHasChanges((prev) => true);
  };

  const handleViewChange = (newView) => {
    setViewType((prev) => newView);
    setReportHasChanges((prev) => true);
  };

  const handleSearchTextChange = (newSearch) => {
    setSearchText((prev) => newSearch);
    if (newSearch !== searchText) {
      setReportHasChanges((prev) => true);
    }
  };

  const handleSaveSuccess = (response) => {
    setHasMetricChanges((prev) => false);
    setIsPreview((prev) => false);
    onSave(response);
  };

  const handleSaveError = () => {
    dispatch(
      openGeneralError({
        open: true,
        message: "Report could not be saved",
        disableSeverity: true,
        subtitle: error.message,
        onClose: handleCloseError,
      }),
    );
  };

  const resetParams = () => {
    const reportFilters =
      reportMetrics[0]?.filters?.map((f) => ({path: f.path, value: f.value})) ||
      [];
    setReportHasChanges((prev) => false);
    setReportName((prev) => report?.title || "");
    setMetric((prev) => reportMetrics[0]?.metric);
    setFilters((prev) => reportFilters);
    setViewType((prev) => reportMetrics[0]?.view);
    setInterval((prev) => reportMetrics[0]?.interval || "month");
    setStart((prev) => 0);
    setSearchText((prev) => "");
    setEditName((prev) => false);
  };

  const handleCancel = () => {
    if (create) {
      onClose();
    } else {
      setPreviewData((prev) => []);
      setPreviewBuckets((prev) => []);
      resetParams();
    }
  };

  const loadNextHits = () => {
    if (isPreview) {
      dispatch(
        getReportPreview(
          metric,
          start,
          viewType,
          interval,
          filters,
          searchText,
          handleReportPreviewSuccess,
          handleReportPreviewError,
          reportHits.length,
        ),
      );
    } else {
      dispatch(
        getReports(
          report?.report_id,
          interval,
          start,
          reportHits.length,
          filters,
          searchText,
        ),
      );
    }
  };

  const handleSaveReport = () => {
    const reportId = create ? null : selectedReport.report_id;
    const starred = create ? true : !!reportMetrics[0]?.starred;
    const title = !!reportName.trim() ? reportName.trim() : "";
    const metrics = [
      {
        metric,
        filters: filters.map((f) => ({path: f.path, value: f.value})),
        view: viewType,
        interval: interval,
        starred,
      },
    ];
    dispatch(
      postReport(reportId, title, metrics, handleSaveSuccess, handleSaveError),
    );
  };

  const handleDeleteReport = () => {
    onClose();
    dispatch(deleteReport(selectedReport.report_id));
  };

  const handleIntervalSelect = (newInterval) => () => {
    closeIntervalMenu();
    setInterval((prev) => newInterval);
    if (newInterval !== interval) {
      setReportHasChanges((prev) => true);
    }
  };

  const handleTitleChange = (e) => {
    const val = e.target.value;
    setReportName((prev) => val);
    setReportHasChanges((prev) => true);
  };

  const handleMetricSelect = (newMetric) => {
    const metricNameSplit = metricLabels[newMetric].split(" - ");
    const newReportName =
      metricNameSplit.length > 1 ? metricNameSplit[1] : metricNameSplit[0];
    setFilters((prev) => []);
    setSearchText((prev) => "");
    setMetric((prev) => newMetric);
    setReportName((prev) => newReportName);
    if (newMetric !== metric) {
      setReportHasChanges((prev) => true);
    }
  };

  const handleExportMenuClose = () => setExportMenuAnchorEl((prev) => null);

  const onExportSuccess = (response, exportType) => {
    const reportName = selectedReport.title.toLowerCase().replace(" ", "-");
    saveAsFile(response, `${reportName}-report.${exportType}`, exportType);
  };
  const exportReport = (exportType) => {
    setExportMenuAnchorEl((prev) => null);
    dispatch(
      getExportReport({
        reportId: selectedReport.report_id,
        contentType: exportType,
        start,
        onSuccess: (r) => onExportSuccess(r, exportType),
      }),
    );
  };

  function getRangeLabel() {
    if (start > 0) return `${start} ${interval}${start > 1 ? "s" : ""} Ago`;
    else return `This ${interval}`;
  }

  const reportChart = React.useMemo(
    () => (
      <ReportChart
        create={create}
        metric={metric}
        view={viewType}
        interval={interval}
        selectedReport={selectedReport}
        previewData={previewBuckets}
        isPreview={isPreview}
        start={start}
      />
    ),
    [metric, viewType, interval, selectedReport, previewBuckets],
  );

  const reportTable = React.useMemo(
    () => (
      <ReportTable
        create={create}
        metric={metric}
        filters={filters}
        viewType={viewType}
        searchText={searchText}
        isPreview={isPreview}
        previewData={previewData}
        setMetric={handleMetricSelect}
        setFilters={handleFiltersChange}
        setViewType={handleViewChange}
        setSearchText={handleSearchTextChange}
        selectedReport={selectedReport}
        loadNextPage={loadNextHits}
      />
    ),
    [
      create,
      metric,
      filters,
      viewType,
      interval,
      selectedReport,
      searchText,
      reportHits,
      previewData,
    ],
  );

  if (selectedReportId || !reportList.length)
    return (
      <div className={clsx(classes.root, classes.center)}>
        <CircularProgress color="secondary" />
      </div>
    );

  return (
    <div className={classes.root}>
      <CustomMenu
        open={Boolean(intervalMenuAnchorEl)}
        anchorEl={intervalMenuAnchorEl}
        onClose={closeIntervalMenu}
        content={
          <MenuList id="range-menu">
            <MenuItem disabled value="">
              Select an interval
            </MenuItem>
            {intervals.map((i) => (
              <MenuItem
                key={i}
                selected={interval === i}
                value={i}
                onClick={handleIntervalSelect(i)}
                style={{textTransform: "capitalize"}}
              >
                {i}s
              </MenuItem>
            ))}
          </MenuList>
        }
      />
      <CustomMenu
        open={Boolean(exportMenuAnchorEl)}
        anchorEl={exportMenuAnchorEl}
        content={
          <MenuList id="export-menu">
            {Object.keys(reportExportTypes).map((exportType) => (
              <MenuItem
                key={`export-${exportType}`}
                onClick={() => exportReport(exportType)}
              >
                Export as .{exportType}
              </MenuItem>
            ))}
          </MenuList>
        }
        onClose={handleExportMenuClose}
      />
      <CustomCardHeader
        title={
          <div className={classes.headerRow}>
            {editName ? (
              <TextField
                fullWidth
                autoFocus
                value={reportName}
                inputProps={{className: classes.inputTitle}}
                InputProps={{disableUnderline: true}}
                placeholder="Report Name"
                onChange={handleTitleChange}
              />
            ) : (
              <>
                <Typography variant="h4" color="primary">
                  {reportName || "Untitled Report"}
                </Typography>
                <IconButton
                  className={classes.iconBtn}
                  style={{paddingBottom: 0}}
                  onClick={() => setEditName((prev) => true)}
                >
                  <EditIcon className={classes.editIcon} />
                </IconButton>
              </>
            )}
          </div>
        }
        subheader={
          <>
            <PrimaryButton
              variant="outlined"
              size="small"
              className="mr-1"
              style={{padding: 0}}
              onClick={changeStartRange("before")}
              disabled={start === maxAllowedStartValues[interval]}
              label={<NavigateBeforeIcon />}
            />
            <PrimaryButton
              variant="outlined"
              size="small"
              className="mr-2"
              style={{padding: 0}}
              onClick={changeStartRange("after")}
              disabled={start === 0}
              label={<NavigateNextIcon />}
            />
            <ExpandButton
              hasPopup
              size="small"
              label={getRangeLabel()}
              onClick={(e) => setIntervalMenuAnchorEl(e.currentTarget)}
            />
          </>
        }
        type="title"
        variant="h4"
        action={
          disableEdit ? (
            <>
              <IconButton
                className={classes.iconBtn}
                onClick={(e) => setExportMenuAnchorEl(e.currentTarget)}
              >
                <SvgIcon
                  viewBox="0 0 16 16"
                  className={clsx(classes.icon, "-small", "ml-3 mr-2")}
                  component={ExportIcon}
                />
              </IconButton>
              <Box ml={2}>
                <CloseIconButton sm onClick={() => onClose()} />
              </Box>
            </>
          ) : (
            <>
              {!create && !reportHasChanges && (
                <IconButton
                  className={classes.iconBtn}
                  onClick={handleDeleteReport}
                >
                  <DeleteIcon className={classes.icon} />
                </IconButton>
              )}
              {!create && !reportHasChanges && (
                <IconButton
                  className={classes.iconBtn}
                  onClick={(e) => setExportMenuAnchorEl(e.currentTarget)}
                >
                  <SvgIcon
                    viewBox="0 0 16 16"
                    className={clsx(classes.icon, "-small", "ml-3 mr-2")}
                    component={ExportIcon}
                  />
                </IconButton>
              )}
              {reportHasChanges && !disableEdit && (
                <>
                  <Button
                    variant="text"
                    className="mr-2"
                    classes={{label: classes.cancelBtn}}
                    size="small"
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>
                  <PrimaryButton
                    label="Save Report"
                    size="small"
                    disabled={saveDisabled}
                    onClick={handleSaveReport}
                  />
                </>
              )}
            </>
          )
        }
        className={classes.header}
        classes={{action: classes.actions}}
      />
      <div className={classes.content}>
        {reportChart}
        {reportTable}
      </div>
    </div>
  );
}
