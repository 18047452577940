import * as API from "../api/reportsAPI";
import * as types from "../actionTypes";
import * as crmAPI from "../api/crmAPI";
import qs from "qs";
import {getEncodedFilters} from "utilities/helperFunctions";
import {
  setApiError,
  setApiStart,
  setApiSuccess,
  setStartKeys,
} from "./settingsActions";

export function setLoadingHits(loading) {
  return {type: types.SET_REPORTS_LOADING_HITS, loading};
}
export function setReportList(reportList) {
  return {type: types.SET_REPORT_LIST, reportList};
}
export function setDashboardReport(report) {
  return {type: types.SET_DASHBOARD_REPORT, report};
}
export function addReport(isNew, reportId, report) {
  return {type: types.ADD_REPORT, isNew, reportId, report};
}
export function addReportHits(reportId, hits, startKey) {
  return {type: types.ADD_REPORT_HITS, reportId, hits, startKey};
}
export function setDeleteReport(reportId) {
  return {type: types.DELETE_REPORT, reportId: reportId};
}
export function addExportReport(reportId, contentType, data) {
  return {type: types.ADD_EXPORT_REPORT, reportId, contentType, data};
}
export function selectReport(reportId) {
  return {type: types.SET_SELECTED_REPORT, reportId};
}

export function getAllReports(start, query) {
  return async (dispatch, getState) => {
    const current_user = getState().defaultReducer.current_user;
    let params = `enso_key=${current_user}`;
    if (!!start) {
      params += `&start=${start}`;
    }
    if (!!query) {
      params += `&query=${query}`;
    }
    dispatch(setApiStart("reports"));
    try {
      const reports = await API.getReports(params);
      console.log(`GOT REPORTS`, reports);
      dispatch(setReportList(reports));
      dispatch(setApiSuccess("reports"));
      dispatch(setStartKeys("reports", !reports.length));
    } catch (error) {
      console.log("ERROR - GET REPORTS", error);
      dispatch(setApiError("reports"));
    }
  };
}

export function deleteReport(report_id) {
  return async (dispatch, getState) => {
    const current_user = getState().defaultReducer.current_user;
    let params = `enso_key=${current_user}&report_id=${report_id}`;
    dispatch(setApiStart("reports"));
    try {
      const response = await API.deleteReport(params);
      console.log(`GOT REPORTS`, response);
      dispatch(setDeleteReport(report_id));
      dispatch(setApiSuccess("reports"));
    } catch (error) {
      console.log("ERROR - GET REPORTS", error);
      dispatch(setApiError("reports"));
    }
  };
}

export function getReports(
  reportId,
  interval,
  start,
  startHits,
  filters = [],
  query,
) {
  return async (dispatch, getState) => {
    const current_user = getState().defaultReducer.current_user;
    let params = `enso_key=${current_user}&timezone=${Intl.DateTimeFormat().resolvedOptions().timeZone}`;
    if (!!reportId) {
      params += `&report_id=${reportId}`;
    }
    if (!!interval) {
      params += `&interval=${interval}`;
    }
    if (!!start) {
      params += `&start=${start}`;
    }
    if (!!filters.length) {
      params += `&filters=${getEncodedFilters(filters)}`;
    }
    if (!!query) {
      params += `&query=${query}`;
    }
    if (!!startHits) {
      params += `&start_hits=${startHits}`;
      dispatch(setLoadingHits(true));
    }
    dispatch(setApiStart("reports"));
    try {
      const response = await API.getReport(params);
      console.log(`GOT REPORT(S)`, response);
      dispatch(
        !!reportId
          ? !!startHits
            ? addReportHits(reportId, response.hits, response.start_key)
            : addReport(!reportId, reportId, response)
          : setDashboardReport(response),
      );
      dispatch(setApiSuccess("reports"));
      if (!!startHits) {
        dispatch(setLoadingHits(false));
      }
    } catch (error) {
      console.log("ERROR - GET REPORT", error);
      dispatch(setApiError("reports"));
      if (!!startHits) {
        dispatch(setLoadingHits(false));
      }
    }
  };
}

export function getReportPreview(
  metric,
  start,
  view,
  interval,
  filters,
  query,
  onSuccess,
  onError,
  startHits,
  crmView,
) {
  return async (dispatch, getState) => {
    const current_user = getState().defaultReducer.current_user;
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const LIMIT = 50;

    let params = {
      enso_key: current_user,
      interval: interval,
      start: start,
      view: view,
      metric: metric,
      filters: filters.map((f) => ({
        path: f.path,
        operator: f.operator,
        value: f.value,
      })),
      query: query,
      timezone: timezone,
    };
    if (!!startHits) {
      params.start_hits = startHits;
      dispatch(setLoadingHits(true));
    }

    if (crmView === "guests") {
      params.limit = LIMIT;
      params.page = (startHits || 0) / LIMIT + 1;
    }

    dispatch(setApiStart("reports"));
    try {
      let response = null;

      if (crmView === "guests") {
        response = await crmAPI.getCrmPreviewGuests(params);
        response.start_hits = params.page * LIMIT;
        response.metric = metric;
        response.view = view;
        response.start = start;
      } else {
        response = await API.getReportPreview(params);
      }
      console.log("GOT PREVIEW RESPONSE", response);
      onSuccess && onSuccess([response]);
      dispatch(setApiSuccess("reports"));
      if (!!startHits) {
        dispatch(setLoadingHits(false));
      }
    } catch (error) {
      console.log("ERROR - GET REPORT PREVIEW", error);
      onError && onError();
      dispatch(setApiError("reports", "This preview is not available"));
      if (!!startHits) {
        dispatch(setLoadingHits(false));
      }
    }
  };
}

export function getExportReport({
  reportId,
  contentType,
  metric,
  start,
  view,
  interval,
  filters,
  query,
  startHits,
  onSuccess,
  crmView,
}) {
  return async (dispatch, getState) => {
    const current_user = getState().defaultReducer.current_user;
    let params = null;
    if (!!reportId) {
      params = qs.stringify({
        report_id: reportId,
        content_type: contentType,
        enso_key: current_user,
        start,
      });
    } else {
      params = `content_type=${contentType}&metric=${metric}&view=${view}&interval=${interval}&enso_key=${current_user}`;
      if (!!start) {
        params += `&start=${start}`;
      }
      if (!!filters?.length) {
        params += `&filters=${getEncodedFilters(filters)}`;
      }
      if (!!query) {
        params += `&query=${query}`;
      }
      if (!!startHits) {
        params += `&start_hits=${startHits}`;
      }
    }

    dispatch(setApiStart("reports"));
    try {
      let response = null;
      if (crmView === "guests") {
        response = await crmAPI.getCrmExportGuests({
          query: query,
          filters: filters,
          content_type: contentType,
        });
      } else {
        response = await API.getReportExport(params);
      }
      console.log(`GOT EXPORT REPORT`, response);
      if (!!response?.data) {
        dispatch(addExportReport(reportId, contentType, response.data));
      }
      onSuccess && onSuccess(response);
      dispatch(setApiSuccess("reports"));
    } catch (error) {
      console.log("ERROR - GET EXPORT REPORT", error);
      dispatch(setApiError("reports"));
    }
  };
}

export function postReport(reportId, title, metrics, onSuccess, onError) {
  return async (dispatch, getState) => {
    const current_user = getState().defaultReducer.current_user;
    const body = {
      enso_key: current_user,
      title,
      metrics,
    };
    if (!!reportId) {
      body.report_id = reportId;
    }
    dispatch(setApiStart("reports"));
    try {
      const response = await API.postReport({body});
      console.log(`GOT NEW REPORT`, response);
      if (!!response.error) {
        dispatch(setApiError("reports", response.error));
        onError && onError();
      } else {
        dispatch(addReport(!reportId, response.report_id, response));
        onSuccess && onSuccess(response);
        dispatch(setApiSuccess("reports"));
      }
    } catch (error) {
      console.log("ERROR - POST REPORT", error);
      dispatch(setApiError("reports"));
      onError && onError();
    }
  };
}
