import React from "react";
import {useSelector, useDispatch} from "react-redux";
import NumberFormat from "react-number-format";
// UI
import {
  MenuItem,
  MenuList,
  Link,
  Chip,
  Box,
  Button,
  SvgIcon,
} from "@material-ui/core";
import SendIcon from "@material-ui/icons/Send";
import {ReactComponent as DownloadIcon} from "assets/icons/download.svg";
import {makeStyles, useTheme} from "@material-ui/core/styles";
// Custom
import MarketingExperiencePanel from "components/Panels/Experience/MarketingExperiencePanel";
import GJEInfoCard from "components/Cards/CRMInfoCard";
import BookingInfoDrawer from "components/Panels/Booking/BookingInfoDrawer";
import CustomCardHeader from "core/cards/CustomCardHeader";
import CampaignTable from "components/Tables/CampaignTable";
import ReportTable from "components/Tables/ReportTable";
import PrimaryButton from "core/buttons/PrimaryButton";
import ExpandButton from "core/buttons/ExpandButton";
import CustomMenu from "core/menus/CustomMenu";
import Panel2 from "core/panels/Panel2";
import Panel3 from "core/panels/Panel3";
// Actions
import {
  closeGeneralSuccessAlert,
  openGeneralSuccessAlert,
} from "redux/actions/settingsActions";
import {getExportReport, getReportPreview} from "redux/actions/reportsActions";
import {getExperiences} from "redux/actions/experiencesActions";
// Utilities
import {reportExportTypes, segmentsCRM} from "configuration/constants";
import {getEncodedFilters, newUuid} from "utilities/helperFunctions";
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    height: "100%",
    display: "flex",
    flexDirection: "row",
    maxWidth: "95vw",
    overflow: "hidden",
  },
  panel2Container: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
  },
  header: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    marginBottom: -10,
  },
  menuIcon: {minWidth: 40},
  instantExperiencesSection: {
    width: "100%",
    position: "relative",
  },
  cardsRow: {
    display: "inline-flex",
    paddingLeft: theme.spacing(4),
  },
  subtitle: {
    fontWeight: 600,
    fontSize: 12,
    lineHeight: "14.52px",
    letterSpacing: "0.05em",
    color: "#425460",
    textTransform: "uppercase",
    marginLeft: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  smIcon: {
    fontSize: 20,
    marginLeft: 2,
    marginBottom: 2,
  },
  rightListItemIcon: {minWidth: "auto"},
  arrowIcon: {
    color: theme.palette.grey[300],
    fontSize: 14,
  },
  link: {cursor: "pointer"},
  titleContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: theme.spacing(2),
  },
}));

export default function CRM() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const apiCallId = React.useRef(null);
  const user_profile = useSelector(
    (state) => state.defaultReducer.user_profile,
  );
  const current_user = useSelector(
    (state) => state.defaultReducer.current_user,
  );
  const isTabletView =
    useSelector((state) => state.defaultReducer.deviceType) === "tablet";
  const [view, setView] = React.useState("guests");
  const [exportMenuAnchorEl, setExportMenuAnchorEl] = React.useState(null);
  const [detailsPanel, setDetailsPanel] = React.useState(null);
  const [selectedGuest, setSelectedGuest] = React.useState(null);
  const [connectedGuests, setConnectedGuests] = React.useState([]);
  const [selectedSegment, setSelectedSegment] = React.useState(null);
  const [totalCampaigns, setTotalCampaigns] = React.useState(null);
  const [metric, setMetric] = React.useState("guests");
  const [filters, setFilters] = React.useState([]);
  const [searchText, setSearchText] = React.useState("");
  const [previewData, setPreviewData] = React.useState([]);
  const [campaigns, setCampaigns] = React.useState([]);
  const [selectedCampaign, setSelectedCampaign] = React.useState(null);

  React.useEffect(() => {
    if (!current_user) {
      return;
    }
    getCampaigns();
  }, [current_user]);

  React.useEffect(() => {
    if (!current_user) {
      return;
    }
    loadData();
  }, [current_user, metric, filters, searchText]);

  React.useEffect(() => {
    if (!selectedSegment) {
      return;
    }
    let missingFilters = !_.isEqual(
      segmentsCRM[selectedSegment.segment_id].filters,
      filters,
    );
    if (missingFilters) {
      setSelectedSegment((prev) => null);
    }
  }, [filters, selectedSegment]);

  const getCampaigns = () => {
    dispatch(
      getExperiences({
        type: "cmp",
        onSuccess: (response) => {
          const newCampaigns = response?.experience ?? [];
          setCampaigns((prev) => newCampaigns);
          setTotalCampaigns((prev) => newCampaigns.length);
        },
      }),
    );
  };

  const closeExportMenu = () => setExportMenuAnchorEl((prev) => null);
  const handleReportPreviewError = () => setPreviewData((prev) => []);

  const addCampaign = (campaign) => {
    setCampaigns((prev) => [...prev, campaign]);
    setTotalCampaigns((prev) => prev.campaigns + 1);
  };

  const handleReportPreviewSuccess = (response, add, responseId) => {
    if (apiCallId.current !== responseId) {
      return;
    }
    if (add) {
      setPreviewData((prev) => {
        const newData = {
          ...response[0],
          hits: [...prev[0].hits, ...response[0].hits],
        };
        return [newData];
      });
    } else {
      setPreviewData((prev) => response);
    }
  };

  const loadData = () => {
    let newApiCallId = newUuid();
    apiCallId.current = newApiCallId;
    let useBookingMetric = false;
    if (useBookingMetric && metric !== "bookings") {
      setMetric((prev) => "bookings");
    }
    dispatch(
      getReportPreview(
        useBookingMetric ? "bookings" : metric,
        0,
        "line_chart",
        "all",
        getEncodedFilters(filters, false),
        searchText,
        (response) => handleReportPreviewSuccess(response, false, newApiCallId),
        handleReportPreviewError,
        null,
        "guests",
      ),
    );
  };

  const loadNextHits = () => {
    let newApiCallId = newUuid();
    apiCallId.current = newApiCallId;
    let useBookingMetric = false;
    if (useBookingMetric && metric !== "bookings") {
      setMetric((prev) => "bookings");
    }
    dispatch(
      getReportPreview(
        useBookingMetric ? "bookings" : metric,
        0,
        "line_chart",
        "all",
        getEncodedFilters(filters, false),
        searchText,
        (response) => handleReportPreviewSuccess(response, true),
        handleReportPreviewError,
        previewData[0].hits.length,
        "guests",
      ),
    );
  };

  const handleDetailsPanelClose = () => {
    setDetailsPanel((prev) => null);
    setSelectedSegment((prev) => null);
  };

  const onGuestSelect = (guest) => {
    setDetailsPanel((prev) => "guest_details");
    setSelectedGuest((prev) => guest);
  };

  const openCampaign = (campaign) => {
    setDetailsPanel((prev) => "inst");
    setSelectedCampaign((prev) => campaign);
  };

  const handleActionClick = (option, e) => {
    if (option === "export") {
      const el = e.currentTarget;
      setExportMenuAnchorEl((prev) => el);
    } else {
      if (option === "inst") {
        setSelectedCampaign((prev) => null);
      }
      setDetailsPanel((prev) => option);
    }
  };

  const handleConnectedIdsChange = (newIds) => {
    console.log("new IDs: ", newIds);
    setConnectedGuests((prev) => newIds);
  };

  function handleSuccessAlertClose() {
    dispatch(closeGeneralSuccessAlert());
  }

  function successResponseAlert() {
    dispatch(
      openGeneralSuccessAlert({
        message: "The exported data will be sent to your email shortly",
        open: true,
        onClose: () => handleSuccessAlertClose(),
      }),
    );
  }

  const exportReport = (exportType) => {
    closeExportMenu();
    dispatch(
      getExportReport({
        metric,
        filters: getEncodedFilters(filters, false),
        interval: "all",
        contentType: exportType,
        query: searchText,
        crmView: "guests",
        onSuccess: (r) => successResponseAlert(),
      }),
    );
  };

  const handleSearchTextChange = (newSearch) => {
    setSearchText((prev) => newSearch);
  };

  function getExportMenuContent() {
    return (
      <MenuList id="export-options-menu">
        {Object.keys(reportExportTypes).map((exportType) => (
          <MenuItem key={exportType} onClick={() => exportReport(exportType)}>
            {`Export as .${exportType}`}
          </MenuItem>
        ))}
      </MenuList>
    );
  }

  function getPanel3Content() {
    if (!detailsPanel) {
      return null;
    }
    switch (detailsPanel) {
      case "help":
        return (
          <div className={classes.instantExperiencesSection}>
            <CustomCardHeader
              title={"Explore your guest data"}
              className={classes.cardHeader}
              action={
                <>
                  <Button
                    classes={{label: classes.cancelBtn}}
                    size="small"
                    onClick={() => setDetailsPanel(null)}
                  >
                    Close
                  </Button>
                </>
              }
            />
            <div style={{padding: theme.spacing(4)}}>
              <GJEInfoCard type={`crm`} />
            </div>
          </div>
        );
      case "inst":
        return (
          <MarketingExperiencePanel
            hideContent
            hideTriggers
            hideConnection
            selectedCampaign={selectedCampaign}
            connectedIds={
              !selectedCampaign
                ? connectedGuests
                : (selectedCampaign?.connected_to?.ids ?? [])
            }
            getCampaigns={getCampaigns}
            addCampaign={addCampaign}
            closeDetails={handleDetailsPanelClose}
          />
        );
      case "guest_details":
        return (
          <BookingInfoDrawer
            guestId={selectedGuest.guest_id}
            onClose={handleDetailsPanelClose}
          />
        );

      default:
        return null;
    }
  }

  const exportButton = (
    <ExpandButton
      size="small"
      label="Export"
      variant="contained"
      startIcon={<SvgIcon component={DownloadIcon} viewBox="0 0 24 24" />}
      style={{height: "fit-content", padding: theme.spacing(1, 3)}}
      onClick={(e) => handleActionClick("export", e)}
    />
  );

  const panel2 = (
    <div className={classes.panel2Container}>
      <CustomMenu
        open={Boolean(exportMenuAnchorEl)}
        anchorEl={exportMenuAnchorEl}
        onClose={closeExportMenu}
        content={getExportMenuContent()}
      />
      <CustomCardHeader
        title={
          <Box className={classes.titleContainer}>
            <Link onClick={() => setView("guests")} className={classes.link}>
              {" CRM"}
            </Link>
            {" · "}
            {/* Feature Flag this */}
            <Link onClick={() => setView("campaigns")} className={classes.link}>
              <NumberFormat
                value={totalCampaigns}
                thousandSeparator
                displayType="text"
              />
              {" Campaigns"}
            </Link>
          </Box>
        }
        className={classes.header}
        action={
          <PrimaryButton
            size="small"
            icon={<SendIcon />}
            label="Create Marketing Campaign"
            variant="contained"
            onClick={() => handleActionClick("inst")}
          />
        }
      />
      {view === "guests" ? (
        <ReportTable
          crmView
          isPreview
          allInterval
          metric={metric}
          filters={filters}
          selectedReport="new"
          viewType="line_chart"
          searchText={searchText}
          previewData={previewData}
          exportButton={exportButton}
          hideCheckboxColumn={detailsPanel !== "inst"}
          setMetric={setMetric}
          setFilters={setFilters}
          loadNextPage={loadNextHits}
          openGuestPanel={onGuestSelect}
          setSearchText={handleSearchTextChange}
          setSelectedIds={handleConnectedIdsChange}
          exportDataHandler={exportReport}
        />
      ) : (
        <Box mt={4} flex={1} display="flex" flexDirection="column">
          <CampaignTable
            campaigns={campaigns}
            onCampaignSelect={openCampaign}
          />
        </Box>
      )}
    </div>
  );

  if (
    !Object.keys(user_profile).length ||
    user_profile.scopes?.crm === "hide"
  ) {
    return null;
  }

  return (
    <div className={classes.root}>
      <Panel2
        lg
        flexAuto
        content={panel2}
        isPanel3Open={isTabletView ? false : !!detailsPanel}
        hidden={isTabletView && !!detailsPanel}
      />
      {(!isTabletView || !!detailsPanel) && (
        <Panel3
          sm
          rounded={isTabletView}
          content={getPanel3Content()}
          hidden={!detailsPanel}
          disableMaxWidth={isTabletView}
        />
      )}
    </div>
  );
}
