import React, {useEffect, useRef, useState} from "react";
import {useDispatch} from "react-redux";
// UI
import {
  Box,
  CircularProgress,
  ThemeProvider,
  Typography,
} from "@material-ui/core";
import usePreviewBPPanelStyles from "styles/usePreviewBPPanel";
// Custom
import PreviewContentWrapper from "components/keycard/PreviewContentWrapper";
import KeycardCollectDetails from "components/keycard/KeycardCollectDetails";
import KeycardAgreement from "components/keycard/KeycardAgreement";
import CustomIDScanner from "components/keycard/CustomIDScanner";
import GuidebookPage from "components/keycard/GuidebookPage";
import KeycardNavbar from "components/keycard/KeycardNavbar";
import CloseIconButton from "core/buttons/CloseIconButton";
import ProductPage from "components/keycard/ProductPage";
import CheckSteps from "components/keycard/CheckSteps";
import VerifyPage from "components/keycard/VerifyPage";
// Redux
import {getBPContentPreview} from "redux/actions/bpActions";
// Utils
import {newUuid} from "utilities/helperFunctions";
import getTheme from "configuration/keycardTheme";
import clsx from "clsx";

const routes = ["home", "upgrades", "guides"];
const listingResources = ["fees", "upgrades", "checkout", "checkin", "guides"];
const verifySections = ["details", "agreement", "id_upload"];
const autohost_img =
  "https://enso-image-assets.s3.amazonaws.com/autohost_preview_bp.png";

const getReplacedData = ({
  array,
  useIndex,
  originalItem,
  idKey,
  selectedIndex,
}) => {
  let newArray = array.slice();
  let newSelectedItem = useIndex ? -1 : null;
  let newIndex = -1;
  if (!!idKey) {
    if (!!newArray.length) {
      newIndex = newArray.findIndex(
        (item) => item[idKey] === originalItem[idKey],
      );
    } else {
      newIndex = 0;
    }
  } else {
    newIndex = selectedIndex;
  }

  if (useIndex) {
    newSelectedItem = newIndex;
  } else {
    newSelectedItem = newArray[newIndex][idKey];
  }
  return {newArray, newSelectedItem};
};

const PreviewBPPanel = ({type, content = {}, listingId, verifier}) => {
  const classes = usePreviewBPPanelStyles();
  const dispatch = useDispatch();
  const phoneFrameRef = useRef(null);
  const requestId = useRef(null);
  const originalItem = useRef(null);
  const [kc, setKc] = useState(null);
  const [components, setComponents] = useState(null);
  const [loading, setLoading] = useState(true);
  const [iphoneSize, setIphoneSize] = useState({height: 0, width: 0});
  const [showGrid, setShowGrid] = useState(false);
  const [selectedScreen, setSelectedScreen] = useState(type);
  const [selectedRoute, setSelectedRoute] = useState(
    routes.includes(type) ? type : "home",
  );
  const [BPTheme, setBPTheme] = useState(
    getTheme({color1: "#000000", color2: "#000000"}),
  );
  // Selected items
  const [guidebookId, setGuidebookId] = useState(null);
  const [stepIndex, setStepIndex] = useState(-1);
  const [productId, setProductId] = useState(null);
  // General
  let guides = components?.guidebooks ?? [];

  useEffect(() => {
    const element = phoneFrameRef.current;
    if (!element) {
      return;
    }
    const calculateRatio = () => {
      const dynamicHeight = element.offsetHeight;
      const newWidth = dynamicHeight / 2;
      setIphoneSize({height: dynamicHeight, width: newWidth});
    };
    // Calculate iphoneWidth on initial load
    calculateRatio();
    // Recalculate iphoneWidth on window resize
    window.addEventListener("resize", calculateRatio);
    return () => {
      window.removeEventListener("resize", calculateRatio);
    };
  }, []);

  useEffect(() => {
    setSelectedScreen(type);
  }, [type]);

  useEffect(() => {
    let timer = null;
    let currRequestId = null;
    if (!listingId || !Object.keys(content)) {
      return;
    }
    timer = setTimeout(() => {
      currRequestId = newUuid();
      requestId.current = currRequestId;
      let fakeId = newUuid();
      let params = {
        listing_id: listingId,
        listing_resource: !listingResources.includes(type)
          ? undefined
          : !!content.id
            ? content
            : {...content, id: fakeId, resource_id: fakeId, enso_key: fakeId},
        options: listingResources.includes(type) ? null : content,
      };
      setLoading((prev) => true);
      dispatch(
        getBPContentPreview(
          params,
          (response) => {
            try {
              if (currRequestId === requestId.current) {
                const newComponents = {...response.components};
                let newArray, newSelectedItem;
                switch (type) {
                  case "guides":
                    // If initial load || it's a new guidebook
                    if (!originalItem.current || !content.id) {
                      originalItem.current =
                        response.components.guidebooks.find(
                          (res) => res.id === (content.resource_id ?? fakeId),
                        );
                    }

                    if (!!originalItem.current) {
                      ({newArray, newSelectedItem} = getReplacedData({
                        array: response.components.guidebooks,
                        originalItem: originalItem.current,
                        idKey: "id",
                      }));
                      newComponents.guidebooks = newArray;
                      if (guidebookId !== newSelectedItem) {
                        setGuidebookId((prev) => newSelectedItem);
                      }
                    }
                    break;
                  case "fees":
                  case "upgrades":
                    let property = type === "upgrades" ? "upsells" : type;

                    // If initial load || it's a new fee/upsell
                    if (!originalItem.current || !content.id) {
                      originalItem.current = response.components[property].find(
                        (res) =>
                          res.product_id ===
                          (content.properties.product_id ?? fakeId),
                      );
                    }

                    ({newArray, newSelectedItem} = getReplacedData({
                      array: response.components[property],
                      originalItem: originalItem.current,
                      idKey: "product_id",
                    }));
                    newComponents[property] = newArray;
                    if (productId !== newSelectedItem) {
                      setProductId((prev) => newSelectedItem);
                    }
                    break;
                  case "checkin":
                  case "checkout":
                    if (!originalItem.current) {
                      originalItem.current = response.components[type]?.at(-1);
                    }
                    ({newArray, newSelectedItem} = getReplacedData({
                      array: response.components[type],
                      useIndex: true,
                      originalItem: originalItem.current,
                      selectedIndex: content.index,
                    }));
                    newComponents[type] = newArray;
                    if (stepIndex === -1) {
                      setStepIndex((prev) => content.index);
                    }
                    break;

                  default:
                    break;
                }
                setComponents((prev) => newComponents);
                setKc((prev) => ({...response, components: newComponents}));
                setBPTheme(getTheme(response.components.metadata));
                setLoading((prev) => false);
              }
            } catch (error) {
              console.log("ERROR HANDLING BP PREVIEW RESPONSE", error);
            }
          },
          () => {
            if (currRequestId === requestId.current) {
              setLoading((prev) => false);
            }
          },
        ),
      );
    }, 500);

    return () => clearTimeout(timer);
  }, [listingId, content]);

  const handleSelectNavbarItem = (newRoute) => {
    setSelectedRoute(newRoute);
    setSelectedScreen(newRoute);
  };

  const handleComponentsChange = (newComponents) => {
    if (!kc) {
      return;
    }
    setKc({...kc, components: newComponents});
    setComponents((prev) => newComponents);
  };

  const handleItemClick = (item) => {
    switch (selectedScreen) {
      case "guides":
        setGuidebookId((prev) => item);
        setShowGrid((prev) => false);
        break;
      case "fees":
      case "upgrades":
        setProductId((prev) => item);
        setShowGrid((prev) => false);
        break;
      case "details":
      case "agreement":
      case "id_upload":
        setShowGrid((prev) => false);
        setSelectedScreen((prev) => item);
        break;

      default:
        break;
    }
  };

  function getPreviewComponent() {
    switch (selectedScreen) {
      case "guides":
        return (
          <GuidebookPage
            guidebookId={!guidebookId ? guides.length - 1 : guidebookId}
            components={components}
            closePage={() => setShowGrid((prev) => true)}
          />
        );
      case "fees":
      case "upgrades":
        if (!productId) {
          return null;
        }
        return (
          <ProductPage
            product_id={productId}
            components={components}
            disableClose={selectedScreen === "fees"}
            closePage={() => setShowGrid((prev) => true)}
          />
        );
      case "checkin":
      case "checkout":
        return (
          <CheckSteps
            kc={kc}
            components={components}
            selectedIndex={stepIndex}
            checkin={selectedScreen === "checkin"}
          />
        );
      case "details":
        return (
          <KeycardCollectDetails
            closePage={() => setShowGrid(true)}
            components={components}
            setDetails={(d) =>
              handleComponentsChange({...components, details: d})
            }
            submitDetails={() => {}}
          />
        );
      case "agreement":
        return (
          <KeycardAgreement
            closePage={() => setShowGrid(true)}
            components={components}
          />
        );
      case "id_upload":
        if (!verifier || verifier === "unset") {
          return (
            <Box className={classes.emptyIDCollectionBox}>
              <Box className={classes.closeIconEmpty}>
                <CloseIconButton lg black onClick={() => setShowGrid(true)} />
              </Box>
              <Typography variant="h3" align="center">
                {"No ID is being collected"}
              </Typography>
              <Typography variant="h5" align="center">
                {"Please select a verification method"}
              </Typography>
            </Box>
          );
        } else if (verifier === "enso" || verifier.includes("superhog")) {
          return (
            <CustomIDScanner
              closePage={() => setShowGrid(true)}
              components={components}
            />
          );
        } else if (verifier.includes("autohost")) {
          return (
            <Box className={classes.autohostContainer}>
              <img
                src={autohost_img}
                alt="autohost preview"
                className={classes.autohostImg}
              />
            </Box>
          );
        } else {
          return null;
        }

      default:
        return null;
    }
  }

  return (
    <div className={classes.previewContainer}>
      <div
        className={clsx(classes.frame, classes.dropShadow)}
        style={{width: iphoneSize.width}}
      />
      <div
        ref={phoneFrameRef}
        className={classes.frame}
        style={{width: iphoneSize.width}}
      >
        {loading ? (
          <Box className={classes.loaderContainer}>
            <CircularProgress className={classes.loader} />
            <Box mt={3} zIndex={1}>
              <Typography variant="h1" className={classes.loader}>
                {"Loading preview"}
              </Typography>
            </Box>
          </Box>
        ) : (
          <Box
            className={classes.contentContainer}
            style={{backgroundColor: BPTheme.palette.primary.contrast}}
          >
            <ThemeProvider theme={BPTheme}>
              {showGrid ? (
                verifySections.includes(selectedScreen) ? (
                  <VerifyPage
                    components={components}
                    onItemClick={handleItemClick}
                  />
                ) : (
                  <PreviewContentWrapper
                    type={selectedScreen}
                    components={components}
                    onItemClick={handleItemClick}
                  />
                )
              ) : (
                getPreviewComponent()
              )}
              {showGrid && !verifySections.includes(selectedScreen) && (
                <KeycardNavbar
                  selected={selectedRoute}
                  components={components}
                  onClick={handleSelectNavbarItem}
                />
              )}
            </ThemeProvider>
          </Box>
        )}
      </div>
    </div>
  );
};

export default PreviewBPPanel;
