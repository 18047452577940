import {lighten, makeStyles} from "@material-ui/core";
import {FIXED_SIZES, THEME} from "configuration/settings.js";
import {isSafari} from "react-device-detect";

const useMessageStyles = makeStyles((theme) => ({
  partialMessage: {
    maxHeight: 330,
    minWidth: 70,
  },
  fullMessage: {maxHeight: "fit-content"},
  previewEmailContainer: {
    maxHeight: "70vh",
    maxWidth: "70vw",
    overflow: "auto",
  },
  iframe: {
    border: "none",
    width: "100%",
    height: "100%",
    display: "block",
    overflow: "auto",
    "&:.-hide": {opacity: 0},
  },
  card: {
    display: "flex",
    alignItems: "center",
    margin: theme.spacing(2, 4),
    padding: theme.spacing(4),
    border: `1px solid ${lighten(theme.palette.primary.main, 0.8)}`,
  },
  saveBtn: {
    padding: theme.spacing(2, 4),
    borderRadius: theme.shape.borderRadius * 6,
    marginBottom: theme.spacing(2),
  },
  btnsSection: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 2,
  },
  contextTxtWrapper: {
    width: "20rem",
    flexGrow: 3,
    margin: theme.spacing(0, 5),
  },
  contextTxt: {
    fontSize: 14,
  },
  jarvisIcon: {
    height: 32,
    width: 32,
  },
  sentLabel: {
    display: "flex",
    justifyContent: (props) =>
      props.who === "them" ? "flex-start" : "flex-end",
    color: THEME.subdued,
    marginTop: -theme.spacing(3),
    marginBottom: theme.spacing(4),
    marginRight: (props) => (props.who === "them" ? 0 : theme.spacing(3)),
    marginLeft: (props) => (props.who === "them" ? theme.spacing(3) : 0),
  },
  messageWrapper: {
    position: "relative",
    overflow: "hidden",
    width: "100%",
    margin: theme.spacing(4, 0),
    padding: theme.spacing(0, 3),
  },
  automessageWrapper: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: (props) =>
      props.who === "them" ? "flex-start" : "flex-end",
  },
  textWrapper: {
    maxWidth: "70%",
    overflowWrap: "anywhere",
    padding: theme.spacing(3, 4),
    minHeight: 40,
    borderRadius: theme.shape.borderRadius * 4,
    position: "relative",
    zIndex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    wordBreak: "break-word",
    "& p": {marginBottom: 0},
  },
  attachments: {
    marginTop: theme.spacing(4),
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: theme.spacing(2),
    flexWrap: "wrap",
    "& .attachment-img": {
      width: 80,
      height: 80,
      display: "inline-block",
    },
    "& .attachment-file": {fontSize: 64},
  },
  automessageText: {
    marginRight: (props) => (props.who === "them" ? 0 : -theme.spacing(4)),
    marginLeft: (props) => (props.who === "them" ? -theme.spacing(4) : 0),
    width: "100%",
  },
  chatbotRate: {
    position: "absolute",
    top: 0,
    left: 0,
    marginLeft: -100,
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    justifyContent: "flex-end",
    "& > .rate-label": {color: theme.palette.grey[400]},
  },
  thumbIconBtn: {
    "& .icon": {
      fontSize: 16,
      transition: "0.15s",
    },
    "&:hover .icon": {color: theme.palette.secondary.main},
  },
  bubblePeak: {
    position: "absolute",
    bottom: 0,
    width: 0,
    height: (props) => (!!props.isSafari ? 30 : 0),
    border: "20px solid transparent",
    borderBottom: 0,
    marginTop: -13,
  },
  channelSection: {
    zIndex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  channel: {
    width: FIXED_SIZES.message_channel.width,
    height: FIXED_SIZES.message_channel.height,
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    bottom: 0,
    zIndex: 1,
  },
  sectionChannel: {
    width: 18,
    height: 18,
    borderRadius: "50%",
    padding: 3,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 3,
    cursor: "pointer",
    opacity: 0.3,
    transition: "opacity .15s, width .15s, height .15s",
    "&:hover": {
      opacity: 0.8,
      width: 24,
      height: 24,
    },
  },
  selected: {
    opacity: "1!important",
    width: 24,
    height: 24,
  },
  SMS: {backgroundColor: THEME.sms},
  whatsapp: {backgroundColor: THEME.whatsapp},
  airbnb: {backgroundColor: THEME.airbnb},
  email: {backgroundColor: THEME.grey},
  channelIcon: {
    width: "100%",
    height: "100%",
    objectFit: "fill",
    color: "white",
    maxWidth: 16,
    maxHeight: 16,
    transition: "width .15s, height .15s",
  },
  // Me
  meChannels: {
    right: theme.spacing(3),
  },
  meText: {
    float: "right",
    backgroundColor: THEME.messageMe,
    marginRight: theme.spacing(5),
    color: "#425360",
  },
  meBubblePeak: {
    right: 39,
    borderRight: 0,
    borderLeftColor: THEME.messageMe,
    backgroundColor: THEME.messageMe,
    borderBottomLeftRadius: "12px 16px",
    borderWidth: 16,
  },
  meBubblePeakAfter: {
    right: isSafari ? 39 : 38,
    borderRight: 0,
    backgroundColor: theme.palette.grey[50],
    transform: "translate(14px, 1px)",
    borderBottomLeftRadius: 8,
    borderWidth: 18,
  },
  // Them
  linkColor: {"& a": {color: THEME.emailDarkAccent}},
  themChannels: {
    left: theme.spacing(3),
  },
  themText: {
    float: "left",
    backgroundColor: THEME.grey,
    color: theme.palette.common.white,
    marginLeft: theme.spacing(5),
  },
  themBubblePeak: {
    left: 39,
    borderLeft: 0,
    borderRightColor: THEME.grey,
    backgroundColor: THEME.grey,
    borderBottomRightRadius: "16px 12px",
    borderWidth: 16,
  },
  themBubblePeakAfter: {
    left: isSafari ? 39 : 38,
    borderLeft: 0,
    backgroundColor: theme.palette.grey[50],
    transform: "translate(-14px, 1px)",
    borderBottomRightRadius: 8,
    borderWidth: 18,
  },
  timeDivider: {
    display: "flex",
    justifyContent: "center",
    color: theme.palette.action.disabled,
  },
  notificationMessage: {
    fontSize: 12,
    fontWeight: "400",
    lineHeight: "17px",
    color: "#425360",
    "& .-bold": {
      fontWeight: "700",
      marginRight: theme.spacing(1),
    },
  },
  notificationTime: {color: THEME.subdued},
  notificationContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    padding: (props) => (!!props.noPadding ? 0 : theme.spacing(3)),
    gap: theme.spacing(2),
    "&.column": {
      flexWrap: "wrap",
      display: "flex",
      flexDirection: "column",
      marginBottom: theme.spacing(2),
    },
  },
  notifText: {
    color: (props) => (!!props.hasErrors ? THEME.error : THEME.subdued),
    textAlign: (props) => (!!props.group ? "left" : "center"),
    "&.flex": {display: "flex"},
    "& .type": {
      "& .error": {
        color: theme.palette.error.main,
        display: "contents",
      },
      "& > .bullet": {margin: theme.spacing(0, 1)},
    },
  },
  bell: {
    backgroundColor: "#FFF",
    color: (props) => (!!props.hasErrors ? THEME.error : THEME.text),
    borderRadius: "50%",
    padding: 6,
    alignSelf: "flex-start",
    "&.standalone": {
      marginRight: theme.spacing(2),
      marginBottom: theme.spacing(1),
    },
    "&.group": {
      alignSelf: "center",
      marginRight: theme.spacing(2),
    },
  },
  viewMoreBtn: {
    padding: 0,
    minWidth: "fit-content",
    alignSelf: "flex-start",
    height: 24,
  },
  expandedNotifView: {
    flexBasis: "100%",
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(3),
  },
  link: {
    textDecoration: "none !important",
    color: theme.palette.primary.main,
  },
  addToKnowledgeBaseLink: {
    marginRight: theme.spacing(1),
    cursor: "pointer",
    textDecoration: "none !important",
    "&.disabled": {
      opacity: 0.5,
      cursor: "not-allowed",
    },
  },
}));

export default useMessageStyles;
