import {useEffect, useRef} from "react";
import {
  reportExportTypes,
  reportFileContentType,
} from "configuration/constants";
import {maxCompressionImageSize} from "configuration/constants.js";
import ImageCompressor from "js-image-compressor";
import {asyncLoop} from "./helperFunctions";
import {saveAs} from "file-saver";

function getBlob(obj, type) {
  let str = obj;
  if (type === reportFileContentType.json) {
    str = JSON.stringify(obj);
  }
  const bytes = new TextEncoder().encode(str);
  const blob = new Blob([bytes], {type});
  return blob;
}

export function saveAsFile(obj, file_name, file_type = reportExportTypes.json) {
  const fileContentType = reportFileContentType[file_type];
  const blob = getBlob(obj, fileContentType);
  saveAs(blob, file_name);
}

export const useImageCompressor = ({
  srcImgs = [],
  onSuccess = () => null,
  fileInObject = false,
}) => {
  const compressedImgs = useRef([]);

  useEffect(() => {
    if (!srcImgs.length) {
      return;
    }
    async function compressImages(images) {
      let compressionNeeded = false;
      const filesToCompress = images.map((f, i) => ({
        fileData: fileInObject ? {...f} : f,
        compressData: {
          file: fileInObject ? f.file : f,
          redressOrientation: false,
          quality: Number(
            (
              maxCompressionImageSize /
              (fileInObject ? f.file.size : f.size || maxCompressionImageSize)
            ).toFixed(2),
          ),
          success: (result) =>
            compressSuccess(f, result, i === filesToCompress.length - 1),
          error: (msg) => compressError(msg),
        },
      }));

      await asyncLoop(filesToCompress, async (cf) => {
        if (cf.compressData.quality >= 1) {
          compressedImgs.current.push(cf.fileData);
        } else {
          compressionNeeded = true;
          await new ImageCompressor(cf.compressData);
        }
      });
      if (!compressionNeeded) {
        onSuccess(compressedImgs.current);
      }
    }

    compressedImgs.current = [];
    compressImages(srcImgs);
  }, [srcImgs]);

  const compressSuccess = (f, result, isLastImg) => {
    ImageCompressor.file2DataUrl(result, (url) => {
      compressedImgs.current.push(
        fileInObject ? {...f, file: result, picture: url} : result,
      );
      if (isLastImg) {
        onSuccess(compressedImgs.current);
      }
    });
  };

  const compressError = (msg) => {
    console.log("Failed to compress: ", msg);
  };

  return compressedImgs.current;
};
