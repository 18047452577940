import React from "react";
// UI
import NumberFormat from "react-number-format";
import {Card, CardContent, CardHeader} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import useDashboardStyles from "styles/useDashboardStyles";
// Custom
import LineChart from "components/Charts/LineChart";
// Helpers & Utilities
import {
  differenceInHours,
  subDays,
  subMonths,
  subWeeks,
  subYears,
} from "date-fns";
import {getLabelsFromDateRange, setStartOfDay} from "utilities/helperFunctions";
import {metricLabels} from "configuration/constants.js";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  content: {padding: "0px 10px 6px !important"},
}));

export default function LineChartCard({data, interval, start, redirect}) {
  const classes = useStyles();
  const dashboardStyles = useDashboardStyles();
  const title = data.title || metricLabels[data.metric];
  const initialDate = new Date();

  const goToReport = () => redirect(data.report_id);

  function getTimeCount() {
    let count = 0;
    const now = new Date();
    switch (interval) {
      case "day":
        count = !!start
          ? 24
          : differenceInHours(now, setStartOfDay(now), {
              roundingMethod: "ceil",
            });
        break;
      case "week":
        count = 168;
        break;
      case "month":
        count = data.buckets[0]?.data?.length;
        break;
      case "year":
        count = 52;
        break;
    }
    return count;
  }

  function getChartData() {
    const timeCount = getTimeCount();
    const chartData = [];
    const lineChartLabels = getLabelsFromDateRange(
      timeCount,
      interval,
      initialDate,
      true,
      start,
    );
    for (let index = 0; index < data.buckets.length; index++) {
      const bucket = data.buckets[index];
      chartData.push({bucket, values: bucket.data});
    }
    return {
      labels: lineChartLabels,
      data: chartData,
      title: metricLabels[data.metric],
    };
  }

  const lineChart = React.useMemo(
    () => <LineChart data={getChartData()} />,
    [data],
  );

  return (
    <Card className={dashboardStyles.card} onClick={goToReport}>
      <CardHeader
        className={dashboardStyles.cardHeader}
        classes={{action: dashboardStyles.cardAction}}
        title={title}
        titleTypographyProps={{className: dashboardStyles.cardTitle}}
        action={
          <div className={dashboardStyles.cardActionTotals}>
            <NumberFormat
              value={data.total || 0}
              thousandSeparator
              displayType="text"
              suffix={data.metric === "time_saved" ? "hrs" : ""}
              className={dashboardStyles.number}
            />
            <NumberFormat
              value={data.percent_change || 0}
              displayType="text"
              decimalScale={2}
              prefix={data.percent_change > 0 ? "+" : ""}
              suffix="%"
              className={clsx(dashboardStyles.percentage, {
                [dashboardStyles.negativePercentage]: data.percent_change < 0,
              })}
            />
          </div>
        }
      />
      <CardContent
        className={clsx(classes.content, dashboardStyles.cardContent)}
      >
        {lineChart}
      </CardContent>
    </Card>
  );
}
