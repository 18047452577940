import {darken, makeStyles} from "@material-ui/core";

const useReportTableStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    minHeight: 150,
    display: "flex",
    flexDirection: "column",
  },
  row: {
    display: "flex",
    flexDirection: "row",
  },
  gap8: {gap: theme.spacing(2)},
  column: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    height: "100%",
    width: "100%",
    overflow: "hidden",
    "& > p": {
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      WebkitBoxOrient: "vertical",
      WebkitLineClamp: 2,
      width: "100%",
      wordBreak: "break-word",
    },
  },
  tableSection: {
    flexGrow: 1,
    maxWidth: "calc(100vw - 100px)", // 100px = 80px navbar width + 20px right padding
    overflowY: "hidden",
    overflowX: "auto",
  },
  filters: {
    flexGrow: 1,
    display: "inline-block",
    "& > .searchContainer": {paddingBottom: 0},
    "& > button, > div": {
      marginRight: 6,
      marginBottom: 8,
      display: "inline-flex",
      minHeight: 32,
      marginTop: 0,
    },
    "&.crm": {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      "& > .searchContainer": {
        padding: 0,
        margin: 0,
      },
    },
  },
  crmButtonsContainer: {
    flex: 1,
    margin: 0 + "!important",
    minHeight: "fit-content !important",
    display: "flex !important",
    flexDirection: "row",
    justifyContent: "space-between",
    gap: theme.spacing(2),
  },
  options: {
    display: "flex",
    flexDirection: "row",
    padding: theme.spacing(0, 4),
    paddingBottom: theme.spacing(2),
    "&.crm": {padding: theme.spacing(3, 4)},
  },
  graphics: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "flex-end",
    "& .chart-icon": {transition: "0.15s"},
    "& > .icon-button": {
      marginLeft: theme.spacing(3),
      "&:hover .chart-icon": {color: theme.palette.secondary.main},
    },
  },
  selected: {fontWeight: 700},
  divider: {backgroundColor: "#C7D7DD"},
  center: {
    height: "100%",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  chip: {
    color: theme.palette.primary.main,
    backgroundColor: "rgba(13, 86, 140, 0.1)",
    fontWeight: theme.typography.fontWeightBold,
    margin: theme.spacing(1),
    paddingTop: 3,
    paddingBottom: theme.spacing(1),
    height: "auto",
    maxWidth: "-webkit-fill-available",
  },
  chipLabel: {
    display: "flex",
    alignItems: "center",
  },
  chipDeleteIcon: {
    width: 18,
    height: 18,
    color: theme.palette.primary.main,
    "&:hover": {color: darken(theme.palette.primary.main, 0.4)},
  },
  chipInput: {
    marginLeft: theme.spacing(1),
    "& > div": {
      "&:before": {border: "none!important"},
      "&:after": {border: "none!important"},
      "& > input": {
        color: theme.palette.primary.main,
        fontSize: theme.typography.body1.fontSize,
        padding: 0,
        height: "100%",
      },
    },
  },
  clearAllBtn: {
    float: "right",
    margin: "0 !important",
    textTransform: "none",
  },
  columnsMenu: {
    display: "flex",
    flexDirection: "column",
    overflowY: "auto",
    overflowX: "hidden",
    maxHeight: 215,
    padding: theme.spacing(3),
    "&::-webkit-scrollbar": {width: "0!important"},
  },
  columnsMenuItem: {
    padding: 0,
    borderRadius: 5,
    minHeight: 45,
  },
}));

export default useReportTableStyles;
