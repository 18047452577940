import React from "react";
import {Box} from "@material-ui/core";
import useThreePanelStyles from "styles/useThreePanelStyles";
import BackButton from "core/buttons/BackButton";
import clsx from "clsx";

export default function Panel3({
  hidden,
  sm,
  lg,
  disableStandaloneStyle,
  rounded,
  goBack,
  maintainWidth,
  content,
  isMessagingPage,
  hasContentAbove,
  fullWidth,
  disableMaxWidth,
  bg = "#FFFFFF",
}) {
  const classes = useThreePanelStyles({sm, lg, disableStandaloneStyle, bg});

  return (
    <Box
      className={clsx(classes.basePanel, classes.panel3, {
        "-hidden": !!hidden,
        "-rounded": !!rounded,
        "-maintainWidth": !!maintainWidth,
        "-disableMaxWidth": !!disableMaxWidth,
        [classes.btrr0]: !!hasContentAbove,
        [classes.fullWidth]: !!fullWidth,
      })}
    >
      {!!goBack && (
        <Box
          paddingX={4}
          pt={3}
          zIndex={1}
          bgcolor="white"
          style={{borderTopRightRadius: 10}}
        >
          <BackButton
            header
            color="primary"
            goBack={goBack}
            label={isMessagingPage ? "Chat" : "Back"}
            icon={isMessagingPage ? "chat" : "back"}
          />
        </Box>
      )}
      {content}
    </Box>
  );
}
