import {combineReducers} from "redux";
import defaultReducer from "./defaultReducer";
import messagesReducer from "./messagesReducer";
import reportsReducer from "./reportsReducer";
import experiencesReducer from "./experiencesReducer";
import dashboardReducer from "./dashboardReducer";

export default combineReducers({
  defaultReducer,
  messagesReducer,
  reportsReducer,
  experiencesReducer,
  dashboardReducer,
});
