import React, {useState} from "react";
import {useSelector} from "react-redux";
// UI
import {makeStyles} from "@material-ui/core/styles";
// Custom
import Panel2 from "core/panels/Panel2";
import Panel3 from "core/panels/Panel3";
import ReportList from "components/Lists/ReportList.jsx";
import ReportDetailsPanel from "components/Panels/ReportDetailsPanel.jsx";

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    height: "100%",
    display: "flex",
    flexDirection: "row",
  },
}));

export default function Reports() {
  const classes = useStyles();
  const user_profile = useSelector(
    (state) => state.defaultReducer.user_profile,
  );
  const selectedReportId = useSelector(
    (state) => state.reportsReducer.selected_report_id,
  );
  const isTabletView =
    useSelector((state) => state.defaultReducer.deviceType) === "tablet";
  const readOnly = React.useMemo(
    () => user_profile.scopes?.reports !== "write",
    [user_profile],
  );
  const [selectedReport, setSelectedReport] = useState(
    isTabletView ? null : "new",
  );

  React.useEffect(() => {
    if (
      !!readOnly &&
      !selectedReportId &&
      !!selectedReport &&
      selectedReport === "new"
    ) {
      setSelectedReport((prev) => null);
    }
  }, [readOnly, selectedReportId, selectedReport]);

  const reports_list = (
    <ReportList
      selectedReport={selectedReport}
      disableEdit={readOnly}
      onReportSelect={(report) => setSelectedReport(report)}
    />
  );

  const report_details = !!selectedReport && (
    <ReportDetailsPanel
      selectedReport={selectedReport}
      disableEdit={readOnly}
      onSave={(report) => setSelectedReport(report)}
      onClose={() => setSelectedReport((prev) => null)}
    />
  );

  return (
    <div className={classes.root}>
      <Panel2
        sm
        flexAuto
        content={reports_list}
        isPanel3Open={isTabletView ? false : !!selectedReport}
        hidden={isTabletView && !!selectedReport}
      />
      {(!isTabletView || !!selectedReport) && (
        <Panel3
          lg
          content={report_details}
          hidden={!selectedReport}
          maintainWidth={!isTabletView}
          disableMaxWidth={isTabletView}
          goBack={isTabletView ? () => setSelectedReport((prev) => null) : null}
        />
      )}
    </div>
  );
}
