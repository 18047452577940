import React from "react";
import {Bar} from "react-chartjs-2";
import {lighten, makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
  },
}));

export default function BarChart({data, currency}) {
  const classes = useStyles();

  function hoverColorize(ctx) {
    const bgColor = ctx.element.options.backgroundColor;
    const c = bgColor.substring(0, bgColor.length - 2);
    const hoverColor = lighten(c, 0.05);
    return hoverColor;
  }

  const chartData = React.useMemo(() => {
    return {
      labels: data?.labels || [],
      datasets: [
        {
          label: data?.title || "",
          data: data?.data || [],
          backgroundColor:
            data?.colors.map((c) => `${c}BF`) || "rgba(14, 122, 253, 0.75)",
          borderColor: "rgba(255,255,255,0)",
          borderWidth: 5,
        },
      ],
    };
  }, [data]);

  return React.useMemo(
    () => (
      <div className={classes.root}>
        <Bar
          data={chartData}
          options={{
            maintainAspectRatio: false,
            responsive: true,
            plugins: {
              legend: {display: false},
            },
            elements: {
              bar: {
                hoverBackgroundColor: hoverColorize,
              },
            },
            scales: {
              x: {
                grid: {
                  drawOnChartArea: false,
                  drawTicks: false,
                },
                ticks: {color: "#A3A3A3"},
              },
              y: {
                grid: {color: "#F0F0F0"},
                ticks: {
                  callback: function (value, index, values) {
                    if (currency) {
                      return `$${value >= 1000 ? `${Math.floor(value / 1000)}k` : value}`;
                    } else return value;
                  },
                  color: "#A3A3A3",
                },
              },
            },
          }}
        />
      </div>
    ),
    [chartData],
  );
}
