import React, {useEffect, useState, useRef} from "react";
// UI
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Radio,
  Typography,
  makeStyles,
  useTheme,
} from "@material-ui/core";
import WarningIcon from "@material-ui/icons/WarningRounded";
import CheckStepPage from "./CheckStepPage";
import LockButton from "./LockButton";
// Utils
import useRefDimensions from "utilities/useRefDimensions";
import {getVideoLink} from "utilities/helperFunctions";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  imageSection: {
    flex: 1,
    minHeight: 340,
    position: "relative",
    padding: theme.spacing(7.5, 2.5, 2.5),
  },
  blurLayer: {
    backdropFilter: "blur(1.5px)",
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
  },
  text: {padding: theme.spacing(0, 3.75)},
  btnRow: {
    display: "flex",
    flexDirection: "row",
    gap: theme.spacing(1.5),
    padding: theme.spacing(1, 0),
  },
  btn: {
    fontFamily: '"Montserrat", serif !important',
    borderRadius: "30px !important",
    fontWeight: "500 !important",
    fontSize: "16px !important",
    flex: 1,
    color: theme.palette.primary.contrast,
    backgroundColor: theme.palette.primary.main + "!important",
    "&.back": {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.primary.contrast + "!important",
    },
  },
  radioGroup: {
    display: "flex",
    gap: theme.spacing(2.5),
    justifyContent: "center",
    padding: theme.spacing(1, 0),
    "& > .radioBtn": {
      width: 18,
      height: 18,
      padding: 0,
      color: theme.palette.primary.main,
      "&.disabled": {opacity: 0.3},
    },
  },
  imageContainer: {
    width: "100%",
    height: "100%",
    position: "relative",
  },
  img: {
    maxWidth: "100%",
    maxHeight: "100%",
    objectFit: "contain",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    borderRadius: 10,
  },
  video: {
    width: "100%",
    maxHeight: "100%",
    height: "-webkit-fill-available",
    objectFit: "contain",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    borderRadius: 10,
  },
  dialogTitle: {
    fontSize: 24,
    fontWeight: 600,
    lineHeight: "28px",
    textAlign: "center",
    padding: 0,
  },
  dialogPaper: {
    borderRadius: 10,
    boxShadow: `0px 1px 2px 0px rgba(0, 0, 0, 0.15), 0px 0px 5px 0px rgba(0, 0, 0, 0.05)`,
    padding: theme.spacing(2.5),
    gap: theme.spacing(1.5),
  },
  dialogContent: {
    padding: 0,
    fontSize: 15,
    fontWeight: 400,
    lineHeight: "20px",
    whiteSpace: "break-spaces",
    textAlign: "center",
  },
  warningIcon: {
    fontSize: 50,
    alignSelf: "center",
  },
}));

export default function CheckSteps({
  kc,
  checkin,
  components,
  selectedIndex = null,
  closePage,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const contentRef = useRef(null);
  const isAtTheBottom = useRef(false);
  const check = checkin
    ? (components?.checkin ?? [])
    : (components?.checkout ?? []);
  const [currentStep, setCurrentStep] = useState(selectedIndex ?? 0);
  const [visitedSteps, setVisitedSteps] = useState(
    selectedIndex !== null
      ? Array.from({length: selectedIndex + 1}, (_, i) => i)
      : [0],
  );
  const [modalOpen, setModalOpen] = useState(false);
  const [visibleBottom, setVisibleBottom] = React.useState(false);
  const [stepValues, setStepValues] = useState({
    description: "",
    button: "Next",
    is_last: false,
  });
  const contentDimensions = useRefDimensions(contentRef);
  let stepContent = check[currentStep] ?? {};
  let hasMedia = !!stepContent?.video || !!stepContent?.picture;
  let showLockBtn =
    !!components?.devices?.[stepContent?.device_id]?.smart_lock ||
    !!components?.devices?.[stepContent?.device_id]?.smart_lock_name ||
    !!components?.devices?.[stepContent?.device_id]?.smart_lock_code;
  let showDeviceLink =
    !showLockBtn && (!!stepContent?.link_label || !!stepContent?.link_url);
  let stepsCompleted =
    (kc?.view === "checked_in" && checkin) ||
    (kc?.view === "checked_out" && !checkin);

  useEffect(() => {
    if (!!check?.length) {
      let is_last = check.length === currentStep + 1;
      setStepValues({
        is_last: is_last,
        button: is_last
          ? stepsCompleted
            ? "Close"
            : checkin
              ? `I'm in!`
              : `I'm out!`
          : "Next",
        id: is_last
          ? stepsCompleted
            ? "close"
            : checkin
              ? "imin"
              : "imout"
          : "next",
      });
    }
  }, [currentStep]);

  useEffect(() => {
    if (
      contentRef.current?.scrollHeight > contentDimensions.height + 55 &&
      !!visibleBottom
    ) {
      isAtTheBottom.current = false;
      setVisibleBottom((prev) => false);
    } else if (
      contentRef.current?.scrollHeight <= contentDimensions.height + 55 &&
      !visibleBottom
    ) {
      isAtTheBottom.current = true;
      setVisibleBottom((prev) => true);
    }
    scrollToTop();
  }, [currentStep, contentDimensions]);

  function handleNextStep() {
    if (stepValues.is_last && stepsCompleted) {
      closePage && closePage();
    } else {
      setVisitedSteps((prev) => [...prev, currentStep + 1]);
      if (stepValues.is_last) {
        closePage && closePage();
      } else {
        setCurrentStep(currentStep + 1);
      }
    }
  }

  function handlePrevStep() {
    if (currentStep === 0) {
      closePage && closePage();
    } else {
      setCurrentStep(currentStep - 1);
    }
  }

  const confirmCheckout = () => {
    closeModal();
    closePage && closePage();
  };

  const goToStep = (index) => setCurrentStep(index);
  const closeModal = () => setModalOpen((prev) => false);
  const scrollToTop = () =>
    contentRef.current?.scrollTo({top: 0, behavior: "instant"});
  const scrollToBottom = () => {
    if (!!contentRef.current) {
      const el = contentRef.current;
      el.scrollTo({
        top: contentRef.current?.scrollHeight ?? 0,
        behavior: "smooth",
      });
      isAtTheBottom.current = true;
    }
  };

  const handleProgressBtnClick = (index) => () => {
    if (index === currentStep) {
      return;
    } else if (index < currentStep) {
      goToStep(index);
    } else if (index > currentStep && visitedSteps.includes(index)) {
      goToStep(index);
    } else {
      return;
    }
  };

  function getProgressBtns() {
    return (
      <div className={classes.radioGroup}>
        {check.map((step, ind) => {
          let isSelected = ind === Number(currentStep);
          let disabled = !visitedSteps.includes(ind);
          return (
            <Radio
              key={ind}
              className={`radioBtn ${disabled ? "disabled" : ""}`}
              checked={isSelected}
              value={step.description}
              disabled={disabled}
              onChange={handleProgressBtnClick(ind)}
            />
          );
        })}
      </div>
    );
  }

  const handleContentScroll = (e) => {
    const el = e.target;
    if (!!el) {
      let scrollBottom = el.scrollHeight - el.scrollTop - el.clientHeight;
      isAtTheBottom.current = scrollBottom <= 55;
      if (scrollBottom <= 55 && !visibleBottom) {
        setVisibleBottom((prev) => true);
      } else if (scrollBottom > 55 && !!visibleBottom) {
        setVisibleBottom((prev) => false);
      }
    }
  };

  const checkoutModal = (
    <Dialog
      open={!!modalOpen}
      onClose={closeModal}
      PaperProps={{className: classes.dialogPaper}}
    >
      <WarningIcon className={classes.warningIcon} />
      <DialogTitle className={classes.dialogTitle}>
        {"Are you sure you want to check-out?"}
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        {
          "If you proceed to finish your checkout you will no longer have access to your guidebooks, check-in steps or access codes."
        }
        <br />
        <br />
        {
          "Please ensure you have completed all the necessary checkout steps before proceeding."
        }
      </DialogContent>
      <DialogActions>
        <Button
          disableRipple
          className={clsx(classes.btn, "back")}
          onClick={closeModal}
          variant="outlined"
        >
          {"Back"}
        </Button>
        <Button
          disableRipple
          className={classes.btn}
          onClick={confirmCheckout}
          variant="contained"
        >
          {"Check-out"}
        </Button>
      </DialogActions>
    </Dialog>
  );

  const actionPanel = (
    <>
      {getProgressBtns()}
      {showLockBtn && (
        <div>
          <LockButton
            state={components?.devices[stepContent?.device_id]}
            handleUnlock={() => {}}
            components={components}
            device_id={stepContent?.device_id}
          />
        </div>
      )}
      {showDeviceLink && (
        <div>
          <LockButton showLink state={stepContent} components={components} />
        </div>
      )}
      <div className={classes.btnRow}>
        {currentStep > 0 && (
          <Button
            disableRipple
            className={clsx(classes.btn, "back")}
            onClick={() => handlePrevStep()}
            variant="outlined"
          >
            {"Back"}
          </Button>
        )}
        <Button
          disableRipple
          id={stepValues.id}
          className={classes.btn}
          onClick={() => {
            if (visibleBottom) {
              handleNextStep();
            } else if (isAtTheBottom.current) {
              handleNextStep();
            } else {
              scrollToBottom();
            }
          }}
          variant="contained"
        >
          {visibleBottom || isAtTheBottom.current
            ? stepValues.button
            : "Continue"}
        </Button>
      </div>
    </>
  );

  const stepMainContent = (
    <>
      {hasMedia && (
        <div
          className={classes.imageSection}
          style={{
            background: `linear-gradient(0deg, ${theme.palette.primary.contrast} 0%, rgba(0, 0, 0, 0.3) 100%), url("${stepContent?.picture}") no-repeat center center / cover ${theme.palette.primary.contrast + "80"}`,
          }}
        >
          <div className={classes.blurLayer} />
          <div className={classes.imageContainer}>
            {!!stepContent?.video ? (
              <div className={classes.video}>
                <iframe
                  width="100%"
                  height="100%"
                  src={getVideoLink(stepContent?.video)}
                  title="Step video"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerPolicy="strict-origin-when-cross-origin"
                  allowFullScreen
                ></iframe>
              </div>
            ) : (
              !!stepContent?.picture && (
                <img className={classes.img} src={stepContent?.picture} />
              )
            )}
          </div>
        </div>
      )}
      <div
        className={classes.text}
        style={{paddingTop: !hasMedia ? theme.spacing(7.5) : 0}}
      >
        {!!stepContent.description &&
          stepContent?.description.split("\n").map((p, i) => (
            <Typography
              key={`description-${i}`}
              style={{marginBottom: 10, wordBreak: "break-word"}}
            >
              {p}
            </Typography>
          ))}
        {!stepContent.description && (
          <Typography style={{fontStyle: "italic", opacity: 0.5}}>
            {"Instructions"}
          </Typography>
        )}
      </div>
    </>
  );

  return (
    <CheckStepPage
      modal={checkoutModal}
      actionPanel={actionPanel}
      onClose={closePage}
      contentRef={contentRef}
      handleContentScroll={handleContentScroll}
      pageContent={stepMainContent}
    />
  );
}
