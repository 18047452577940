import {makeStyles} from "@material-ui/core";
import {
  desktopSizes,
  mobileSizes,
  tabletSizes,
  THEME,
} from "configuration/settings.js";

const useThreePanelStyles = makeStyles((theme) => ({
  // Mobile styles
  mobileGridItem: {
    zIndex: 1,
    backgroundColor: theme.palette.common.white,
    height: "unset",
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    "&::-webkit-scrollbar": {width: "0!important"},
  },
  mobileListPanel: {
    overflow: "visible",
    zIndex: 2,
    height: "100%",
    width: mobileSizes.listPanel,
    backgroundColor: (props) => props.bg,
  },
  mobileDetailsPanel: {
    overflowY: "scroll",
    overflowX: "hidden",
    height: "100%",
    width: mobileSizes.detailsPanel,
    backgroundColor: (props) => props.bg,
  },
  mobileActionPanel: {
    overflowY: "scroll",
    overflowX: "hidden",
    height: "100%",
    width: mobileSizes.actionPanel,
    backgroundColor: (props) => props.bg,
  },

  // Tablet & desktop panel styles
  basePanel: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  panel1: {
    borderRadius: "10px 0px 0px 10px",
    borderRight: "1px solid rgba(13, 86, 140, 0.05)",
    boxShadow: "-2px 0px 8px -3px rgba(28,41,90,0.101961)",
    backgroundColor: (props) => props.bg,
    [theme.breakpoints.up("lg")]: {
      width: desktopSizes.listPanel,
      minWidth: desktopSizes.listPanel,
    },
    [theme.breakpoints.down("md")]: {
      width: tabletSizes.listPanel,
      minWidth: tabletSizes.listPanel,
    },
    "&.-rounded": {
      borderRadius: 10,
      borderRight: "none",
      boxShadow: "0px 0px 8px rgba(28,41,90,0.101961)",
    },
  },
  panel2: {
    borderRight: "1px solid rgba(13, 86, 140, 0.05)",
    boxShadow: "0px 0px 8px -3px rgba(28,41,90,0.101961)",
    flex: 1,
    width: "100%",
    backgroundColor: (props) => props.bg,
    "&.-lg": {
      borderRadius: "10px 0px 0px 10px",
      boxShadow: "-2px 0px 8px -3px rgba(28,41,90,0.101961)",
      maxWidth: "65%",
    },
    "&.-lg-last-panel": {
      borderRadius: 10,
      maxWidth: "100%",
      boxShadow: "0px 0px 8px rgba(28,41,90,0.101961)",
    },
    "&.-hidden": {
      visibility: "hidden",
      width: 0,
      minWidth: 0,
      height: 0,
      flex: 0,
      border: "none",
    },
    "&.-last-panel": {
      borderRadius: "0px 10px 10px 0px",
      borderRight: "none",
      boxShadow: "2px 0px 8px -3px rgba(28,41,90,0.101961)",
    },
    "&.flexAuto": {flex: "auto"},
  },
  panel3: {
    borderRadius: "0px 10px 10px 0px",
    backgroundColor: (props) => props.bg,
    boxShadow: "2px 0px 8px -3px rgba(28,41,90,0.101961)",
    width: "-moz-available" /* Firefox */,
    width: "-webkit-fill-available" /* Chrome, Safari */,
    width: "fill-available" /* Standard */,

    maxWidth: 700,
    "&.-hidden": {
      visibility: "hidden",
      width: 0,
      minWidth: 0,
      height: 0,
      flex: 0,
      border: "none",
    },
    "&.-rounded": {
      borderRadius: 10,
      boxShadow: "0px 0px 8px rgba(28,41,90,0.101961)",
    },
    "&.-disableMaxWidth": {maxWidth: "unset"},
    "&.-widthAuto": {
      width: "auto",
      minWidth: 430,
    },
    "&.-fillWidth": {width: "-webkit-fill-available"},
    "&.-flexAuto": {flex: "auto"},
    "&.-maintainWidth": {
      width: "-webkit-fill-available",
      flex: "unset",
    },
  },
  paper: {
    display: "flex",
    height: "100%",
    "&.-hidden": {
      width: 0,
      height: 0,
    },
  },
  btrr0: {borderTopRightRadius: "0px !important"},
  fullWidth: {width: "100%"},
  // Multi-inbox
  mainContainer: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    height: "100%",
    overflow: "hidden",
    "&.fitContent": {width: "fit-content"},
  },
  panelGroup: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    maxWidth: "65%",
    minWidth: "65%",
    "&.fullWidth": {
      width: "100%",
      maxWidth: "100%",
    },
    "&.tabletPanel3Open": {
      width: "fit-content",
      minWidth: "fit-content",
    },
  },
  messagingPanels: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "row",
    overflow: "hidden",
  },
}));

export default useThreePanelStyles;
