import {makeStyles} from "@material-ui/core/styles";

const useHouseListStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: "100%",
    display: "flex",
    flexDirection: "column",
    position: "relative",
    overflow: "hidden",
    borderRadius: (props) => (!props.detailsPanelClosed ? "10px 0 0 10px" : 10),
  },
  header: {
    padding: (props) =>
      !!props.disableGutters ? theme.spacing(2, 3) : theme.spacing(4),
    paddingBottom: (props) =>
      !!props.disableGutters ? theme.spacing(2) : theme.spacing(3),
  },
  searchContainer: {
    padding: (props) => (!!props.disableGutters ? 0 : theme.spacing(0, 4, 4)),
    "& > .searchContainer": {paddingBottom: 0},
    "&.-no-title": {padding: theme.spacing(0, 0, 3)},
  },
  content: {
    flexGrow: 1,
    overflow: "hidden",
  },
  contentGroups: {
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
    "&::-webkit-scrollbar": {width: "0!important"},
  },
  listingsContainer: {
    flexGrow: 1,
    minHeight: 280,
    padding: theme.spacing(0, 4, 3),
    backgroundColor: "#F1F3F9",
    "&.-paddingTop": {paddingTop: theme.spacing(3)},
  },
  ellipsis: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "100%",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    wordBreak: "break-all",
  },
  itemIcon: {
    marginRight: theme.spacing(3),
    height: "100%",
  },
  img: {
    width: 85,
    height: "100%",
    objectFit: "cover",
    borderRadius: 7,
    backgroundColor: theme.palette.grey[300],
  },
  title: {
    WebkitLineClamp: 1,
    fontWeight: 500,
    fontSize: 15,
    lineHeight: "20px",
    color: "#000",
  },
  subtitle: {
    WebkitLineClamp: 3,
    fontSize: 13,
    color: "rgba(60, 60, 67, 0.6)",
  },
  multiListItem: {
    height: "100%",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    cursor: (props) => (!!props.disableSelect ? "default" : "pointer"),
  },
  listItem: {
    backgroundColor: "rgba(255, 255, 255, 0.85)",
    borderRadius: 10,
    height: "100%",
    padding: theme.spacing(3, 4),
    cursor: (props) => (!!props.disableSelect ? "default" : "pointer"),
  },
  icon: {
    fontSize: 16,
    color: theme.palette.action.focus,
    marginLeft: theme.spacing(3),
    marginRight: -theme.spacing(1),
  },
  checkbox: {
    width: "fit-content",
    height: "fit-content",
    alignSelf: "center",
  },
  multiselected: {
    backgroundColor: `${theme.palette.secondary.main}0d !important`,
  },
  selected: {
    backgroundColor: "rgba(255, 255, 255, 0.85) !important",
    border: "4px solid #007AFF",
    padding: "8px 16px 9px",
  },
  multiselectSelected: {
    backgroundColor: "rgba(67, 84, 96, 0.0313726) !important",
  },
  groupItem: {
    backgroundColor: "#FFF",
    cursor: "default",
    height: 48,
    "&:hover": {
      "& .-settingsBtn": {
        opacity: 1,
        width: "fit-content !important",
        minWidth: "fit-content !important",
        paddingRight: theme.spacing(4),
        backgroundColor: "#f8f8f9",
      },
      "& .clickableRow": {paddingRight: theme.spacing(3)},
    },
    "&.-expanded": {
      backgroundColor: "#F1F3F9 !important",
      "&:hover .-settingsBtn": {backgroundColor: "#F1F3F9 !important"},
    },
  },
  groupTitle: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    "& > .clickableRow": {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      cursor: "pointer",
      flexGrow: 1,
    },
    "& .-title": {
      flexGrow: 1,
      overflow: "hidden",
      textOverflow: "ellipsis",
      width: "100%",
      display: "-webkit-box",
      WebkitBoxOrient: "vertical",
      wordBreak: "break-all",
      lineClamp: 1,
    },
  },
  settingsBtn: {
    opacity: 0,
    fontSize: 12,
    transition: "opacity 0.15s",
    padding: 0,
    paddingTop: theme.spacing(1),
    borderRadius: 0,
    minWidth: 0,
    width: 0,
    overflow: "hidden",
    whiteSpace: "nowrap",
    textTransform: "none",
    "&:hover": {textDecoration: "underline"},
  },
  groupText: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "100%",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    wordBreak: "break-all",
    lineClamp: 2,
  },
  expandArrowIcon: {
    fontSize: 16,
    transition: "0.15s",
    marginRight: theme.spacing(3),
    color: "rgba(0, 0, 0, 0.54)",
    "&.-expanded": {transform: "rotate(90deg)"},
  },
  listItemDivider: {borderBottom: "1px solid #F3F7F9"},
}));

export default useHouseListStyles;
