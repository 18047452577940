import React from "react";
// UI
import {Card, CardContent, CardHeader} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import useDashboardStyles from "styles/useDashboardStyles";
// Custom
import BarChart from "components/Charts/BarChart";
// Helpers & Utilities
import {metricLabels} from "configuration/constants.js";
import {THEME} from "configuration/settings.js";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  content: {padding: "10px 8px 0px !important"},
}));

export default function BarChartCard({data, redirect}) {
  const classes = useStyles();
  const dashboardStyles = useDashboardStyles();
  const title = data.title || metricLabels[data.metric];

  const goToReport = () => redirect(data.report_id);

  function getChartData() {
    const labels = [];
    const chart_data = [];
    const colors = [];
    for (let index = 0; index < data.buckets.length; index++) {
      const bucket = data.buckets[index];
      labels.push(bucket.label);
      chart_data.push(bucket.total);
      colors.push(bucket.color || THEME.secondary);
    }
    return {
      labels: labels,
      data: chart_data,
      title: metricLabels[data.metric],
      colors: colors,
    };
  }

  const barChart = React.useMemo(
    () => <BarChart data={getChartData()} />,
    [data],
  );

  return (
    <Card className={dashboardStyles.card} onClick={goToReport}>
      <CardHeader
        className={dashboardStyles.cardHeader}
        classes={{action: dashboardStyles.cardAction}}
        title={title}
        titleTypographyProps={{className: dashboardStyles.cardTitle}}
      />
      <CardContent
        className={clsx(classes.content, dashboardStyles.cardContent)}
      >
        {barChart}
      </CardContent>
    </Card>
  );
}
