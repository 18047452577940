import React from "react";
import {Line} from "react-chartjs-2";
import {lighten, makeStyles} from "@material-ui/core/styles";
import {THEME} from "configuration/settings.js";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100% !important",
    height: "100%",
    position: "relative",
  },
  gradientLeft: {
    position: "absolute",
    left: 0,
    height: (props) => (props.smallGradient ? "93%" : "88%"),
    bottom: (props) => (props.smallGradient ? "7%" : "12%"),
    width: (props) => (props.smallGradient ? "10%" : "10%"),
    background:
      "linear-gradient(to left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255) 100%)",
  },
  // gradientBottom: {
  //   position: 'absolute',
  //   left: 0,
  //   width: '100%',
  //   bottom: props => props.smallGradient ? '7%' : '12%',
  //   height: props => props.smallGradient ? '5%' : '14%',
  //   background: 'linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255) 100%)',
  // },
}));

export default function LineChart({data, smallGradient}) {
  const styleProps = {smallGradient};
  const classes = useStyles(styleProps);

  const chartData = React.useMemo(() => {
    const hasData = !!data?.data;
    return {
      labels: data?.labels || [],
      datasets: !hasData
        ? []
        : data.data.map((d, i) => {
            const mainColor = d.bucket.color || THEME.secondary;
            return {
              label: d.bucket.label,
              data: d.values,
              fill: false,
              borderColor: mainColor,
              backgroundColor: lighten(mainColor, 0.8),
              borderWidth: 1.5,
              borderCapStyle: "round",
              tension: 0.3,
              pointRadius: 0,
              pointHoverRadius: 0,
              pointHitRadius: 50,
            };
          }),
    };
  }, [data]);

  return React.useMemo(
    () => (
      <div className={classes.root}>
        {/* <div className={classes.gradientLeft} /> */}
        {/* <div className={classes.gradientBottom} /> */}
        <Line
          data={chartData}
          options={{
            maintainAspectRatio: false,
            responsive: true,
            plugins: {
              legend: {display: false},
            },
            scales: {
              x: {
                grid: {
                  drawBorder: false,
                  drawOnChartArea: false,
                  drawTicks: false,
                },
                ticks: {
                  font: {
                    weight: 700,
                    size: 10,
                    lineHeight: "17px",
                  },
                  color: "rgba(112,112,112,0.5)",
                  maxTicksLimit: 4,
                  maxRotation: 0,
                  minRotation: 0,
                },
              },
              y: {
                display: false,
                offset: true,
              },
            },
          }}
        />
      </div>
    ),
    [chartData],
  );
}
