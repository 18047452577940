import React from "react";
import {useSelector} from "react-redux";
import {NavLink, useHistory, useLocation} from "react-router-dom";
// UI
import {makeStyles} from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import Tooltip from "@material-ui/core/Tooltip";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import {ReactComponent as SettingsIcon} from "assets/icons/Icon_Business_Settings.svg";
import {ReactComponent as DashboardIcon} from "assets/icons/Enso.svg";
import {ReactComponent as MessagesIcon} from "assets/icons/Icon_Messages.svg";
import {ReactComponent as ListingsIcon} from "assets/icons/Icon_Listing_Management.svg";
import {ReactComponent as GuestsIcon} from "assets/icons/Icon_Guests.svg";
import {ReactComponent as HomeIcon} from "assets/icons/house.svg";
import {ReactComponent as BarChartIcon} from "assets/icons/bar-chart.svg";
import SvgIcon from "@material-ui/core/SvgIcon";
// Utilities
import {desktopSizes, mobileSizes, THEME} from "configuration/settings.js";
import {toSnakeCase} from "utilities/formatUtilities";
import {scopes} from "configuration/constants";
import routes from "configuration/routes.js";
import clsx from "clsx";

const selectedScopes = Object.keys(scopes).filter(
  (scope) => !!scopes[scope].path,
);
const mobileScopes = {
  home: {label: "Home", path: "home"},
  messaging: scopes.messaging,
  dashboard: {label: "Dashboard", path: "dashboard"},
};
const mobileScopeKeys = Object.keys(mobileScopes);

const icons = {
  reports: {icon: DashboardIcon, viewBox: "0 0 30 29"},
  home: {icon: HomeIcon, viewBox: "0 0 16 16"},
  dashboard: {icon: BarChartIcon, viewBox: "0 0 25 24"},
  listings: {icon: ListingsIcon, viewBox: "0 0 35 35"},
  messaging: {icon: MessagesIcon, viewBox: "0 0 35 35"},
  business_settings: {icon: SettingsIcon, viewBox: "0 1 27 24"},
  crm: {icon: GuestsIcon, viewBox: "0 0 32 19"},
};

const useStyles = makeStyles((theme) => ({
  navbar: {
    minWidth: desktopSizes.navbar,
    height: "100%",
    justifyContent: "space-between",
    overflow: "hidden auto",
    "&.padd": {padding: theme.spacing(4, 0)},
  },
  routesList: {flexGrow: 1},
  columnCenter: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  link: {
    [theme.breakpoints.down("sm")]: {marginBottom: 0},
    [theme.breakpoints.up("sm")]: {marginBottom: theme.spacing(2)},
  },
  avatar: {
    width: 30,
    height: 30,
    border: `1px solid ${theme.palette.common.white}`,
    boxShadow: "0px 0px 2px rgba(0,0,0,0.15)",
    cursor: "pointer",
  },
  iconButton: {
    color: THEME.grey,
    padding: theme.spacing(2),
    transition: ".15s",
    "& .icon": {width: 30},
    "&:hover": {color: theme.palette.secondary.main},
  },
  selectedIcon: {color: theme.palette.secondary.main},
  mobileNavbar: {
    padding: theme.spacing(0, 1),
    minHeight: mobileSizes.navbar,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-evenly",
    overflow: "auto hidden",
    width: "100%",
    backgroundColor: "#F0F1EA",
  },
}));

function AdminNavbar({handleSignOut}) {
  const classes = useStyles();
  const history = useHistory();
  const user_profile = useSelector(
    (state) => state.defaultReducer.user_profile,
  );
  const isMobileView =
    useSelector((state) => state.defaultReducer.deviceType) === "mobile";
  const hideNavbar = useSelector((state) => state.defaultReducer.hideNavbar);
  const selectedViewItems = useSelector(
    (state) => state.defaultReducer.selected_view_items,
  );
  let path = useLocation().pathname;
  let selected = routes.filter((route) => {
    return path.includes(route.layout + route.path);
  })[0];
  const [anchorEl, setAnchorEl] = React.useState(null);

  const getIcon = (option, selected) => {
    return (
      <SvgIcon
        fontSize="large"
        viewBox={icons[option].viewBox}
        className={clsx("icon", {
          [classes.selectedIcon]: selected,
        })}
        component={icons[option].icon}
      />
    );
  };

  const handleMenuOpen = (e) => setAnchorEl(e.currentTarget);
  const handleMenuClose = () => setAnchorEl(null);

  const goToProfile = () => {
    setAnchorEl(null);
    history.push(`/admin/settings/users/${user_profile.enso_user_id}`);
  };

  const getCustomPath = (route, path) => {
    let newPath = `/admin/${route}`;
    switch (route) {
      case "messages":
        if (path.includes("/admin/messages")) {
          newPath = path;
        }
        break;
      case "listings":
        let savedItem = selectedViewItems.listings.item;
        let savedProps = selectedViewItems.listings.props;
        if (!!savedItem) {
          if (!!savedProps.isGroup) {
            newPath = `/admin/listings/groups/${savedItem}`;
          } else {
            newPath = `/admin/listings/${savedItem}`;
            if (!!savedProps.listingSection) {
              newPath += `/${savedProps.listingSection}`;
            }
          }
        }
        break;
    }
    return newPath;
  };

  const getRouteLink = (scope) => {
    const option = mobileScopes[scope] ?? scopes[scope];
    const route = option.path;
    const isActive =
      route === "settings"
        ? path.includes("/admin/settings")
        : route === "messages"
          ? ["/admin/chat", "/admin/profile", "/admin/messages"].some((p) =>
              path.includes(p),
            )
          : selected?.path === `/${route}`;
    let isDashboardHidden =
      isMobileView &&
      route.includes("dashboard") &&
      user_profile.scopes?.reports === "hide";
    const hideOption =
      isDashboardHidden ||
      (user_profile.scopes?.[scope] === "hide" &&
        !["dashboard", "settings"].includes(route));

    if (hideOption || route === "settings/billing") {
      return null;
    } // we don't want to display billing on the navbar

    return (
      <NavLink
        key={scope}
        to={getCustomPath(route, path)}
        className={classes.link}
        id={`nav-${route}`}
      >
        <Tooltip
          title={option.label === "Reports" ? "Dashboard" : option.label}
        >
          <IconButton
            id={`navbar-${toSnakeCase(option.label)}`}
            className={classes.iconButton}
          >
            {getIcon(scope, isActive)}
          </IconButton>
        </Tooltip>
      </NavLink>
    );
  };

  const avatarMenu = () => (
    <Menu
      id="account-menu"
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleMenuClose}
      anchorOrigin={{vertical: "top", horizontal: "right"}}
      transformOrigin={{vertical: "top", horizontal: "left"}}
    >
      {!isMobileView && user_profile.scopes?.accounts !== "hide" && (
        <MenuItem disabled={!user_profile.enso_user_id} onClick={goToProfile}>
          <ListItemText
            primary="My Profile"
            primaryTypographyProps={{color: "secondary"}}
          />
        </MenuItem>
      )}
      <MenuItem onClick={handleSignOut}>
        <ListItemText
          primary="Logout"
          primaryTypographyProps={{color: "secondary"}}
        />
      </MenuItem>
    </Menu>
  );

  const getAvatar = () => {
    return (
      <Avatar
        className={classes.avatar}
        alt="Profile Picture"
        src={user_profile.profile_picture}
        onClick={handleMenuOpen}
      />
    );
  };

  if (hideNavbar) {
    return null;
  }
  return (
    <>
      {avatarMenu()}
      {isMobileView ? (
        <div className={classes.mobileNavbar}>
          {!!Object.keys(user_profile).length &&
            mobileScopeKeys.map((scope) => getRouteLink(scope))}
          {getAvatar()}
        </div>
      ) : (
        <div
          className={clsx(classes.navbar, classes.columnCenter, {
            padd: path.includes("/dashboard"),
          })}
        >
          <List className={clsx(classes.routesList, classes.columnCenter)}>
            {!!Object.keys(user_profile).length &&
              selectedScopes.map((scope) => getRouteLink(scope))}
          </List>
          <div className={classes.columnCenter}>{getAvatar()}</div>
        </div>
      )}
    </>
  );
}

export default AdminNavbar;
