import React from "react";
import {isMobile} from "react-device-detect";
import {makeStyles} from "@material-ui/core/styles";
import {onboardingContent} from "configuration/specs";
import {Button, Typography} from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: (props) => (!!props.banner ? 0 : 10),
    background: "rgba(47, 122, 251, 0.10)",
    marginBottom: theme.spacing(4),
  },
  summary: {
    display: "flex",
    flexDirection: "column",
    padding: (props) => theme.spacing(!!props.banner ? 4 : 3),
    flex: 1,
    gap: theme.spacing(3),
  },
  crmRow: {
    marginTop: theme.spacing(2),
    gap: theme.spacing(3),
    padding: (props) => theme.spacing(!!props.banner ? 4 : 3),
  },
  row: {
    display: "flex",
    flexDirection: "row",
    gap: theme.spacing(3),
  },
  img: {
    width: 100,
    objectFit: "contain",
    height: "fit-content",
    maxHeight: 100,
    alignSelf: "flex-end",
    marginRight: theme.spacing(3),
  },
  btn: {
    borderRadius: 25,
    textTransform: "none",
    backgroundColor: theme.palette.common.white,
    color: theme.palette.secondary.main,
    padding: theme.spacing(1, 3),
    width: "fit-content",
  },
  buttons: {flexWrap: "wrap"},
}));

const GJEInfoCard = ({type, banner}) => {
  const classes = useStyles({banner});
  let typeObj = type.includes(".") ? type.split(".") : null;
  let content = !!typeObj
    ? onboardingContent[typeObj[0]][typeObj[1]]
    : onboardingContent[type];

  const handleBtnClick = (articleId) => () => {
    if (!!window.Intercom && !isMobile) {
      window.Intercom("showArticle", articleId);
    }
  };

  if (!content) {
    return null;
  }
  return (
    <div className={classes.card}>
      <div style={{display: "flex", flexDirection: "row"}}>
        <div className={classes.summary} style={{marginRight: 30}}>
          <Typography variant="subtitle2" color="primary">
            {content.description}
          </Typography>
        </div>
        <img className={classes.img} src={content.image_url} />
      </div>
      <div className={classes.crmRow}>
        <div style={{display: "flex", marginBottom: 5}}>
          <div style={{flex: 1}}>
            <Typography variant="h2" style={{fontWeight: 600}} color="primary">
              Explore Boarding Pass Data
            </Typography>
          </div>
          <Button
            id={"asdasd"}
            disableRipple
            disableElevation
            className={classes.btn}
            onClick={handleBtnClick("asdasd")}
          >
            Open View
          </Button>
        </div>
        <Typography variant="subtitle2" color="primary">
          Show information collected via the Boarding Pass during the
          verification process, including any custom collect detail fields.
        </Typography>
      </div>
      <div className={classes.crmRow}>
        <div style={{display: "flex", marginBottom: 5}}>
          <div style={{flex: 1}}>
            <Typography variant="h2" style={{fontWeight: 600}} color="primary">
              Explore Guest Sentiment
            </Typography>
          </div>
          <Button
            id={"asdasd"}
            disableRipple
            disableElevation
            className={classes.btn}
            onClick={handleBtnClick("asdasd")}
          >
            Open View
          </Button>
        </div>
        <Typography variant="subtitle2" color="primary">
          View guest sentiment, AI conversations summaries & message topics to
          better understand your guest journey.
        </Typography>
      </div>
      <div className={classes.crmRow}>
        <div style={{display: "flex", marginBottom: 5}}>
          <div style={{flex: 1}}>
            <Typography variant="h2" style={{fontWeight: 600}} color="primary">
              Explore Booking Data
            </Typography>
          </div>
          <Button
            id={"asdasd"}
            disableRipple
            disableElevation
            className={classes.btn}
            onClick={handleBtnClick("asdasd")}
          >
            Open View
          </Button>
        </div>
        <Typography variant="subtitle2" color="primary">
          Show checkin & checkout dates, payment amount & other booking data.
        </Typography>
      </div>
      <div className={classes.crmRow}>
        <div style={{display: "flex", marginBottom: 5}}>
          <div style={{flex: 1}}>
            <Typography variant="h2" style={{fontWeight: 600}} color="primary">
              Connect your CRM & Export Data
            </Typography>
          </div>
          <Button
            id={"asdasd"}
            disableRipple
            disableElevation
            className={classes.btn}
            onClick={handleBtnClick("asdasd")}
          >
            Learn More
          </Button>
        </div>
        <Typography variant="subtitle2" color="primary">
          Learn how to automatically sync guest data with your CRM & perform-one
          time exports to a CSV, Excel or JSON file.
        </Typography>
      </div>
    </div>
  );
};

export default GJEInfoCard;
