import React from "react";
import {useDispatch, useSelector} from "react-redux";
// UI
import {makeStyles} from "@material-ui/core/styles";
import {
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  SvgIcon,
} from "@material-ui/core";
// Custom
import {ReactComponent as PinIcon} from "assets/icons/Icon_PinTack.svg";
import {EmptyListPanel} from "components/Helpers/EmptyPanels";
import CustomCardHeader from "core/cards/CustomCardHeader";
import PrimaryButton from "core/buttons/PrimaryButton";
import SearchBar from "core/bars/SearchBar";
// Actions
import {getAllReports, postReport} from "redux/actions/reportsActions";

import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  pinIcon: {fontSize: 15},
  selected: {color: theme.palette.primary.main},
  disabled: {color: "rgba(112, 112, 112, 0.25)"},
  listItem: {padding: theme.spacing(2, 4)},
}));

export default function ReportList({
  selectedReport,
  onReportSelect,
  disableEdit,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const current_user = useSelector(
    (state) => state.defaultReducer.current_user,
  );
  const reportList = useSelector((state) => state.reportsReducer.report_list);
  const startKeys = useSelector((state) => state.defaultReducer.start_keys);
  const selectedReportId = useSelector(
    (state) => state.reportsReducer.selected_report_id,
  );
  const [searchText, setSearchText] = React.useState("");
  const loading = startKeys.reports === null;

  React.useEffect(() => {
    if (!current_user) return;
    !reportList.length && dispatch(getAllReports());
    !!selectedReportId && onReportSelect({report_id: selectedReportId});
  }, [current_user]);

  const onStarredChange = (report) => () => {
    const newMetrics = [...report.metrics];
    newMetrics[0].starred = !newMetrics[0].starred;
    dispatch(postReport(report.report_id, report.title, newMetrics));
  };

  const handleSearchInput = (val) => {
    setSearchText((prev) => val);
  };

  const selectReport = (item) => {
    onReportSelect(item);
  };

  return loading ? (
    <EmptyListPanel loading list="reports" roundedSearchbar />
  ) : (
    <>
      <CustomCardHeader
        title="Reports"
        action={
          !loading && (
            <PrimaryButton
              label="Create New"
              size="small"
              disabled={selectedReport === "new" || !!disableEdit}
              onClick={() => selectReport("new")}
            />
          )
        }
      />
      <SearchBar
        rounded
        searchInput={searchText}
        handleSearchInput={handleSearchInput}
      />
      <List className="mt-3">
        {reportList.map((item) =>
          !item.report_id ? null : (
            <ListItem
              button
              divider
              disableRipple
              key={item.report_id}
              className={classes.listItem}
              selected={selectedReport?.report_id === item.report_id}
              onClick={(e) => selectReport(item)}
            >
              <ListItemText
                primary={item.title}
                primaryTypographyProps={{variant: "h1"}}
              />
              <ListItemSecondaryAction>
                <IconButton
                  disabled={disableEdit}
                  onClick={onStarredChange(item)}
                >
                  <SvgIcon
                    viewBox="0 0 15 15"
                    component={PinIcon}
                    className={clsx(classes.pinIcon, {
                      [classes.selected]: item.metrics[0].starred,
                      [classes.disabled]: !item.metrics[0].starred,
                    })}
                  />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ),
        )}
      </List>
    </>
  );
}
