import React from "react";
import {Pie} from "react-chartjs-2";
import {lighten, makeStyles} from "@material-ui/core/styles";
import clsx from "clsx";
import {Typography} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    position: "relative",
    paddingBottom: theme.spacing(5),
  },
  extraPadding: {paddingBottom: theme.spacing(6)},
  noData: {
    position: "absolute",
    top: 0,
    left: 0,
    height: "80%",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  legendContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
  },
  legend: {
    "& .-square": {
      width: 13,
      height: 13,
      marginBottom: theme.spacing(1),
    },
    "& .-text": {
      fontSize: 10,
      fontWeight: 700,
      lineHeight: "17px",
      color: "#707070",
      opacity: 0.5,
    },
  },
}));

export default function PieChart({data, fullSize}) {
  const classes = useStyles();
  const noData = !data || data.data.every((value) => !value);

  function getLegend(label, color) {
    return (
      <div key={label} className={classes.legend}>
        <div className="-square" style={{backgroundColor: color}} />
        <Typography className="-text">{label.toUpperCase()}</Typography>
      </div>
    );
  }

  function hoverColorize(ctx) {
    const bgColor = ctx.element.options.backgroundColor;
    const c = bgColor.substring(0, bgColor.length - 2);
    const hoverColor = lighten(c, 0.05);
    return hoverColor;
  }

  const chartData = React.useMemo(() => {
    return {
      labels: data?.labels || [],
      datasets: [
        {
          label: data?.title || "",
          data: data?.data || [],
          backgroundColor: data?.colors.map((c) => `${c}BF`) || [],
          hoverOffset: 4,
          radius: fullSize ? "100%" : "80%",
        },
      ],
    };
  }, [data]);

  return React.useMemo(
    () => (
      <div
        className={clsx(classes.root, {
          [classes.extraPadding]: !!fullSize,
        })}
      >
        {noData && (
          <Typography
            variant="h2"
            color="textSecondary"
            className={classes.noData}
          >
            No Data
          </Typography>
        )}
        <Pie
          data={chartData}
          options={{
            maintainAspectRatio: false,
            responsive: true,
            plugins: {
              legend: {
                display: false,
                position: "bottom",
                onClick: null,
              },
            },
            elements: {
              arc: {
                hoverBackgroundColor: hoverColorize,
              },
            },
          }}
        />
        <div className={clsx(classes.legendContainer, {"mt-3": !!fullSize})}>
          {data?.colors.map((c, i) =>
            getLegend(data.labels[i] || "", `${c}BF`),
          )}
        </div>
      </div>
    ),
    [chartData],
  );
}
