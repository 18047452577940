import React from "react";
// UI
import {
  KeyboardDateTimePicker,
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import {makeStyles} from "@material-ui/core/styles";
import ClockIcon from "@material-ui/icons/Schedule";
// Utils
import {THEME} from "configuration/settings";
import DateFnsUtils from "@date-io/date-fns";

const useStyles = makeStyles((theme) => ({
  picker: {
    margin: 0,
    borderRadius: 5,
    backgroundColor: (props) => (!!props.filled ? THEME.inputBg : "#FFF"),
    "& > label": {
      top: 8,
      left: 12,
      color: theme.palette.text.secondary + "!important",
    },
    "& input": {padding: 0},
    "& > p": {margin: 0},
    "& > div": {
      margin: 0,
      display: "flex",
      height: "100%",
      flexDirection: "row",
      alignItems: "center",
      padding: "17px 12px 0",
    },
  },
  adornmentIcon: {"& > button": {padding: 0}},
}));

function getDateTimePicker(props) {
  const {type, classes, handleValueChange, handleError, error, ...other} =
    props;
  switch (type) {
    case "keyboardDate":
      return null;
    case "time":
      return (
        <KeyboardTimePicker
          fullWidth
          margin="normal"
          className={classes.picker}
          onChange={handleValueChange}
          InputProps={{disableUnderline: true}}
          InputLabelProps={{shrink: true}}
          error={!!error}
          helperText={error}
          InputAdornmentProps={{classes: {root: classes.adornmentIcon}}}
          onError={(e) => handleError(!!e)}
          keyboardIcon={<ClockIcon />}
          {...other}
        />
      );
    case "dateTime":
      return (
        <KeyboardDateTimePicker
          fullWidth
          showTodayButton
          strictCompareDates
          format="MM/dd/yyyy hh:mm a"
          placeholder="MM/DD/YYYY hh:mm am/pm"
          className={classes.picker}
          onChange={handleValueChange}
          minDate={new Date(new Date().getTime() - 60000)}
          minDateMessage="Time must be greater than current time"
          onError={(e) => handleError(!!e)}
          InputProps={{disableUnderline: true}}
          InputLabelProps={{shrink: true}}
          {...other}
        />
      );

    default:
      return null;
  }
}

export default function DateTimePicker(props) {
  const {
    type = "dateTime",
    onChange,
    onInvalidChange = () => null,
    filled,
    ...other
  } = props;
  const classes = useStyles({filled});

  const handleValueChange = (date) => onChange(date);
  const handleError = (hasError) => onInvalidChange(hasError);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      {getDateTimePicker({
        type,
        classes,
        handleValueChange,
        handleError,
        ...other,
      })}
    </MuiPickersUtilsProvider>
  );
}
