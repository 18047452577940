import React from "react";
// Redux
import {useSelector} from "react-redux";
// UI
import useReportTableStyles from "styles/useReportTableStyles";
// Custom
import CustomCellElement from "components/Helpers/CustomCellElement";
import VirtualizedTable from "components/Tables/VirtualizedTable";
// Utilities
import {getNestedObject} from "utilities/helperFunctions";

export default function CampaignTable({campaigns, onCampaignSelect}) {
  const classes = useReportTableStyles();
  // Selectos
  const loading = useSelector(
    (state) => state.defaultReducer.loading,
  ).configured_experiences;
  // State
  const [sortParams, setSortParams] = React.useState({
    sortBy: "",
    sortDirection: "ASC",
  });
  // General
  const rowCount = campaigns.length;

  const handleSort = ({sortBy, sortDirection}) => {
    setSortParams((prev) => ({sortBy, sortDirection}));
  };
  const isRowLoaded = ({index}) => index < campaigns.length;
  const loadMoreRows = () => null;

  const rowGetter = ({index}) => {
    return {
      ...campaigns[index],
      send_time: campaigns[index]?.actions[0]?.trigger_value,
      num_guests: campaigns[index].connected_to?.ids?.length ?? 0,
    };
  };

  const onRowClick = ({index}) => {
    console.log("Row selected: ", campaigns[index]);
    onCampaignSelect(campaigns[index]);
  };

  const getCustomCell = (columnConfig, cellData, rowIndex) => {
    const columnDataKey = columnConfig.dataKey;
    const cellValue = columnDataKey.includes(".")
      ? getNestedObject(campaigns[rowIndex], columnDataKey.split("."))
      : cellData;

    return (
      <div className={classes.column}>
        <CustomCellElement
          data={cellValue}
          rowIndex={rowIndex}
          obj={campaigns[rowIndex]}
          field={columnConfig.field}
        />
      </div>
    );
  };

  const COLS = [
    {
      width: 20,
      label: "",
      field: "",
      dataKey: "",
      flexGrow: 0,
      hideLabel: true,
    },
    {
      width: 1,
      label: "name",
      field: "name",
      dataKey: "name",
      flexGrow: 0.8,
      customCell: true,
    },
    {
      width: 1,
      label: "send time",
      field: "sent_at",
      dataKey: "send_time",
      flexGrow: 0.8,
      customCell: true,
    },
    {
      width: 1,
      label: "# guests",
      field: "",
      dataKey: "num_guests",
      flexGrow: 0.8,
    },
  ];

  return (
    <div className={classes.root}>
      <div className={classes.tableSection}>
        <VirtualizedTable
          rowCount={campaigns.length}
          loaderRowCount={rowCount}
          rowGetter={rowGetter}
          columns={COLS}
          onRowClick={onRowClick}
          rowHeight={56}
          getCustomCell={getCustomCell}
          useInfiniteLoader
          isRowLoaded={isRowLoaded}
          loadMoreRows={loadMoreRows}
          sort={handleSort}
          sortBy={sortParams.sortBy}
          sortDirection={sortParams.sortDirection}
          disableAutoHeaderHeight
        />
      </div>
    </div>
  );
}
