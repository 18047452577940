import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {Redirect, useHistory} from "react-router-dom";
import clsx from "clsx";
// UI
import {
  Button,
  CircularProgress,
  MenuItem,
  MenuList,
  Typography,
} from "@material-ui/core";
import useOldDashboardStyles from "styles/useOldDashboardStyles";
import DateRangeIcon from "@material-ui/icons/DateRange";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
// Custom
import CustomMenu from "core/menus/CustomMenu";
import BarChartCard from "components/Cards/BarChartCard";
import PieChartCard from "components/Cards/PieChartCard";
import LineChartCard from "components/Cards/LineChartCard";
import DraggableLayout from "components/Grids/DraggableLayout";
import BookingList from "components/Lists/BookingList";
// Actions
import {
  getBookingCheckinouts,
  selectBooking,
} from "redux/actions/bookingsActions";
import {getReports, selectReport} from "redux/actions/reportsActions";
// Utilities
import {getGreeting} from "utilities/helperFunctions.js";
import {intervals, maxAllowedStartValues} from "configuration/constants.js";

export default function OldDashboard() {
  const classes = useOldDashboardStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const current_user = useSelector(
    (state) => state.defaultReducer.current_user,
  );
  const user_profile = useSelector(
    (state) => state.defaultReducer.user_profile,
  );
  const report = useSelector((state) => state.reportsReducer.dashboard_report);
  const reportLoading = useSelector(
    (state) => state.defaultReducer.loading,
  ).reports;
  const bkgsLoading = useSelector(
    (state) => state.defaultReducer.loading,
  ).booking_checkinouts;
  const isMobileView =
    useSelector((state) => state.defaultReducer.deviceType) === "mobile";
  // State
  const [redirect, setRedirect] = React.useState(false);
  const [rangeMenuAnchorEl, setRangeMenuAnchorEl] = React.useState(null);
  const [start, setStart] = React.useState(0);
  const [interval, setInterval] = React.useState("month");
  const [menuOptions, setMenuOptions] = React.useState({
    type: "",
    anchorEl: null,
  });
  const [todayMovements, setTodayMovements] = React.useState({
    loaded: false,
    checkins: null,
    checkouts: null,
  });
  // General
  const reportEmpty = React.useMemo(
    () => !Object.keys(report).length,
    [report],
  );
  const users = user_profile.enso_users;
  const currentUser = users?.find(
    (u) => u.enso_user_id === user_profile.enso_user_id,
  );
  const loading = reportLoading || !current_user;
  const greeting = `Good ${getGreeting()}${currentUser ? `, ${currentUser.name.split(" ")[0]}` : ""}`;

  React.useEffect(() => {
    if (!!current_user) {
      dispatch(
        getBookingCheckinouts((response) =>
          setTodayMovements((prev) => ({
            loaded: true,
            checkins: response.checkin,
            checkouts: response.checkout,
          })),
        ),
      );
    }
  }, [current_user]);

  React.useEffect(() => {
    if (!current_user) return;
    getData();
  }, [current_user, start, interval]);

  const getData = () => dispatch(getReports(null, interval, start, 0, []));
  const openRangeMenu = (e) => setRangeMenuAnchorEl(e.currentTarget);
  const closeRangeMenu = () => setRangeMenuAnchorEl(null);
  const closeMovementsMenu = () => setMenuOptions({type: "", anchorEl: null});

  const goToReport = (reportId) => {
    if (isMobileView) {
      return;
    }
    dispatch(selectReport(reportId));
    setRedirect("settings/reports");
  };

  const changeStartRange = (type) => () => {
    setStart((prev) => (type === "before" ? ++prev : --prev));
  };

  const handleBookingSelect = (bkg) => {
    dispatch(selectBooking(bkg.booking_id));
    closeMovementsMenu();
    history.push(`/admin/messages/${bkg.guest_id}`);
  };

  const handleRangeSelect = (newInterval) => () => {
    closeRangeMenu();
    setInterval((prev) => newInterval);
    setStart((prev) => 0);
  };

  const handleMovementsClick = (type) => (e) => {
    const currEl = e.currentTarget;
    setMenuOptions((prev) => ({type: type, anchorEl: currEl}));
  };

  function getRangeLabel(interval) {
    if (start > 0) return `${start} ${interval}${start > 1 ? "s" : ""} Ago`;
    else return `This ${interval}`;
  }

  function getButton(type, label) {
    return (
      <Button
        disableRipple
        className={classes.greyButton}
        onClick={handleMovementsClick(type)}
      >
        {label}
      </Button>
    );
  }

  function getBookingMovements() {
    if (
      !!bkgsLoading ||
      (!todayMovements.checkins?.length && !todayMovements.checkouts?.length)
    ) {
      return null;
    }
    const checkinMovements = !!todayMovements.checkins?.length
      ? getButton("checkin", `${todayMovements.checkins.length} check-ins`)
      : null;
    const checkoutMovements = !!todayMovements.checkouts?.length
      ? getButton("checkout", `${todayMovements.checkouts.length} check-outs`)
      : null;

    return (
      <div>
        <div className={classes.movementsRow}>
          <Typography component="div" className={classes.subheader}>
            {"There are "}
            {checkinMovements}
          </Typography>
        </div>
        <div className={classes.movementsRow}>
          <Typography component="div" className={classes.subheader}>
            {!!checkinMovements && !!checkoutMovements && " and "}
            {checkoutMovements}
            {" today."}
          </Typography>
        </div>
      </div>
    );
  }

  function getMovementsMenu() {
    return (
      <CustomMenu
        open={Boolean(menuOptions.anchorEl)}
        anchorEl={menuOptions.anchorEl}
        timeout={0}
        placement="bottom-start"
        onClose={closeMovementsMenu}
        content={getMenuContent(menuOptions.type)}
      />
    );
  }

  function getHeader() {
    return (
      <div className={classes.header}>
        <div className={classes.greeting}>
          <Typography variant="h6">{greeting}</Typography>
          {getBookingMovements()}
        </div>
        <div className={classes.btnsContainer}>
          <div className={classes.controlsRow}>
            <Button
              variant="contained"
              className={classes.btnTransparent}
              classes={{
                root: classes.arrowBtn,
                label: classes.btnLabel,
                disabled: classes.arrowBtnDisabled,
              }}
              onClick={changeStartRange("before")}
              disabled={start === maxAllowedStartValues[interval]}
            >
              <NavigateBeforeIcon />
            </Button>
            <Button
              variant="contained"
              className={clsx(classes.btnTransparent, "mr-1")}
              classes={{
                root: classes.arrowBtn,
                label: classes.btnLabel,
                disabled: classes.arrowBtnDisabled,
              }}
              onClick={changeStartRange("after")}
              disabled={start === 0}
            >
              <NavigateNextIcon />
            </Button>
            <Button
              variant="contained"
              className={classes.btnTransparent}
              classes={{
                root: classes.btnRangeRoot,
                label: classes.btnLabel,
              }}
              onClick={openRangeMenu}
              startIcon={<DateRangeIcon />}
            >
              {getRangeLabel(interval)}
            </Button>
            <CustomMenu
              open={Boolean(rangeMenuAnchorEl)}
              anchorEl={rangeMenuAnchorEl}
              onClose={closeRangeMenu}
              content={
                <MenuList id="range-menu">
                  <MenuItem disabled value="">
                    Select a range
                  </MenuItem>
                  {intervals.map((i) => (
                    <MenuItem
                      key={i}
                      selected={interval === i}
                      value={i}
                      onClick={handleRangeSelect(i)}
                      style={{textTransform: "capitalize"}}
                    >
                      {i}s
                    </MenuItem>
                  ))}
                </MenuList>
              }
            />
          </div>
        </div>
      </div>
    );
  }

  function getMenuContent(type) {
    if (!type) {
      return <div></div>;
    }
    return (
      <div>
        <BookingList
          wide
          bookings={
            type === "checkin"
              ? todayMovements.checkins
              : todayMovements.checkouts
          }
          onBookingSelect={handleBookingSelect}
        />
      </div>
    );
  }

  const renderChart = (metric) => {
    switch (metric.view) {
      case "line_chart":
        return (
          <LineChartCard
            key={metric.metric_id}
            data={metric}
            start={start}
            interval={interval}
            redirect={goToReport}
          />
        );
      case "pie_chart":
        return (
          <PieChartCard
            key={metric.metric_id}
            data={metric}
            redirect={goToReport}
          />
        );
      case "histogram":
        return (
          <BarChartCard
            key={metric.metric_id}
            data={metric}
            redirect={goToReport}
          />
        );
      default:
        return null;
    }
  };

  if (!!redirect) return <Redirect push to={`/admin/${redirect}`} />;
  else if (isMobileView) {
    return !!loading || !todayMovements.loaded ? (
      <div className={clsx(classes.mobileMainContainer, "mobileContainer")}>
        {getHeader()}
        <div className={clsx(classes.container, classes.center)}>
          <CircularProgress color="secondary" />
        </div>
      </div>
    ) : (
      <div className={clsx(classes.mobileMainContainer, "mobileContainer")}>
        {getMovementsMenu()}
        {getHeader()}
        <div className={classes.mobileReportsContainer}>
          {reportEmpty ? (
            <Typography variant="h2" align="center" color="textSecondary">
              No Data
            </Typography>
          ) : (
            report.metrics?.map((metric) => renderChart(metric))
          )}
        </div>
      </div>
    );
  } else {
    return (
      <div className={classes.root}>
        {getMovementsMenu()}
        {getHeader()}
        <div
          className={clsx(classes.content, {
            [classes.center]: reportEmpty || loading,
          })}
        >
          {loading ? (
            <CircularProgress color="secondary" />
          ) : reportEmpty ? (
            <Typography variant="h2" color="textSecondary">
              No Data
            </Typography>
          ) : (
            <DraggableLayout
              autoSize
              disablePadding
              disableDrag // Disable drag until we support drag action
              itemKey="metric_id"
              items={report.metrics || []}
              renderItem={renderChart}
            />
          )}
        </div>
      </div>
    );
  }
}
