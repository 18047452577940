import React from "react";
// UI
import {Card, CardContent, CardHeader} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import useDashboardStyles from "styles/useDashboardStyles";
// Custom
import PieChart from "components/Charts/PieChart";
// Helpers & Utilities
import {metricLabels} from "configuration/constants.js";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  root: {height: "380px !important"},
  content: {padding: "0px !important"},
}));

export default function PieChartCard({data, redirect}) {
  const classes = useStyles();
  const dashboardStyles = useDashboardStyles();
  const title = data.title || metricLabels[data.metric];

  const goToReport = () => redirect(data.report_id);

  function getChartData() {
    const labels = [];
    const chart_data = [];
    const colors = [];
    for (let index = 0; index < data.buckets.length; index++) {
      const bucket = data.buckets[index];
      labels.push(bucket.label);
      chart_data.push(bucket.total);
      colors.push(bucket.color);
    }
    return {
      labels: labels,
      data: chart_data,
      title: metricLabels[data.metric],
      colors: colors,
    };
  }

  const pieChart = React.useMemo(
    () => <PieChart data={getChartData()} />,
    [data],
  );

  return (
    <Card
      className={clsx(dashboardStyles.card, classes.root)}
      onClick={goToReport}
    >
      <CardHeader
        className={dashboardStyles.cardHeader}
        classes={{action: dashboardStyles.cardAction}}
        title={title}
        titleTypographyProps={{className: dashboardStyles.cardTitle}}
      />
      <CardContent
        className={clsx(classes.content, dashboardStyles.cardContent)}
      >
        {pieChart}
      </CardContent>
    </Card>
  );
}
