import React from "react";
import {useFlags, withLDConsumer} from "launchdarkly-react-client-sdk";
import NewDashboard from "./NewDashboard";
import OldDashboard from "./OldDashboard";

const Dashboard = ({}) => {
  const flags = useFlags();

  if (!!flags.newDashboard) {
    return <NewDashboard />;
  } else {
    return <OldDashboard />;
  }
};

export default withLDConsumer()(Dashboard);
