import {API} from "aws-amplify";

export const getReports = async (params) => {
  try {
    const response = await API.get("ensoAPI", `/reports?${params}`);
    return response;
  } catch (err) {
    throw err;
  }
};

export const getReport = async (params) => {
  try {
    const response = await API.get("ensoAPI", `/reports/data?${params}`);
    return response;
  } catch (err) {
    throw err;
  }
};

export const deleteReport = async (params) => {
  try {
    const response = await API.del("ensoAPI", `/reports?${params}`);
    return response;
  } catch (err) {
    throw err;
  }
};

export const getReportPreview = async (params) => {
  try {
    const response = await API.post("ensoAPI", `/reports/preview`, {
      body: params,
    });
    return response;
  } catch (err) {
    throw err;
  }
};

export const getReportExport = async (params) => {
  try {
    const response = await API.get("ensoAPI", `/reports/export?${params}`);
    return response;
  } catch (err) {
    throw err;
  }
};

export const postReport = async (body) => {
  try {
    const response = await API.post("ensoAPI", "/reports", body);
    return response;
  } catch (err) {
    throw err;
  }
};
