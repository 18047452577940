import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {Redirect} from "react-router";
// UI
import {Box, Button, Chip, Paper, Slide, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
// Custom
import ExperienceBuilder from "./ExperienceBuilder";
import WarningAlert from "core/alerts/WarningAlert";
import FullLoader from "components/Dialogs/FullLoader";
import ConfirmDialog from "components/Dialogs/ConfirmDialog";
import PrimaryButton from "core/buttons/PrimaryButton";
import UpdateSession from "components/Dialogs/UpdateSession";
import FilledTextField from "core/inputs/FilledTextField";
import CustomCardHeader from "core/cards/CustomCardHeader";
import DateTimePicker from "core/inputs/DateTimePicker";
// Actions
import {saveExperience} from "redux/actions/experiencesActions";
// Utils
import {isValid} from "date-fns";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
  paper: {
    position: "absolute",
    top: 0,
    left: 0,
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  column: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: "100%",
  },
  content: {
    flexGrow: 1,
    borderTop: `1px solid rgba(13,86,140,0.05)`,
    overflowX: "hidden",
    overflowY: "auto",
    display: "flex",
    flexDirection: "column",
  },
  cancelBtn: {
    color: "#A8A8A8",
    fontSize: 14,
  },
  cardHeader: {padding: theme.spacing(4)},
  warningContainer: {padding: theme.spacing(4)},
  container: {padding: theme.spacing(3, 4)},
  betaChip: {
    height: 24,
    paddingTop: 3,
  },
}));

export default function MarketingExperiencePanel({
  selectedCampaign,
  connectedIds,
  hideContent,
  hideTriggers,
  hideConnection,
  closeDetails,
  addCampaign,
  getCampaigns,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const user_profile = useSelector(
    (state) => state.defaultReducer.user_profile,
  );
  const [isDataValid, setIsDataValid] = React.useState(false);
  const [data, setData] = React.useState({});
  const [date, setDate] = React.useState(null);
  const [emptyIds, setEmptyIds] = React.useState(false);
  const [showCancelConfirmation, setShowCancelConfirmation] =
    React.useState(false);
  const [redirect, setRedirect] = React.useState(null);
  const [updateSession, setUpdateSession] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [name, setName] = React.useState("");
  const [saveDisabled, setSaveDisabled] = React.useState(false);
  const create = !selectedCampaign;

  React.useEffect(() => validateData(data), [connectedIds, name, date]);

  React.useEffect(() => {
    setDate((prev) =>
      create
        ? new Date()
        : new Date(
            parseInt(selectedCampaign?.actions[0]?.trigger_value ?? null),
          ),
    );
    setName((prev) => selectedCampaign?.name ?? "");
  }, [selectedCampaign]);

  const closeConfiguration = () => closeDetails();
  const handleDismiss = () => setShowCancelConfirmation((prev) => false);
  const onCancel = () => setShowCancelConfirmation((prev) => true);

  const validateData = (newData) => {
    const validName = !!name.trim();
    const validDate = isValid(date);
    const validIds = !!connectedIds.length;
    const validActions = !!newData.actions?.every(
      (step) => !!step.actions?.length,
    );
    const isNewDataValid = validName && validIds && validActions && validDate;
    if (validIds === emptyIds) {
      setEmptyIds((prev) => !validIds);
    }
    if (isNewDataValid !== isDataValid) {
      setIsDataValid((prev) => isNewDataValid);
    }
  };

  const onSaveSuccess = () => {
    setLoading((prev) => false);
    setUpdateSession((prev) => null);
    closeDetails(true);
    getCampaigns();
  };

  const onSaveError = () => {
    setLoading((prev) => false);
    setUpdateSession((prev) => null);
  };

  const handleSave = (saveUpdate) => {
    setIsDataValid((prev) => false);
    setLoading((prev) => true);
    const newData = {
      experience: {
        ...data,
        name,
        enabled: true,
        experience_type: "cmp",
        connected_to: {object: "guest", ids: connectedIds},
        actions: [
          {
            ...data.actions[0],
            trigger: "specific_time",
            trigger_value: date.getTime(),
          },
        ],
      },
    };

    if (!!saveUpdate && !!updateSession) {
      newData.update_id = updateSession.update_session.update_id;
    }
    if (!!data.experience_id) {
      newData.experience_id = data.experience_id;
    }
    if (!!newData.experience.experience_id) {
      addCampaign(newData.experience);
    }

    dispatch(
      saveExperience({
        body: newData,
        type: "campaign",
        onSuccess: onSaveSuccess,
        onError: onSaveError,
        onUpdates: !saveUpdate
          ? (update) => {
              setUpdateSession((prev) => update);
              setLoading((prev) => false);
            }
          : null,
      }),
    );
  };

  const handleCancelUpdates = () => {
    setUpdateSession((prev) => null);
  };

  const handleLoadingDialogClose = () => {
    setLoading((prev) => false);
  };

  const handleDataChange = (newData) => {
    validateData(newData);
    setData((prev) => newData);
  };

  function getErrors() {
    const errors = selectedCampaign?.errors || [];
    return errors.map((err, i) => (
      <WarningAlert
        key={`${err.title}-${i}`}
        title={err.title}
        subtitle={err.message}
        severity={err.severity}
        actionButtonLabel={err.path_to_resolution ? "Resolve" : null}
        action={
          err.path_to_resolution
            ? () => setRedirect(err.path_to_resolution)
            : null
        }
      />
    ));
  }

  const confirmModal = !!updateSession && (
    <ConfirmDialog
      disableDismiss
      open={!loading}
      onClose={handleCancelUpdates}
      title="Confirm updates?"
      message={
        <>
          {"Updating this experience will affect the following:"}
          <UpdateSession updates={updateSession.update_session.updates} />
        </>
      }
      confirmLabel="Confirm"
      confirmAction={() => handleSave(true)}
      cancelLabel="Cancel"
      cancelAction={handleCancelUpdates}
    />
  );

  const loadingModal = (
    <FullLoader
      open={loading}
      disableDismiss
      onClose={handleLoadingDialogClose}
      loadingText="Saving experience..."
    />
  );

  if (!!redirect) {
    if (redirect.slice(0, 4) === "http") {
      window.location.href = redirect;
    } else {
      return <Redirect push to={`/admin/${redirect}`} />;
    }
  }
  return (
    <div className={classes.root}>
      {confirmModal}
      {loadingModal}
      <ConfirmDialog
        disableDismiss
        open={showCancelConfirmation}
        onClose={handleDismiss}
        title="Quit editing?"
        message="Changes you made so far will not be saved"
        confirmLabel="Yes, quit"
        confirmAction={closeConfiguration}
        cancelLabel="Keep editing"
        cancelAction={handleDismiss}
      />
      <Slide direction="left" in unmountOnExit mountOnEnter timeout={0}>
        <Paper elevation={0} className={classes.paper}>
          <div className={classes.column}>
            <CustomCardHeader
              title={
                <>
                  <Box mr={2}>{"Create Marketing Campaign"}</Box>
                  <Chip
                    color="primary"
                    label={"BETA"}
                    className={classes.betaChip}
                  />
                </>
              }
              type="title"
              className={classes.cardHeader}
              action={
                <>
                  <Button
                    className="mr-2"
                    classes={{label: classes.cancelBtn}}
                    size="small"
                    onClick={onCancel}
                  >
                    Cancel
                  </Button>
                  <PrimaryButton
                    size="small"
                    label="Save"
                    className="mr-2"
                    disabled={
                      !isDataValid || user_profile.scopes?.crm === "read"
                    }
                    onClick={() => handleSave()}
                    id={"button-save-experience"}
                  />
                </>
              }
            />
            <div className={classes.content}>
              {create && (
                <WarningAlert
                  hide={!emptyIds}
                  title="No Selected Guests"
                  subtitle="Select at least 1 guest from the table to save this experience."
                />
              )}
              {getErrors()}
              <div className={clsx(classes.container, "mb-3")}>
                <Typography color="textSecondary" className="mb-3">
                  {`Connected guests: ${connectedIds.length}`}
                </Typography>
                <FilledTextField
                  fullWidth
                  value={name}
                  className="mb-3"
                  label="Campaign name"
                  onChange={(e) => setName(e.target.value)}
                />
                <DateTimePicker
                  value={date}
                  filled={true}
                  label="Send time"
                  onChange={setDate}
                />
              </div>
              <ExperienceBuilder
                type="instant"
                experience={selectedCampaign}
                hideConnection={hideConnection}
                hideContent={hideContent}
                hideTriggers={hideTriggers}
                setSaveDisabled={setSaveDisabled}
                setData={handleDataChange}
              />
            </div>
          </div>
        </Paper>
      </Slide>
    </div>
  );
}
